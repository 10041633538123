import React, { useContext, useRef, useEffect, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const SectionColumnSpacingHandle = (props) => {
  const handle = useRef(null);
  const [contextData] = useContext(VariantContextData);

  const direction = props.direction;
  const column = props.column;
  const directionCapitalized =
    direction == "top"
      ? "Top"
      : direction == "bottom"
      ? "Bottom"
      : direction == "left"
      ? "Left"
      : "Right";
  const section = props.section;
  const isMobile = contextData.device == "mobile";
  const columnDivSelector = `#builder .cf-column[data-section-id="${section.id}"][data-column-position="${column}"]`;
  const defaultValue =
    !isMobile || _.isNil(section.options['structure'][`cf-column-${column}-padding-mobile-${direction}`])
      ? section.options['structure'][`cf-column-${column}-padding-${direction}`]
      : section.options['structure'][`cf-column-${column}-padding-mobile-${direction}`];
  const [value, setValue] = useState(defaultValue);

  const handleDrag = (e) => {
    e.preventDefault();

    let oldValue;
    let newValue;
    const columnDiv = document.querySelector(columnDivSelector);
    const style = window.getComputedStyle(columnDiv);

    if (direction == "top") {
      oldValue = parseInt(style[`paddingTop`].replace(" !important", "") || 0);
      newValue = oldValue + e.movementY;
    }

    if (direction == "bottom") {
      oldValue = parseInt(style[`paddingBottom`].replace(" !important", "") || 0);
      newValue = oldValue + e.movementY;
    }

    if (direction == "left") {
      oldValue = parseInt(style[`paddingLeft`].replace(" !important", "") || 0);
      newValue = oldValue + e.movementX;
    }

    if (direction == "right") {
      oldValue = parseInt(
        style[`paddingRight`].replace(" !important", "") || 0
      );
      newValue = oldValue - e.movementX;
    }

    if (newValue >= 0) {
      columnDiv.style.setProperty(
        `padding-${direction}`,
        `${newValue}px`,
        "important"
      );
      columnDiv.classList.add("spacing");
      setValue(newValue);
    }
  };

  const handleUpdate = () => {
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", handleUpdate);

    const columnDiv = document.querySelector(columnDivSelector);
    const newPosition = parseInt(
      columnDiv.style[`padding${directionCapitalized}`]
        .replace("px", "")
        .replace(" !important", "")) || 0;

      columnDiv.style.removeProperty(`padding-${direction}`);
      columnDiv.classList.remove("spacing");

    dispatchCustomEvent("updateBuilder", {
      updates: [
        {
          object_type: "sections",
          object_id: section.id,
          setting_name: `[options][structure][cf-column-${column}-padding${
            isMobile ? "-mobile" : ""
          }-${direction}]`,
          value: newPosition,
          old_value: defaultValue,
        },
      ],
    });
  };

  
  return (
    <div
      ref={handle}
      className="section-column-spacing-wrapper"
      style={{
        display: contextData.selected_object_type == "sections" && contextData.selected_object_id == section.id && contextData.selected_object_collapse == column ? "flex" : "none",
        position: "absolute",
        top: ["left", "right"].includes(direction) ? 0 : undefined,
        left: ["top", "bottom"].includes(direction) ? 0 : undefined,
        right: ["top", "bottom"].includes(direction) ? 0 : undefined,
        [direction]: 0,
        width: direction === "top" || direction === "bottom" ? "100%" : "10px",
        height: direction === "left" || direction === "right" ? "100%" : "10px",
        cursor:
          direction === "top" || direction === "bottom"
            ? "ns-resize"
            : "ew-resize",
        zIndex: 10,
        justifyContent: "center",
        alignItems: "center",
      }}
      onMouseDown={(e) => {
        document.addEventListener("mousemove", handleDrag);
        document.addEventListener("mouseup", handleUpdate);
      }}
    >
      <div
        className={`section-column-spacing-handle !w-[25px] !min-w-[25px] !h-[7px] bg-white shadow border-solid border-[1px] border-slate-300 rounded ${
          ["left", "right"].includes(direction) ? "rotate-90" : ""
        } ${
          direction == "top"
            ? "mt-[0px]"
            : direction == "bottom"
            ? "mb-[0px]"
            : direction == "left"
            ? "ml-[0px]"
            : "mr-[0px]"
        }`}
      ></div>
      <div className={`hidden section-column-spacing-value text-xl opacity-80 ${direction == 'top' ? 'mt-[10px]' : direction == 'bottom' ? 'mb-[10px]' : direction == 'left' ? 'ml-[30px]' : 'mr-[30px]'}`}>
        {`${value || 0}px`}
      </div>
    </div>
  );
};

export default SectionColumnSpacingHandle;
