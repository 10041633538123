import React, { useContext, useState, useRef } from "react";
import VariantBuilderPanelLayersItem from "../variants/VariantBuilderPanelLayersItem.js";
import SectionColumnLayer from "../sections/SectionColumnLayer.js";
import { VariantContextData } from "../variants/VariantBuilderContext";

const SectionLayer = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const section = props.section;

  let columns = [{ name: "first", position: 1 }];

  if (
    section.options["structure"]["column-layout"] &&
    ["100"].includes(section.options["structure"]["column-layout"]) == false
  ) {
    columns.push({ name: "second", position: 2 });
  }

  if (
    ["33-33-33", "50-25-25", "25-25-50", "25-50-25", "25-25-25-25"].includes(
      section.options["structure"]["column-layout"]
    )
  ) {
    columns.push({ name: "third", position: 3 });
  }

  if (["25-25-25-25"].includes(section.options["structure"]["column-layout"])) {
    columns.push({ name: "fourth", position: 4 });
  }

  const columnsList = (
    <React.Fragment>
      {columns.map((column) => {
        return (
          <SectionColumnLayer
            key={`section-${section.id}-column-${column.name}-layer`}
            column={column}
            {...props}
          />
        );
      })}
    </React.Fragment>
  );

  return !section.content_toggle_item_id ? (
    <VariantBuilderPanelLayersItem
      object_type={"sections"}
      wrapper_object_type={section.element_id ? "elements" : null}
      object_id={section.id}
      wrapper_object_id={section.element_id ? section.element_id : null}
      title={props.title ? props.title : `Section ${section.position}`}
      open={!section.element_id ? true : false}
      object={section}
      element={props.element ? props.element : null}
      element_type={props.element ? props.element.element_type : null}
      canvasSelector={`.cf-section[data-section-id="${section.id}"] .cf-section-overlay`}
      toggleForDevice={() => {
        if (props.element) {
          builder.update([
            {
              object_type: 'elements',
              object_id: props.element.id,
              setting_name: `[options][hide-${contextData.device}]`,
              value: `${(props.element.options[`hide-${contextData.device}`] == "true") == false}`
            },
          ]);
        } else {
          builder.update([
            {
              object_type: 'sections',
              object_id: section.id,
              setting_name: `[options][structure][hide-${contextData.device}]`,
              value: `${(section.options['structure'][`hide-${contextData.device}`] == "true") == false}`
            },
          ]);
        }
      }}
    >
      {columnsList}
    </VariantBuilderPanelLayersItem>
  ) : (
    columnsList
  );
};

export default SectionLayer;
