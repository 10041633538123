import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _, { xor } from "lodash";
import VariantBuilderCanvasSelect from "../variants/VariantBuilderCanvasSelect";
import Section from "../sections/Section";
import Element from "../elements/Element";
import sortElements from "../elements/sortElements.js";
import SectionColumnSpacingHandle from "../sections/SectionColumnSpacingHandle";

const SectionColumn = (props) => {
  let section = props.section;
  let options = section.options["structure"];
  let column = props.column;
  const attributes = style.attributes(section, column);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    props.variant,
    section,
    column
  );

  let column_elements = Object.values(section.elements).filter(
    (e) =>
      e.section_id == section.id &&
      e.column == column.position &&
      e.toBeDeleted !== true
  );
  let sorted_elements = column_elements.sort(function (a, b) {
    return a.position - b.position;
  });

  if (props.builder == true) {
    useEffect(() => {
      let section_tag = document.querySelector(
        "#builder .cf-section[data-section-id='" + section.id + "']"
      );
      let column_tag = document.querySelector(
        "#builder .cf-column[data-section-id='" +
          section.id +
          "'][data-column-position='" +
          column.name +
          "']"
      );

      column_tag.onclick = function (e) {
        if (
          e.target.getAttribute("data-section-id") == section.id + "" &&
          e.target.classList.contains("cf-background-overlay")
        ) {
          dispatchCustomEvent("selectObject", {
            object_type: "sections",
            object_id: section.id,
            panel_closed: false,
          });
        }
      };

      column_tag.ondblclick = function (e) {
        if (
          e.target.getAttribute("data-section-id") == section.id + "" &&
          e.target.classList.contains("cf-background-overlay")
        ) {
          dispatchCustomEvent("selectObject", {
            object_type: "sections",
            object_id: section.id,
            tab: "Columns",
            collapse: column.name,
            panel_closed: false,
          });
        }
      };

      sortElements.start(section, column);

      let icons = column_tag.querySelectorAll(`.section-add-element[data-section-id='${section.id}']`);

      let selectLabel = column_tag.querySelector(
        '.select-label[data-object_type="sections"][data-object_id="' +
          section.id +
          '"][data-column="' +
          column.name +
          '"]'
      );
      let selectLabelPopover = section_tag.querySelector(
        '.select-label-popover[data-object_type="sections"][data-object_id="' +
          section.id +
          '"]'
      );

      column_tag.onmouseover = function (e) {
        icons.forEach((icon) => {
          if (icon.classList.contains("section-add-element")) {
            icon.style.display = "block";
          }
        });

        if (
          (e.target.getAttribute("data-section-id") == section.id ||
            e.target.getAttribute("data-object_id") == section.id) &&
          (e.target.classList.contains("cf-background-overlay") ||
            e.target.classList.contains("section-options-toggle") ||
            e.target.classList.contains("section-add-element") ||
            e.target.classList.contains("glyphicon") ||
            e.target.classList.contains("select-label") ||
            e.target.classList.contains("select-label-span") ||
            e.target.classList.contains("select-label-popover") ||
            (selectLabelPopover && selectLabelPopover.contains(e.target)) ||
            e.target.classList.contains("cf-draggable-creation-hover"))
        ) {
          column_tag.classList.add("editor-select");
          selectLabel.style.display = "block";

          if (selectLabelPopover) {
            selectLabelPopover.style.display = "block";
          }

          icons.forEach((icon) => {
            if (icon.classList.contains("section-add-element") == false) {
              icon.style.display = "block";
            }
          });
        }
      };

      column_tag.onmouseleave = function (e) {
        selectLabel.style.display = "none";

        icons.forEach((icon) => {
          icon.style.display = "none";
        });

        if (
          !e.target.classList.contains("section-column-spacing-handle") &&
          !e.target.classList.contains("section-column-spacing-wrapper") &&
          !e.target.classList.contains("section-column-spacing-value") &&
          !column_tag.classList.contains("spacing") &&
          !(
            window.selected_object_type == "sections" &&
            window.selected_object_id == section.id &&
            window.selected_object_collapse == column.name
          )
        ) {
          column_tag.classList.remove("editor-select");
          selectLabel.style.display = "none";
        }
      };
    });
  }

  let highlightLabel = () => {
    const capitalizeFirstChar = (str) =>
      str.charAt(0).toUpperCase() + str.substring(1);

    let elementType = "";

    if (section.element_id) {
      const sectionElement = props.section.element;

      elementType = sectionElement.element_type;
    } else if (!section.element_id) {
      elementType = "Section";
    } else {
      elementType = "Row";
    }

    return `${capitalizeFirstChar(elementType)} Col ${column.position}`;
  };

  return (
    <React.Fragment>
      <div
        column-position={column.name}
        data-column-position={column.name}
        section-id={section.id}
        data-section-id={section.id}
        data-column={options["cf-column-" + column.name]}
        data-column-layout={attributes.layout}
        data-column-layout-mobile={attributes.layout_mobile}
        data-mobile-responsive={
          options["mobile-responsive"] ? options["mobile-responsive"] : "true"
        }
        data-object-type="sections"
        data-object-id={section.id}
        className={
          attributes.classes +
          (props.builder == true &&
          window.selected_object_type == "sections" &&
          window.selected_object_id == section.id &&
          window.selected_object_collapse == column.name
            ? " editor-select"
            : "")
        }
      >
        <div
          section-id={section.id}
          data-section-id={section.id}
          className="cf-background-overlay"
          data-element-id={section.element_id}
        ></div>
        <div
          className="cf-cta-item-container"
          section-id={section.id}
          data-section-id={section.id}
          data-sortable-id={column.position}
          data-section-column={column.position}
        >
          <div className="cf-cta-item disabled-item"></div>
          {props.builder == true && (
            <p
              className="cf-draggable-creation-hover empty-container"
              data-object-type="Elements"
            >
              Column: Drag elements here
            </p>
          )}
          {sorted_elements.map((element, i) => {
            return (
              <Element
                key={"element-" + element.id}
                builder={props.builder}
                device={props.device}
                website={props.website}
                cta={props.cta}
                variant={props.variant}
                section={props.section}
                element={element}
                preview={props.preview}
              ></Element>
            );
          })}
        </div>
        {props.builder == true && (
          <React.Fragment>
            <div
              className="section-add-element"
              data-section-id={section.id}
              data-test-id={"section-add-element-" + section.id}
              style={{ display: "none" }}
              onClick={() => {
                dispatchCustomEvent("newObject", {
                  object_type: "Elements", 
                  section_id: section.id,
                  column: column.position,
                  element_type: null
                });
              }}
            >
              <span
                className="glyphicon glyphicon-plus"
                aria-hidden="true"
                data-section-id={section.id}
              ></span>
            </div>
            <VariantBuilderCanvasSelect
              label={highlightLabel()}
              object_type="sections"
              object_id={section.id}
              object={section}
              column={column.name}
            />
          </React.Fragment>
        )}
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        {props.builder == true && (
          <React.Fragment>
            <SectionColumnSpacingHandle
              key={`section-${section.id}-${column.name}-spacer-top`}
              {...props}
              direction="top"
              column={column.name}
            ></SectionColumnSpacingHandle>
            <SectionColumnSpacingHandle
              key={`section-${section.id}-${column.name}-spacer-bottom`}
              {...props}
              direction="bottom"
              column={column.name}
            ></SectionColumnSpacingHandle>
            <SectionColumnSpacingHandle
              key={`section-${section.id}-${column.name}-spacer-left`}
              {...props}
              direction="left"
              column={column.name}
            ></SectionColumnSpacingHandle>
            <SectionColumnSpacingHandle
              key={`section-${section.id}-${column.name}-spacer-right`}
              {...props}
              direction="right"
              column={column.name}
            ></SectionColumnSpacingHandle>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const style = {
  predict_column_layout: (options) => {
    var response = "100";
    if (options["cf-column-first"] == "cf-col-md-12") response = "100";
    if (
      options["cf-column-first"] == "cf-col-md-6" &&
      options["cf-column-second"] == "cf-col-md-6"
    )
      response = "50-50";
    if (
      options["cf-column-first"] == "cf-col-md-7" &&
      options["cf-column-second"] == "cf-col-md-5"
    )
      response = "60-40";
    if (
      options["cf-column-first"] == "cf-col-md-5" &&
      options["cf-column-second"] == "cf-col-md-7"
    )
      response = "40-60";
    if (
      options["cf-column-first"] == "cf-col-md-8" &&
      options["cf-column-second"] == "cf-col-md-4"
    )
      response = "70-30";
    if (
      options["cf-column-first"] == "cf-col-md-4" &&
      options["cf-column-second"] == "cf-col-md-8"
    )
      response = "30-70";
    if (
      options["cf-column-first"] == "cf-col-md-9" &&
      options["cf-column-second"] == "cf-col-md-3"
    )
      response = "80-20";
    if (
      options["cf-column-first"] == "cf-col-md-3" &&
      options["cf-column-second"] == "cf-col-md-9"
    )
      response = "20-80";
    if (
      options["cf-column-first"] == "cf-col-md-4" &&
      options["cf-column-second"] == "cf-col-md-4" &&
      options["cf-column-third"] == "cf-col-md-4"
    )
      response = "33-33-33";
    if (
      options["cf-column-first"] == "cf-col-md-6" &&
      options["cf-column-second"] == "cf-col-md-3" &&
      options["cf-column-third"] == "cf-col-md-3"
    )
      response = "50-25-25";
    if (
      options["cf-column-first"] == "cf-col-md-3" &&
      options["cf-column-second"] == "cf-col-md-3" &&
      options["cf-column-third"] == "cf-col-md-6"
    )
      response = "25-25-50";
    if (
      options["cf-column-first"] == "cf-col-md-3" &&
      options["cf-column-second"] == "cf-col-md-3" &&
      options["cf-column-third"] == "cf-col-md-3" &&
      options["cf-column-fourth"] == "cf-col-md-3"
    )
      response = "25-25-25-25";
    return response;
  },
  attributes: (section, column) => {
    let options = section.options["structure"];

    let layout;
    if (options["column-layout"]) {
      layout = options["column-layout"];
    } else {
      layout = style.predict_column_layout(options);
    }

    let layout_mobile;
    if (options["column-layout-mobile"]) {
      layout_mobile = options["column-layout-mobile"];
    } else {
      layout_mobile = layout;
    }

    let classes =
      "cf-col cf-column cf-column-" +
      column.name +
      " " +
      options["cf-column-" + column.name];

    // set mobile column width
    if (options["cf-column-" + column.name + "-mobile"]) {
      classes += " " + options["cf-column-" + column.name + "-mobile"];
    } else if (options["cf-column-" + column.name]) {
      let mobile_width_fallback = options["cf-column-" + column.name].replace(
        "cf-col-md-",
        "cf-col-md-mobile-"
      );
      classes += " " + mobile_width_fallback;
    } else {
      classes += " " + "cf-col-md-mobile-12";
    }

    // desktop/mobile display options
    if (options[column.name + "-column-hide-desktop"] == "true") {
      classes += " " + "cf-hide-desktop";
    }
    if (options[column.name + "-column-hide-mobile"] == "true") {
      classes += " " + "cf-hide-mobile";
    }

    // background image classes
    let background_image_position =
      "cf-background-position-" +
      (options["column-" + column.name + "-background-position"]
        ? options["column-" + column.name + "-background-position"]
        : "top-center");

    let background_image_size =
      "cf-background-size-" +
      (options["column-" + column.name + "-background-size"]
        ? options["column-" + column.name + "-background-size"]
        : "cover");

    classes += " " + background_image_position;
    classes += " " + background_image_size;

    if (options["cf-column-" + column.name + "-custom-class"]) {
      classes += " " + options["cf-column-" + column.name + "-custom-class"];
    }

    return {
      classes: classes,
      layout: layout,
      layout_mobile: layout_mobile,
    };
  },

  stylesheet: (builder, device, cta, variant, section, column) => {
    let options = style.backport(section, column);

    let minHeight;
    if (
      builder == true &&
      device == "desktop" &&
      section.options["structure"]["browser-height"] == "true" &&
      !section.element_id &&
      (cta.cta_type == "page" ||
        ((cta.cta_type == "inline" || cta.cta_type == "overlay") &&
          variant["options"]["container-width"] == "100%"))
    ) {
      minHeight = "700px";
    }

    // background image url
    let backgroundImage = options["cf-column-" + column.name + "-background"];

    if (backgroundImage) {
      backgroundImage = backgroundImage.replace('url("', "").replace('")', "");
    }

    let borderStyle =
      _.isNil(options[`cf-column-${column.name}-border-style`]) == false
        ? options[`cf-column-${column.name}-border-style`]
        : "solid";
    let borderWidth =
      _.isNil(options[`cf-column-${column.name}-border-width`]) == false
        ? options[`cf-column-${column.name}-border-width`]
        : 0;
    let borderWidthLeft =
      _.isNil(options[`cf-column-${column.name}-border-width-left`]) == false
        ? options[`cf-column-${column.name}-border-width-left`]
        : undefined;
    let borderWidthTop =
      _.isNil(options[`cf-column-${column.name}-border-width-top`]) == false
        ? options[`cf-column-${column.name}-border-width-top`]
        : undefined;
    let borderWidthBottom =
      _.isNil(options[`cf-column-${column.name}-border-width-bottom`]) == false
        ? options[`cf-column-${column.name}-border-width-bottom`]
        : undefined;
    let borderWidthRight =
      _.isNil(options[`cf-column-${column.name}-border-width-right`]) == false
        ? options[`cf-column-${column.name}-border-width-right`]
        : undefined;

    let borderColor = options["cf-column-" + column.name + "-border-color"]
      ? options["cf-column-" + column.name + "-border-color"]
      : "#CCCCCC";

    let overlayBackgroundColor = options["cf-column-" + column.name + "-color"]
      ? options["cf-column-" + column.name + "-color"]
      : undefined;

    let overlayOpacity =
      options["cf-column-" + column.name + "-opacity"] !== null
        ? options["cf-column-" + column.name + "-opacity"]
        : undefined;

    let desktopPaddingKey = `cf-column-${column.name}-padding-`;

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-column[data-section-id="${section.id}"][data-column-position="${column.name}"] {
  ${minHeight ? 'min-height: ' + minHeight + ' !important;' : ''}
  ${backgroundImage ? "background-image: url(" + backgroundImage + ") !important;" : ''}
  border: initial !important;
  ${_.isNil(borderStyle) == false ? 'border-style: ' + borderStyle + ' !important;' : ''}
  ${_.isNil(borderWidth) == false ? 'border-width: ' + borderWidth + 'px !important;' : ''}
  ${_.isNil(borderWidthLeft) == false ? 'border-left-width: ' + borderWidthLeft + 'px !important;' : ''}
  ${_.isNil(borderWidthRight) == false ? 'border-right-width: ' + borderWidthRight + 'px !important;' : ''}
  ${_.isNil(borderWidthTop) == false ? 'border-top-width: ' + borderWidthTop + 'px !important;' : ''}
  ${_.isNil(borderWidthBottom) == false ? 'border-bottom-width: ' + borderWidthBottom + 'px !important;' : ''}
  ${borderColor ? 'border-color: ' + borderColor + ' !important;' : ''}
  padding-left: ${_.isNil(options[desktopPaddingKey + 'left']) == false ? options[desktopPaddingKey + 'left'] + 'px' : '0px'} !important;
  padding-right: ${_.isNil(options[desktopPaddingKey + 'right']) == false ? options[desktopPaddingKey + 'right'] + 'px' : '0px'} !important;
  padding-top: ${_.isNil(options[desktopPaddingKey + 'top']) == false ? options[desktopPaddingKey + 'top'] + 'px' : '0px'} !important;
  padding-bottom: ${_.isNil(options[desktopPaddingKey + 'bottom']) == false ? options[desktopPaddingKey + 'bottom'] + 'px' : '0px'} !important;
}

#cta_${cta.id} .cf-column[data-section-id="${section.id}"][data-column-position="${column.name}"] .cf-background-overlay[data-section-id="${section.id}"] {
  background: ${overlayBackgroundColor} !important;
  opacity: ${overlayOpacity} !important;
}
`;

    let mobilePaddingKey = `cf-column-${column.name}-padding-mobile-`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-column[data-section-id="${section.id}"][data-column-position="${column.name}"] {
  ${_.isNil(options[mobilePaddingKey + 'left']) == false ? 'padding-left: ' + options[mobilePaddingKey + 'left'] + 'px !important;' : ''}
  ${_.isNil(options[mobilePaddingKey + 'right']) == false ? 'padding-right: ' + options[mobilePaddingKey + 'right'] + 'px !important;' : ''}
  ${_.isNil(options[mobilePaddingKey + 'top']) == false ? 'padding-top: ' + options[mobilePaddingKey + 'top'] + 'px !important;' : ''}
  ${_.isNil(options[mobilePaddingKey + 'bottom']) == false ? 'padding-bottom: ' + options[mobilePaddingKey + 'bottom'] + 'px !important;' : ''}
  ${_.isNil(options['column-' + column.name + '-background-position-mobile']) == false ? 'background-position: ' + options['column-' + column.name + '-background-position-mobile'].replace('-', ' ') + ' !important;' : ''}
  ${_.isNil(options['column-' + column.name + '-background-size-mobile']) == false ? 'background-size: ' + (options['column-' + column.name + '-background-size-mobile'] !== 'expand-height' ? options['column-' + column.name + '-background-size-mobile'] : 'auto 100%') + ' !important;' : ''}
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },

  // converts old database values to current values
  backport: (section, column) => {
    let options = { ...section.options["structure"] };

    const levelsMap = {
      0: 0,
      1: 15,
      2: 25,
      3: 40,
      4: 70,
      5: 120,
    };

    ["top", "bottom", "left", "right"].forEach((side) => {
      // desktop
      const desktopKey = "cf-column-" + column.name + "-padding-" + side;
      const desktopValue = options[desktopKey];

      if (
        desktopValue &&
        typeof desktopValue == "string" &&
        desktopValue.indexOf("_") > -1
      ) {
        let level = desktopValue.split("_").pop();
        options[desktopKey] = levelsMap[level];
      }

      // mobile
      const mobileKey = "cf-column-" + column.name + "-padding-mobile-" + side;
      const mobileValue = options[mobileKey];

      if (
        mobileValue &&
        typeof mobileValue == "string" &&
        mobileValue.indexOf("_") > -1
      ) {
        let level = mobileValue.split("_").pop();
        options[mobileKey] = levelsMap[level];
      }

      let borderWidth = options[`cf-column-${column.name}-border-width`];
      if (borderWidth && borderWidth.indexOf("px") > -1) {
        options[`cf-column-${column.name}-border-width`] = borderWidth.replace(
          "px",
          ""
        );
      }
    });

    return options;
  },
};

export default SectionColumn;
