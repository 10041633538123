import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useProductRecommendations from "../product_recommendations/useProductRecommendations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewProductRecommendation = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const { ProductRecommendations } = useProductRecommendations();

  const onClick = (e) => {
    let confirmation_type = props.element ? "" : "step";

    ProductRecommendations.create(props.element, {}, {
      callback: (product_recommendation) => {
        dispatchCustomEvent("focusItem", {
          parent_id: props.element.id,
          item_type: "product_recommendations",
          item_id: product_recommendation.id
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="col-xs-12 mb-5">
        <button
          onClick={onClick}
          className="new-product-recommendation w-full p-2 text-left bg-transparent border-none text-xl text-blue-600 dark:text-blue-200 dark:hover:text-white"
          title="Add Product Recommendation"
        >
          <FontAwesomeIcon icon="plus-square" className="mr-2 text-2xl" /> Add Product Recommendation
        </button>
      </div>
    </React.Fragment>
  );
};

export default NewProductRecommendation;
