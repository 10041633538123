import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import FieldSettings from "../fields/FieldSettings";
import NewField from "../fields/NewField";
import useFields from "../fields/useFields";
import useElements from "../elements/useElements";
import CountriesArray from "../../utils/CountriesArray";

const ElementSettingsFields = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const { Fields } = useFields();
  const { Elements } = useElements();

  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  let custom_fields = Object.values(variant.fields)
    .filter(
      (field) => field.element_id == element.id && field.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  let preset_fields = [
    { data_name: "name", label: "First name", parameter: "firstname" },
    { data_name: "lastname", label: "Last name", parameter: "lastname" },
    { data_name: "email", label: "Email address", default_status: "true" },
    { data_name: "phone", label: "Phone number" },
    { data_name: "url", label: "Website URL", parameter: "website" },
    {
      data_name: "company-name",
      label: "Company name",
      parameter: "company_name",
    },
    {
      data_name: "company-title",
      label: "Professional title",
      parameter: "company_title",
    },
    { data_name: "city", label: "City" },
    { data_name: "state", label: "State" },
    { data_name: "country", label: "Country" },
    { data_name: "address", label: "Address" },
    { data_name: "zip-code", label: "Zip code", parameter: "zipcode" },
  ];

  useEffect(() => {
    Fields.sortableStart(element);
  }, [props.object_id]);

  return (
    <React.Fragment>
      <div className="form-fields-container w-full">
        {preset_fields.map((preset_field, i) => {
          return (
            <VariantBuilderSetting
              key={
                "element-" +
                element.id +
                "-preset_field-" +
                preset_field.data_name
              }
              object={element}
              setting_name={"[options][show-" + preset_field.data_name + "]"}
              setting_type="preset_field"
              object_type="elements"
              object_id={element.id}
              condition={element.element_type == "form"}
              value={
                element.options["show-" + preset_field.data_name]
                  ? element.options["show-" + preset_field.data_name]
                  : preset_field.default_status
                  ? preset_field.default_status
                  : "false"
              }
              preset_field={preset_field}
              style={{
                marginBottom: "0px",
              }}
              position={
                element.options[preset_field.data_name + "-position"]
                  ? element.options[preset_field.data_name + "-position"]
                  : i + 1
              }
              data_attributes={{
                "data-field-name": preset_field.data_name,
              }}
              callback={(new_value) => {
                Elements.updateFieldCount(element);
              }}
              warning={
                preset_field.data_name == "email" &&
                element.options["show-email"] == "false" &&
                element.options["show-phone"] == "false"
                  ? "Email field is disabled. Collect email or phone to create contacts."
                  : preset_field.data_name == "phone" &&
                    element.options["show-email"] == "false" &&
                    element.options["show-phone"] == "false"
                  ? "Phone field is disabled. Collect email or phone to create contacts."
                  : ""
              }
            >
              <VariantBuilderSetting
                object={element}
                label={'Require "' + preset_field.label + '"'}
                setting_name={
                  "[options][" + preset_field.data_name + "-required]"
                }
                setting_type="checkbox"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options[preset_field.data_name + "-required"]
                    ? element.options[preset_field.data_name + "-required"]
                    : "false"
                }
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name !== "email" &&
                  (preset_field.data_name !== "phone" ||
                    element.options["show-email"] == "true")
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Label text"
                setting_name={"[options][" + preset_field.data_name + "-label]"}
                setting_type="text"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options[preset_field.data_name + "-label"]
                    ? element.options[preset_field.data_name + "-label"]
                    : ""
                }
                condition={element.element_type == "form"}
                placeholder={
                  element.options[preset_field.data_name + "-label"]
                    ? element.options[preset_field.data_name + "-label"]
                    : ""
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Placeholder text"
                setting_name={
                  "[options][" + preset_field.data_name + "-placeholder]"
                }
                setting_type="text"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options[preset_field.data_name + "-placeholder"]
                    ? element.options[preset_field.data_name + "-placeholder"]
                    : preset_field.label
                }
                condition={element.element_type == "form"}
                placeholder={
                  element.options[preset_field.data_name + "-placeholder"]
                    ? element.options[preset_field.data_name + "-placeholder"]
                    : preset_field.label
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Minimum Characters"
                setting_name={
                  "[options][" + preset_field.data_name + "-minimum-characters]"
                }
                setting_type="number"
                object_type="elements"
                object_id={element.id}
                col="6"
                value={
                  element.options[
                    preset_field.data_name + "-minimum-characters"
                  ]
                    ? element.options[
                        preset_field.data_name + "-minimum-characters"
                      ]
                    : ""
                }
                condition={element.element_type == "form"}
                allow_empty={true}
                placeholder="0"
              />

              <VariantBuilderSetting
                object={element}
                label="Maximum Characters"
                setting_name={
                  "[options][" + preset_field.data_name + "-maximum-characters]"
                }
                setting_type="number"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options[
                    preset_field.data_name + "-maximum-characters"
                  ]
                    ? element.options[
                        preset_field.data_name + "-maximum-characters"
                      ]
                    : ""
                }
                col="6"
                condition={element.element_type == "form"}
                allow_empty={true}
                placeholder="255"
              />

              <VariantBuilderSetting
                object={element}
                label="Prefill with URL parameters"
                setting_name={
                  "[options][" + preset_field.data_name + "-prefill-params]"
                }
                setting_type="text"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options[preset_field.data_name + "-prefill-params"]
                    ? element.options[
                        preset_field.data_name + "-prefill-params"
                      ]
                    : ""
                }
                condition={element.element_type == "form"}
                placeholder="url_parameter_name"
                tooltip={
                  'Map data from URL parameters to prefill this field. Field will always map data from "' +
                  (preset_field.parameter
                    ? preset_field.parameter
                    : preset_field.data_name) +
                  '" parameter'
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Add to redirect URL with data name"
                setting_name={
                  "[options][" + preset_field.data_name + "-redirect-dataname]"
                }
                setting_type="text"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options[preset_field.data_name + "-redirect-dataname"]
                    ? element.options[
                        preset_field.data_name + "-redirect-dataname"
                      ]
                    : ""
                }
                placeholder="data_name"
                condition={element.element_type == "form"}
                tooltip={
                  'Controls the data name of field data sent with URL redirect. Leave blank to use default data name "' +
                  (preset_field.parameter
                    ? preset_field.parameter
                    : preset_field.data_name) +
                  '"'
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Validate Email Is Real"
                setting_name={"[options][validate-email]"}
                setting_type="checkbox"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["validate-email"]
                    ? element.options["validate-email"]
                    : "false"
                }
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name == "email"
                }
                tooltip="Enabling this will have the form check the mailserver to validate the email is real before submitting"
                feature_gate_type="Email Validation"
              />

              <VariantBuilderSetting
                object={element}
                label="Allow Business Emails Only"
                setting_name={"[options][validate-email-business]"}
                setting_type="checkbox"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["validate-email-business"]
                    ? element.options["validate-email-business"]
                    : "false"
                }
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name == "email" &&
                  element.options["validate-email"] == "true"
                }
                tooltip="Enabling this will prevent emails from gmail.com, yahoo.com etc. and any free email provider"
              />
              <VariantBuilderSetting
                object={element}
                label="Hide country code"
                setting_name={"[options][phone-country-code-hide]"}
                setting_type="checkbox"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["phone-country-code-hide"]
                    ? element.options["phone-country-code-hide"]
                    : "false"
                }
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name == "phone"
                }
                note={
                  element.options["phone-country-code-hide"] == "true"
                    ? "WARNING: Hiding country code dropdown will often result in incomplete phone numbers being entered and rejected by SMS marketing platforms"
                    : ""
                }
              />
              <VariantBuilderSetting
                object={element}
                label="Default country code"
                setting_name={"[options][phone-default-country]"}
                setting_type="dropdown"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["phone-default-country"]
                    ? element.options["phone-default-country"]
                    : (website.data && website.data["default-country"]) || "US"
                }
                options={CountriesArray.map((country) => ({
                  value: country.country_code,
                  text: `${country.flag} ${country.name} (+${country.dial_code})`,
                }))}
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name == "phone" &&
                  element.options["phone-country-code-hide"] !== "true"
                }
              />
              <VariantBuilderSetting
                object={element}
                label="Country Code Label"
                setting_name={"[options][phone-country-code-label]"}
                setting_type="text"
                object_type="elements"
                object_id={element.id}
                placeholder="Code"
                value={
                  element.options["phone-country-code-label"]
                    ? element.options["phone-country-code-label"]
                    : ""
                }
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name == "phone" &&
                  element.options["phone-country-code-hide"] !== "true"
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Validate Phone Is Real"
                setting_name={"[options][validate-phone]"}
                setting_type="checkbox"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["validate-phone"]
                    ? element.options["validate-phone"]
                    : "false"
                }
                condition={
                  element.element_type == "form" &&
                  preset_field.data_name == "phone"
                }
                tooltip="Enabling this will have the form validate the phone number is real before submitting, and will format to E.164 standard"
                feature_gate_type="Phone Validation"
              />
            </VariantBuilderSetting>
          );
        })}

        {custom_fields.map((custom_field, i) => {
          return (
            <React.Fragment key={"custom_field-" + custom_field.id}>
              <FieldSettings field={custom_field} {...props} />
              

              <VariantBuilderSetting
                object={custom_field}
                label="Multi-Select"
                setting_name="[multi_select]"
                setting_type="checkbox"
                object_type="fields"
                object_id={custom_field.id}
                value={
                  custom_field.multi_select ? custom_field.multi_select : false
                }
                condition={
                  element.element_type == "survey" && custom_field.slug !== null
                }
                col={6}
              />

              <VariantBuilderSetting
                object={custom_field}
                label="Next Button"
                setting_name="[options][confirmation_button]"
                setting_type="checkbox"
                object_type="fields"
                object_id={custom_field.id}
                value={
                  custom_field.options["confirmation_button"]
                    ? custom_field.options["confirmation_button"]
                    : false
                }
                condition={
                  element.element_type == "survey" &&
                  custom_field.multi_select + "" !== "true"
                }
                col={6}
                classNames="no-padding-left"
              />

              <VariantBuilderSetting
                object={element}
                label="Hide until select"
                setting_name="[options][survey-next-hide]"
                setting_type="checkbox"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["survey-next-hide"]
                    ? element.options["survey-next-hide"]
                    : "true"
                }
                condition={
                  element.element_type == "survey" &&
                  (custom_field.multi_select + "" == "true" ||
                    custom_field.options["confirmation_button"] == "true") &&
                  [false, "false"].includes(
                    custom_field.options["confirmation_button"]
                  ) == false
                }
                col={6}
                classNames={
                  custom_field.multi_select + "" == "true" ||
                  custom_field.options["confirmation_button"] !== "true"
                    ? "no-padding-left"
                    : "no-padding-right"
                }
              />

              <VariantBuilderSetting
                object={element}
                label="Next button text"
                setting_name="[options][survey-next-text]"
                setting_type="text"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["survey-next-text"]
                    ? element.options["survey-next-text"]
                    : ""
                }
                placeholder="Next button text"
                condition={
                  element.element_type == "survey" &&
                  (custom_field.multi_select + "" == "true" ||
                    custom_field.options["confirmation_button"] == "true")
                }
              />
            </React.Fragment>
          );
        })}
      </div>

      {element.element_type == "form" && <NewField element={element} />}

      <div className="row hard-center">
        <VariantBuilderSetting
          object={element}
          label="Email Consent"
          setting_name={"[options][marketing-consent]"}
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["marketing-consent"]
              ? element.options["marketing-consent"]
              : "false"
          }
          condition={element.element_type == "form"}
          tooltip={`Consent message managed site-wide <br><u><a href='/websites/${website.id}/edit' target='_blank'>Edit →</a></u>`}
          tooltip_position="top"
          col={6}
          classNames="no-padding-right"
        />

        <VariantBuilderSetting
          object={element}
          label="Required"
          setting_name={"[options][marketing-consent-required]"}
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["marketing-consent-required"]
              ? element.options["marketing-consent-required"]
              : "false"
          }
          condition={
            element.element_type == "form" &&
            element.options["marketing-consent"] == "true"
          }
          col={6}
        />
      </div>

      <div className="row hard-center">
        <VariantBuilderSetting
          object={element}
          label="SMS Consent"
          setting_name={"[options][sms-consent]"}
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["sms-consent"]
              ? element.options["sms-consent"]
              : "false"
          }
          condition={element.element_type == "form"}
          tooltip={`Consent message managed site-wide <br><u><a href='/websites/${website.id}/edit' target='_blank'>Edit →</a></u>`}
          tooltip_position="top"
          col={6}
          classNames="no-padding-right"
        />

        <VariantBuilderSetting
          object={element}
          label="Required"
          setting_name={"[options][sms-consent-required]"}
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["sms-consent-required"]
              ? element.options["sms-consent-required"]
              : "true"
          }
          condition={
            element.element_type == "form" &&
            element.options["sms-consent"] == "true"
          }
          col={6}
        />
      </div>

      <div className="row hard-center">
        <VariantBuilderSetting
          object={element}
          label="Privacy Consent"
          setting_name={"[options][privacy-consent]"}
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["privacy-consent"]
              ? element.options["privacy-consent"]
              : "false"
          }
          condition={element.element_type == "form"}
          tooltip={`Consent message managed site-wide <br><u><a href='/websites/${website.id}/edit' target='_blank'>Edit →</a></u>`}
          tooltip_position="top"
          col={6}
          classNames="no-padding-right"
        />

        <VariantBuilderSetting
          object={element}
          label="Required"
          setting_name={"[options][privacy-consent-required]"}
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["privacy-consent-required"]
              ? element.options["privacy-consent-required"]
              : "true"
          }
          condition={
            element.element_type == "form" &&
            element.options["privacy-consent"] == "true"
          }
          col={6}
        />
      </div>

      <VariantBuilderSetting
        object={element}
        label="Submit Button Text"
        setting_name="[html]"
        setting_type="editor"
        object_type="elements"
        object_id={element.id}
        value={element.html}
        condition={element.element_type == "form"}
      />

      <VariantBuilderSetting
        object={element}
        label="Prefill fields"
        setting_name={"[options][prefill-fields]"}
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["prefill-fields"]
            ? element.options["prefill-fields"]
            : "true"
        }
        condition={element.element_type == "form"}
        tooltip="Prefill fields with data already collected from the visitor and from URL parameters"
        col="6"
        classNames="some-padding-right"
      />

      <VariantBuilderSetting
        object={element}
        label="Hide prefilled"
        setting_name={"[options][hide-prefilled-fields]"}
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["hide-prefilled-fields"]
            ? element.options["hide-prefilled-fields"]
            : "false"
        }
        condition={
          element.element_type == "form" &&
          element.options["prefill-fields"] !== "false"
        }
        tooltip="Enables progressive profiling for form, hiding any fields with contact info already collected"
        col="6"
        classNames="some-padding-left"
      />
    </React.Fragment>
  );
};

export default ElementSettingsFields;
