import { at } from "lodash";
import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import useElements from "../elements/useElements.js";

const useSections = (step) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let elements;

  const Sections = {
    create: async function (
      parent_step,
      parent_element,
      template_id = null,
      skip_history = false,
      draggablePosition,
      updates,
      forceRender = false,
      callback
    ) {
      let position =
        draggablePosition ||
        Object.values(variant.sections).filter(
          (section) =>
            section.step_id == parent_step.id &&
            !section.element_id &&
            section.toBeDeleted !== true
        ).length + 1;

      builder.create(
        "sections",
        {
          variant_id: variant.id,
          step_id: parent_step.id,
          element_id: parent_element ? parent_element.id : null,
          position: parent_element ? 1 : position,
          template_id: template_id,
        },
        {
          updates: updates,
          skip_history: skip_history,
          forceRender: forceRender,
          callback: (section) => {
            Sections.addChildrenToBuilder(section, true);

            if (callback) {
              callback(section);
            }
          },
        }
      );
    },

    duplicate: async function (og_section, payload, skip_history) {
      if (!og_section.element_id || skip_history == true) {
        let updates = [];

        if (skip_history !== true) {
          let nextSections = Object.values(variant.sections).filter(
            (section) =>
              section.step_id == og_section.step_id &&
              !section.element_id &&
              section.position > og_section.position &&
              section.toBeDeleted !== true
          );

          nextSections.forEach((nextSection) => {
            let update = {
              object_type: "sections",
              object_id: nextSection.id,
              setting_name: "[position]",
              value: nextSection.position + 1,
            };
            updates.push(update);
            builder.update([{ ...update, skip_history: true }]);
          });
        }

        let attributes = {
          position:
            skip_history !== true
              ? og_section.position + 1
              : og_section.position,
          options: og_section.options,
          step_id: og_section.step_id,
        };

        if (payload) {
          attributes = { ...attributes, ...payload };
        }

        builder.create("sections", attributes, {
          updates: updates,
          callback: async function (section) {
            let section_elements = Object.values(variant.elements).filter(
              (element) =>
                element.section_id == og_section.id &&
                element.toBeDeleted !== true
            );
            section_elements.forEach((element) =>
              elements.duplicate(
                element,
                {
                  section_id: section.id,
                },
                true
              )
            );
          },
          skip_history: skip_history,
        });
      }

      if (og_section.element_id && skip_history !== true) {
        let element = variant.elements[og_section.element_id];
        elements.duplicate(element, {});
      }
    },

    remove: (removed_section) => {
      if (!removed_section.element_id) {
        let message =
          "Are you sure you want to remove this section? Once your changes are saved, it will be irreversibly deleted.";
        let updates = [];

        let nextSections = Object.values(variant.sections).filter(
          (section) =>
            section.toBeDeleted !== true &&
            !section.element_id &&
            section.position > removed_section.position
        );

        nextSections.forEach((section) => {
          let update = {
            object_type: "sections",
            object_id: section.id,
            setting_name: "[position]",
            value: section.position - 1,
            old_value: section.position,
          };

          updates.push(update);
          builder.update([{ ...update, skip_history: true }]);
        });

        builder.remove("sections", removed_section.id, message, updates);
      }

      if (removed_section.element_id) {
        let element = variant.elements[removed_section.element_id];
        elements.remove(element);
      }

      let steps_with_sections = Object.values(
        contextData.objects.variant.sections
      )
        .filter((section) => section.toBeDeleted !== true)
        .map((s) => s.step_id)
        .filter((v, i, a) => a.indexOf(v) === i);

      if (steps_with_sections.length == 0) {
        $('.panel-tab[data-tab="steps"]').click();
      }
    },

    moveUp: (moved_section) => {
      if (!moved_section.element_id) {
        let updates = [];
        let sections = Object.values(variant.sections)
          .filter(
            (s) =>
              s.toBeDeleted !== true &&
              s.step_id == moved_section.step_id &&
              !s.element_id
          )
          .sort((a, b) => a.position - b.position);
        let new_value = moved_section.position - 1;

        if (sections.filter((s) => s.position == new_value)[0]) {
          updates.push({
            object_type: "sections",
            object_id: moved_section.id,
            setting_name: "[position]",
            value: new_value,
            old_value: moved_section.position,
          });

          sections
            .filter((s) => s.position == moved_section.position - 1)
            .forEach((section, i) => {
              updates.push({
                object_type: "sections",
                object_id: section.id,
                setting_name: "[position]",
                value: section.position + 1,
                old_value: section.position,
              });
            });

          builder.update(updates);
        }
      }

      if (moved_section.element_id) {
        let element = variant.elements[moved_section.element_id];
        elements.moveUp(element);
      }
    },

    moveDown: (moved_section, skip_history) => {
      if (!moved_section.element_id) {
        let updates = [];
        let sections = Object.values(variant.sections)
          .filter(
            (s) =>
              s.toBeDeleted !== true &&
              s.step_id == moved_section.step_id &&
              !s.element_id
          )
          .sort((a, b) => a.position - b.position);
        let new_value = moved_section.position + 1;

        if (sections.filter((s) => s.position == new_value)[0]) {
          updates.push({
            object_type: "sections",
            object_id: moved_section.id,
            setting_name: "[position]",
            value: new_value,
            old_value: moved_section.position,
            skip_history: skip_history,
          });

          sections
            .filter((s) => s.position == moved_section.position + 1)
            .forEach((section) => {
              updates.push({
                object_type: "sections",
                object_id: section.id,
                setting_name: "[position]",
                value: section.position - 1,
                old_value: section.position,
                skip_history: skip_history,
              });
            });

          builder.update(updates);
        }
      }

      if (moved_section.element_id) {
        let element = variant.elements[moved_section.element_id];
        elements.moveDown(element);
      }
    },

    addChildrenToBuilder: (section, skip_history) => {
      builder.addObjectToBuilder("sections", section, skip_history);

      if (Object.keys(section).includes("elements")) {
        Object.values(section.elements).forEach((element) => {
          Elements.addChildrenToBuilder(element, true);
        });
      }
    },

    sortableStart: () => {
      let container = document.querySelector(
        "#builder .cf-step[data-step-id='" + step.id + "'] .cf-cta-container"
      );

      if (contextData.selected_object_editing == false) {
        let forceFallback = environment == "test" ? false : true;
        let sortable_options = {
          sort: true,
          group: { name: "step-editors" },
          animation: 0,
          draggable: ".cf-section",
          filter: ".drag-disable, .section-spacing-wrapper, .section-column-spacing-handle, .section-column-spacing-wrapper, .section-column-spacing-handle",
          ghostClass: "drop-zone",
          handle:
            ".cf-section-overlay, .section-options-toggle, .cf-background-overlay",
          forceFallback: forceFallback,
          fallbackOnBody: true,
          fallbackClass: "builder-sortable-fallback",
          fallbackTolerance: 5,
          animation: 0,
          scroll: true,
          scrollSensitivity: 100,
          scrollSpeed: 10,
          preventOnFilter: false,
          onUpdate: Sections.sortableUpdate,
          onAdd: Sections.sortableUpdate,
        };

        let sortable = new Sortable(container, sortable_options);
      }
    },

    sortableUpdate: (event) => {
      if (event.item.getAttribute("data-section-id")) {
        let updates = [];
        Array.from(event.to.children).forEach((childDiv, i) => {
          if (childDiv.classList.contains("cf-section")) {
            const section_div = childDiv;
            let section_id = section_div.getAttribute("data-section-id");
            let section = variant.sections[section_id];
            let new_position = i + 1;

            updates.push({
              object_type: "sections",
              object_id: section_id,
              setting_name: "[position]",
              value: new_position,
            });

            if (section.step_id !== step.id) {
              updates.push({
                object_type: "sections",
                object_id: section_id,
                setting_name: "[step_id]",
                value: step.id,
                old_value: section.step_id,
              });

              section_div
                .querySelectorAll(
                  '.cf-section[data-step-id="' + section.step_id + '"]'
                )
                .forEach((row_section_div) => {
                  let row_section_id =
                    row_section_div.getAttribute("data-section-id");
                  let row_section = variant.sections[row_section_id];

                  if (row_section) {
                    updates.push({
                      object_type: "sections",
                      object_id: row_section.id,
                      setting_name: "[step_id]",
                      value: step.id,
                      old_value: row_section.step_id,
                    });
                  }
                });

              event.from.appendChild(section_div);
            }
          }
        });
        builder.update(updates);
      }
    },

    sortableLayersStart: () => {
      const container = document.querySelector(
        `#layers .layers-container[data-object-type='steps'][data-object-id="${step.id}"]`
      );

      let sortable_options = {
        sort: true,
        group: { name: "layers-sections" },
        animation: 0,
        draggable: `.layers-item[data-object-type='sections']`,
        filter: ".drag-disable",
        ghostClass: "drop-zone",
        handle: `.layers-item-icon[data-object-type='sections'], .layers-item-label[data-object-type='sections']`,
        scroll: true,
        forceFallback: true,
        fallbackOnBody: true,
        fallbackTolerance: 5,
        fallbackClass: "hide-during-drag",
        scrollSpeed: 10,
        onUpdate: Sections.sortableLayersUpdate,
        onAdd: Sections.sortableLayersUpdate,
      };

      let sortable = new Sortable(container, sortable_options);
    },

    sortableLayersUpdate: (event) => {
      let updates = [];

      Array.from(event.to.children).forEach((childDiv, i) => {
        if (childDiv.getAttribute("data-object-type") == "sections") {
          let section_id = childDiv.getAttribute("data-object-id");
          let section = variant.sections[section_id];
          let new_position = i + 1;

          updates.push({
            object_type: "sections",
            object_id: section_id,
            setting_name: "[position]",
            value: new_position,
          });

          if (section.step_id !== step.id) {
            updates.push({
              object_type: "sections",
              object_id: section_id,
              setting_name: "[step_id]",
              value: step.id,
            });

            document
              .querySelectorAll(
                `#builder .cf-section[data-section-id="${section.id}"] .cf-section[data-step-id="${section.step_id}"]`
              )
              .forEach((row_section_div) => {
                let row_section_id =
                  row_section_div.getAttribute("data-object-id");
                let row_section = variant.sections[row_section_id];

                if (row_section) {
                  updates.push({
                    object_type: "sections",
                    object_id: row_section.id,
                    setting_name: "[step_id]",
                    value: step.id,
                    old_value: row_section.step_id,
                  });
                }
              });

            event.from.appendChild(childDiv);
          }
        }
      });

      builder.update(updates);
    },
  };

  const { Elements } = useElements(Sections);
  elements = Elements;

  let currentHook = Sections;
  return { Sections, currentHook };
};

export default useSections;
