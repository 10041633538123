import React from "react";
import PropTypes from "prop-types";

const ElementFormConsentFields = (props) => {
  let element = props.element;
  let options = element.options;
  let important = props.builder !== true ? " !important" : "";
  let website = props.website;
  let websiteBrandName = website.brand ? website.brand : website.name;
  let smsConsentMessage = `By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from {BRAND} at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply.`;

  if (website.data["sms-consent"]) {
    smsConsentMessage = website.data["sms-consent"];
  }

  smsConsentMessage = smsConsentMessage.replace("{BRAND}", websiteBrandName);

  let consent_link_style = {
    fontFamily: "inherit" + important,
    color: "inherit" + important,
    textDecoration: "underline" + important,
    opacity: "0.7" + important,
    display: "none",
  };

  let privacy_link_style = Object.assign({}, consent_link_style);
  if (website.data["privacy-link"]) {
    privacy_link_style["display"] = "inline";
  }

  let terms_link_style = Object.assign({}, consent_link_style);
  if (website.data["terms-link"]) {
    terms_link_style["display"] = "inline";
  }

  return (
    <React.Fragment>
      <div
        className="cf-consent-field cf-marketing-consent cf-left"
        style={{
          display: options["marketing-consent"] == "true" ? "inline" : "none",
        }}
      >
        <label
          className="cf-label"
          style={{
            color: options["label-color"] ? options["label-color"] : "",
          }}
        >
          <input
            type="checkbox"
            className="cf-consent-checkbox"
            name="contact[marketing_consent]"
            value={options["marketing-consent"] == "true" ? "true" : ""}
            required={options["marketing-consent"] == "true" && element.options["marketing-consent-required"] == 'true' ? true : false}
          ></input>
          <span className="cf-consent-label">
            <span className="cf-marketing-label">
              {website.data["marketing-consent"]
                ? website.data["marketing-consent"]
                : "Yes, I'd like to receive email communications"}
            </span>
          </span>
        </label>
      </div>
      <div
        className="cf-consent-field cf-sms-consent cf-left"
        style={{
          display: options["sms-consent"] == "true" ? "inline" : "none",
        }}
      >
        <label
          className="cf-label"
          style={{
            color: options["label-color"] ? options["label-color"] : "",
          }}
        >
          <input
            type="checkbox"
            className="cf-consent-checkbox"
            name="contact[sms_consent]"
            value={options["sms-consent"] == "true" ? "true" : ""}
            required={options["sms-consent"] == "true" && element.options["sms-consent-required"] !== 'false' ? true : false}
          ></input>
          <span className="cf-consent-label">
            <span className="cf-consent-label cf-sms-label">
              <span className="cf-sms-message">{smsConsentMessage}</span>
              <a
                className="cf-privacy-link"
                href={
                  website.data["privacy-link"]
                    ? website.data["privacy-link"]
                    : "#"
                }
                target="_blank"
                style={privacy_link_style}
              >
                <br></br>
                {website.data["privacy-link-text"]
                  ? website.data["privacy-link-text"]
                  : "Privacy Policy"}
              </a>
              &nbsp;&nbsp;
              <a
                className="cf-terms-link"
                href={
                  website.data["terms-link"] ? website.data["terms-link"] : "#"
                }
                target="_blank"
                style={terms_link_style}
              >
                {website.data["terms-link-text"]
                  ? website.data["terms-link-text"]
                  : "Terms of Service"}
              </a>
            </span>
          </span>
        </label>
      </div>
      <div
        className="cf-consent-field cf-privacy-consent cf-left"
        style={{
          display: options["privacy-consent"] == "true" ? "inline" : "none",
        }}
      >
        <label
          className="cf-label"
          style={{
            color: options["label-color"] ? options["label-color"] : "",
          }}
        >
          <input
            type="checkbox"
            className="cf-consent-checkbox"
            name="contact[privacy_consent]"
            required={options["privacy-consent"] == "true" && element.options["privacy-consent-required"] !== 'false' ? true : false}
            value={options["privacy-consent"] == "true" ? "true" : ""}
          ></input>
          <div className="cf-consent-label">
            <span className="cf-privacy-message">
              {website.data["privacy-message"]
                ? website.data["privacy-message"]
                : "Accept privacy policy and terms of service"}
            </span>
            <a
              className="cf-privacy-link"
              href={
                website.data["privacy-link"]
                  ? website.data["privacy-link"]
                  : "#"
              }
              target="_blank"
              style={privacy_link_style}
            >
              <br></br>
              {website.data["privacy-link-text"]
                ? website.data["privacy-link-text"]
                : "Privacy Policy"}
            </a>
            &nbsp;&nbsp;
            <a
              className="cf-terms-link"
              href={
                website.data["terms-link"] ? website.data["terms-link"] : "#"
              }
              target="_blank"
              style={terms_link_style}
            >
              {website.data["terms-link-text"]
                ? website.data["terms-link-text"]
                : "Terms of Service"}
            </a>
          </div>
        </label>
      </div>
    </React.Fragment>
  );
};

export default ElementFormConsentFields;
