import React, { useContext, useState, useEffect, useRef } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import froalaExtend from "packs/ctas/froala_extend.js";

const VariantBuilderSettingEditor = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  const [modalVisibility, setModalVisibility] = useState(null);
  const editorDiv = useRef(null);
  const modalId = `modal-${props.object_type}-${props.object_id}-${props.setting_name}-editor`;
  const FroalaEditor = froalaExtend(contextData.objects.website);

  let website = contextData.objects.website;
  let froala;

  const stylesheet = style.stylesheet(
    props.builder,
    contextData.device,
    props.cta,
    props.variant,
    props.object,
    props.setting_name
  );

  // prettier-ignore
  function updateInput(html) {
    const selector = `input[name="${props.setting_name}"][data-object_type="${props.object_type}"]${props.object_id ? `[data-object_id="${props.object_id}"]` : ""}`;
    var input = document.querySelector(selector);

    if (input && input.value !== html) {
      input.value = html;

      var event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      input.dispatchEvent(event);
      
      if (['field_options', 'content_toggle_items', 'content_list_items', 'products', 'product_variants'].includes(props.object_type) == false) {
        dispatchCustomEvent("selectObject", {
          object_type: props.object_type,
          object_id: props.object_id,
        });
      }
    }
  }

  function livePreview(froala) {
    if (props.editor_live_preview !== false) {
      let html = froala.html.get(true);
      let previewSelector =
        '#builder .cf-element[data-element-id="' + props.object_id + '"]';
      if (props.preview_selector) {
        previewSelector += ` ${props.preview_selector}`;
      }

      previewSelector += " .froala-editor";

      const builder_preview = document.querySelector(previewSelector);
      if (builder_preview && html) {
        builder_preview.innerHTML = html;
      }
    }
  }

  useEffect(() => {
    let brand_color_vibrant = "#0072f7";
    let brand_color_dark = "#000";
    let brand_color_light = "#fff";

    if (website && website.data) {
      brand_color_vibrant =
        website.data["brand_color_vibrant"] || brand_color_vibrant;
      brand_color_dark =
        website.data["brand_color_dark"] || brand_color_dark;
      brand_color_light =
        website.data["brand_color_light"] || brand_color_light;
    }

    let toolbarButtons = [
      "paragraphFormat",
      "insertLink",
      "bold",
      "italic",
      "fontFamily",
      "underline",
      "strikeThrough",
      "align",
      "fontSize",
      "lineHeight",
      "textColor",
      "backgroundColor",
      "customHTML"
    ];

    // prettier-ignore
    let color_codes = [
      '#FFFFFF', '#bdc3c7', '#7f8c8d', '#6C7A89',
      '#3498db', '#0075ff', '#2C82C9', '#475577', 
      '#34495e', '#28324E', '#000000', '#4ECDC4', 
      '#1abc9c', '#2ecc71', '#27ae60', '#16a085', 
      '#019875', '#1E824C', '#F5D76E', '#FAC51C', 
      '#FBA026', '#e67e22', '#E25041', '#B8312F', 
      '#96281B', '#f624a2', '#BF55EC', '#9A12B3', 
      '#663399', brand_color_light, brand_color_vibrant, 
      brand_color_dark, 'REMOVE'
    ];

    // prettier-ignore
    let font_sizes = ['None', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '60', '72', '96'];

    // prettier-ignore
    let toolbar_buttons_img = ['filestackReplace', 'imageAlign', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', 'imageStyle', 'imageSize', '-', 'imageRemove', 'imageAlt', 'imageDisplay'];

    // prettier-ignore
    var editor = editorDiv.current;
    editor.innerHTML = props.value ? props.value : "";

    if (props.editor_toolbar_exclusions) {
      toolbarButtons = toolbarButtons.filter(function (el) {
        return !props.editor_toolbar_exclusions.includes(el);
      });
    }

    froala = new FroalaEditor(editor, {
      attribution: false,
      toolbarButtons: {
        'moreText': {
          'buttons': toolbarButtons,
          'buttonsVisible': 2
        },
        'misc': {
          'buttons': [
            "filestack",
            "personalize",
            "clearFormatting"
          ],
          'buttonsVisible': 3
        }
      },
      charCounterCount: false,
      keepFormatOnDelete: true,
      enter: FroalaEditor.ENTER_BR,
      fontSize: font_sizes,
      fontFamily: builder_fonts,
      fontFamilySelection: true,
      imageEditButtons: toolbar_buttons_img,
      imageResize: false,
      colorsBackground: color_codes,
      colorsStep: 8,
      colorsText: color_codes,
      key: "2J1B10dA5F5A3A4E3E3J3C-22VKOG1FGULVKHXDXNDXc2a1Kd1SNdF3H3A8D7D4F4B3E3E2A10==",
      linkStyles: {
        "cf-link-no-underline": "No underline",
        "cf-link-blue": "Blue hyperlink",
      },
      linkList: [],
      pastePlain: true,
      events: {
        'initialized':  function () {
          $('.fr-element[contenteditable="true"]').attr("data-gramm", "false");

          $('.fr-command[data-cmd="fontFamily"]').on('click', function() {
            ctas.builder.fonts.froala_dropdown();
          });
        },
        'commands.before': function (cmd, e) {
          if (
            cmd == "customHTML" && 
            !modalVisibility &&
            builder.displayFeatureGate(e, "Custom HTML")
          ) {
            this.events.trigger('blur', [], true);
            setModalVisibility(true);
          }
  
          if (cmd == "filestack" || cmd == "filestackReplace") {
            froala.filestackUploading = true;
          }
        },
        'input': function (inputEvent) {
          livePreview(froala);
        },
        'contentChanged': function () {
          livePreview(froala);
  
          if (froala.colorChanging == true) {
            froala.events.trigger('blur', [], true);
            froala.colorChanging = undefined;
          }
        },
        'blur': function () {
          if (
            (froala.undo_index > 1 || froala.colorChanging == true) &&
            froala.filestackUploading !== true
          ) {
            froala.html.cleanEmptyTags();
            let html = froala.html.get(true);
            html = html.replace('id="selected"', "");
  
            if (html || props.allow_empty == true) {
              updateInput(html);
            }
          }
        }
      }
    })
  }, [_.get(props.object, props.setting_name)]);

  useEffect(() => {
    let modal = document.getElementById(modalId);

    let parentModal = $(modal).parents(".modal")[0];
    if (parentModal) {
      $(modal)
        .off("shown.bs.modal")
        .on("shown.bs.modal", function () {
          $(parentModal).animate({ scrollTop: 0 });
        });
    }

    if (modalVisibility == true) {
      $(modal).modal("show");
      $('.fr-toolbar').hide();

      let codeContainer = modal.querySelector(
        '.step-script[data-name="' + props.setting_name + '"]'
      );

      let codeTextArea = document.createElement("textarea");
      codeTextArea.className = "scriptEditor";
      codeTextArea.value = props.value ? props.value : "";

      codeContainer.innerHTML = "";
      codeContainer.appendChild(codeTextArea);

      let codeEditor = CodeMirror.fromTextArea(codeTextArea, {
        mode: "htmlmixed",
        theme: "material",
        lineWrapping: true,
        tabSize: 2,
      });

      $(modal)
        .off("hidden.bs.modal")
        .on("hidden.bs.modal", function (e) {
          let value = codeEditor.getValue();
          setModalVisibility(false);
          if (value !== props.value) {
            builder.update([
              {
                object_type: props.object_type,
                object_id: props.object_id,
                setting_name: props.setting_name,
                value: value,
                skip_history: props.skip_history,
              },
            ]);
          }
        });

      modal.onclick = function (e) {
        if (e.target == modal) {
          if (
            $(modal).is(":visible") &&
            $(modal).find(".modal:visible").length == 0
          ) {
            setModalVisibility(false);
          }
        }
      };
    } else {
      $(modal).modal("hide");
    }
  }, [modalVisibility]);

  useEffect(() => {
    if (modalVisibility) {
      setContextData({
        ...contextData,
        selected_object_editing: modalVisibility,
      });
    }
  }, [modalVisibility]);
  
  return (
    <React.Fragment>
      <input
        type={environment == "test" ? "text" : "hidden"}
        name={props.setting_name}
        data-object_type={props.object_type}
        data-object_id={props.object_id}
        value={props.value ? props.value : ""}
        onInput={(e) => {
          builder.update([
            {
              object_type: props.object_type,
              object_id: props.object_id,
              setting_name: props.setting_name,
              value: e.target.value,
              skip_history: props.skip_history,
            },
          ]);
        }}
      />
      <div
        data-setting_name={props.setting_name}
        data-object_type={props.object_type}
        data-object_id={props.object_id}
        className={"editor row hard-center"}
        ref={editorDiv}
        dangerouslySetInnerHTML={{ __html: props.value ? props.value : "" }}
      ></div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      
      <div
        className="modal"
        id={modalId}
        data-name={props.setting_name}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        data-backdrop="false"
        style={{
          background: "rgb(0, 0, 0, 0.21)",
          overflow: "visible",
        }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{
            maxWidth: "900px",
            width: "100%",
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path d="M12.2996 4.1001L4.09961 12.3001M12.2996 12.3001L4.09961 4.1001" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              </button>
              <h4
                className="modal-title bold smooth"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {props.label}
              </h4>
            </div>
            <div className="modal-body">
              <div
                className="row hard-center"
                style={{
                  maxWidth: "900px",
                }}
              >
                <div
                  data-name={props.setting_name}
                  className="step-script"
                ></div>
                <p
                  className="script-editor-note light smooth semibold"
                  style={{
                    marginTop: "10px",
                    textTransform: "initial",
                    fontSize: "12px",
                  }}
                >
                  Javascript must have &lt;script&gt; tags. CSS must have
                  &lt;style&gt; tags. Added scripts will run as asynchronous.
                </p>
              </div>
            </div>
            <div className="modal-footer !border-slate-200 dark:!border-slate-800">
              <button
                type="button"
                className="btn blue-button semibold smooth"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const style = {
  stylesheet: (builder, device, cta, variant, object, setting_name) => {
    let options = { ...object.options };

    let fontSize;
    if (options["font-size"] && options["font-size"] !== "theme") {
      fontSize = options["font-size"];
    } else if (
      options["font-size"] == "theme" &&
      variant.options["font-size"]
    ) {
      fontSize = variant.options["font-size"];
    }

    let fontFamily;
    if (options["font-family"] && options["font-family"] !== "theme") {
      fontFamily = options["font-family"];
    } else if (
      options["font-family"] == "theme" &&
      variant.options["font-family"]
    ) {
      fontFamily = variant.options["font-family"];
    }

    let fontWeight;
    if (options["font-weight"] && options["font-weight"] !== "theme") {
      fontWeight = options["font-weight"];
    } else if (
      options["font-weight"] == "theme" &&
      variant.options["font-weight"]
    ) {
      fontWeight = variant.options["font-weight"];
    }

    let fontColor;
    if (options["font-color"] && options["font-color"] !== "theme") {
      fontColor = options["font-color"];
    } else if (
      options["font-color"] == "theme" &&
      variant.options["font-color"]
    ) {
      fontColor = variant.options["font-color"];
    }

    let textShadow;
    if (options["box-shadow"] == "soft") {
      textShadow = "0 3px 5px rgb(179 179 179 / 78%)";
    }
    if (options["box-shadow"] == "hard") {
      textShadow = "-3px 3px 0 rgb(0 0 0 / 10%)";
    }
    if (options["box-shadow"] == "custom") {
      textShadow = "";
      textShadow +=
        (object.options["box-shadow-horizontal-distance"]
          ? `${object.options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      textShadow +=
        (object.options["box-shadow-vertical-distance"]
          ? `${object.options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      textShadow +=
        (object.options["box-shadow-blur-radius"]
          ? `${object.options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      textShadow +=
        (object.options["box-shadow-color"]
          ? object.options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    let desktopStyles = `
.setting[data-setting_name="${setting_name}"] .fr-view h1,
.setting[data-setting_name="${setting_name}"] .fr-view h2, 
.setting[data-setting_name="${setting_name}"] .fr-view h3, 
.setting[data-setting_name="${setting_name}"] .fr-view h4, 
.setting[data-setting_name="${setting_name}"] .fr-view h5, 
.setting[data-setting_name="${setting_name}"] .fr-view p, 
.setting[data-setting_name="${setting_name}"] .fr-view span,
.setting[data-setting_name="${setting_name}"] .fr-view {
  ${fontFamily && fontFamily !== 'inherit' ? `font-family: ${fontFamily}, sans-serif;` : ''}
  ${fontWeight ? `font-weight: ${fontWeight} !important;` : ''}
}
`;

    if (fontFamily == "inherit") {
      // prettier-ignore
      desktopStyles += ` 
.setting[data-setting_name="${setting_name}"] .fr-view span {
font-family: inherit;
}`
    }

    return desktopStyles;
  },
};

export default VariantBuilderSettingEditor;
