import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ElementFormPresetFields from "../elements/ElementFormPresetFields";
import ElementFormCustomFields from "../elements/ElementFormCustomFields";
import ElementFormConsentFields from "../elements/ElementFormConsentFields";
import ElementFormButton from "../elements/ElementFormButton";

const ElementForm = (props) => {
  let element = props.element;
  let options = element.options;
  let important = props.builder !== true ? " !important" : "";
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  let consent_visible = "false";
  if (
    options["privacy-consent"] == "true" &&
    options["marketing-consent"] !== "true"
  ) {
    consent_visible = "privacy";
  } else if (
    options["privacy-consent"] !== "true" &&
    options["marketing-consent"] == "true"
  ) {
    consent_visible = "marketing";
  } else if (
    options["privacy-consent"] == "true" &&
    options["marketing-consent"] == "true"
  ) {
    consent_visible = "both";
  }

  // set total visible fields count
  let total_visible_fields;
  if (options["visible-fields"]) {
    total_visible_fields = options["visible-fields"];
  } else if (options["form-type"] == "horizontal") {
    total_visible_fields = "1";
  }

  if (props.builder == true) {
    useEffect(() => {
      let form = document.querySelector(
        '#builder form[data-element-id="' + element.id + '"]'
      );
      $(form).on("submit", function (e) {
        e.preventDefault();
        return false;
      });
    });
  }

  return (
    <React.Fragment>
      <div
        id={"element" + element.id + "-content"}
        className="element-content cf-form"
      >
        <div
          id={"element" + element.id + "_form"}
          className={classes}
          data-element-id={element.id}
          data-consent={consent_visible}
          data-visible-fields={total_visible_fields}
          style={{
            fontFamily: options["field-font"]
              ? options["field-font"] + ", sans-serif" + important
              : undefined,
          }}
          data-form-type={options["form-type"]}
          data-form-type-mobile={options["form-type-mobile"]}
          data-font={element.options["field-label-font-family"]}
        >
          <form
            acceptCharset="UTF-8"
            data-element-id={element.id}
            element-id={element.id}
            data-remote="true"
            className="new_contact"
            method="post"
            noValidate="novalidate"
            data-hide-prefilled-fields={
              element.options["hide-prefilled-fields"]
            }
          >
            <input type="hidden" name="contact[visitor_token]" />
            <input type="hidden" name="contact[source_url]" />
            <input type="hidden" name="contact[contactable_type]" value="Cta" />
            <input
              type="hidden"
              name="contact[contactable_id]"
              value={props.cta.id}
            />
            <input
              type="hidden"
              name="contact[website_id]"
              value={element.website_id}
            />
            <input
              type="hidden"
              name="contact[element_id]"
              value={element.id}
            />
            <input
              type="hidden"
              id={"form" + element.id + "-broadcast-id"}
              name="contact[broadcast_id]"
            />
            <input
              type="hidden"
              id={"form" + element.id + "-variant-id"}
              name="contact[variant_id]"
              value={props.variant_id}
            />
            <div className="cf-form-fields">
              <ElementFormPresetFields {...props} />
              <ElementFormCustomFields {...props} />
            </div>
            <div className="cf-form-consent">
              <ElementFormConsentFields
                element={element}
                website={props.website}
                builder={props.builder}
              />
            </div>
            <div className="cf-form-button">
              <ElementFormButton
                element={element}
                builder={props.builder}
                {...props}
              ></ElementFormButton>
            </div>
          </form>
        </div>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes =
      "cf-form cf-form-2 cf-form-" +
      (device == "mobile" && options["form-type-mobile"]
        ? options["form-type-mobile"]
        : options["form-type"]);

    classes +=
      " " +
      (options["form-theme"] ? options["form-theme"] : "cf-form-theme-default");

    classes += " " + (options["cf-alignment"] ? options["cf-alignment"] : "");

    classes +=
      " " +
      (options["form-width"] && options["form-width"] !== "custom"
        ? options["form-width"]
        : "");

    if (!options["field-size"] || options["field-size"] == "cf-field-regular") {
      classes += " cf-field-regular";
    }

    if (options["field-size"] == "cf-field-large") {
      classes += " cf-field-large";
    }

    if (options["field-size"] == "custom") {
      classes += " cf-field-custom";
    }

    classes +=
      " " +
      (options["field-rounded"]
        ? options["field-rounded"]
        : "cf-field-slightly-rounded");
    return classes;
  },

  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    let fieldRadius;
    if (options["field-rounded"] == "cf-field-not-rounded") {
      fieldRadius = "0px";
    }
    if (options["field-rounded"] == "cf-field-slightly-rounded") {
      fieldRadius = "3px";
    }
    if (options["field-rounded"] == "cf-field-fully-rounded") {
      fieldRadius = "100px";
    }
    if (options["field-rounded"] == "custom") {
      fieldRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          fieldRadius += `${
            _.isNil(options["field-radius-" + side]) == false
              ? options["field-radius-" + side]
              : "3"
          }px `;
        }
      );
    }

    let fieldFontSize;
    if (options["field-font-size"]) {
      fieldFontSize = options["field-font-size"];
    }

    let fieldLabelFontSize;
    if (options["field-label-font-size"]) {
      fieldLabelFontSize = options["field-label-font-size"];
    }

    let fieldLabelFontFamily;
    if (options["field-label-font-family"]) {
      fieldLabelFontFamily = options["field-label-font-family"];
    }

    let fontWeight;
    if (
      options["field-font-weight"] &&
      options["field-font-weight"] !== "theme"
    ) {
      fontWeight = options["field-font-weight"];
    }

    let fieldLabelFontWeight;
    if (options["field-label-font-weight"]) {
      fieldLabelFontWeight = options["field-label-font-weight"];
    }

    let boxShadow;

    if (options["box-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["box-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["box-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["box-shadow-horizontal-distance"]
          ? `${element.options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-vertical-distance"]
          ? `${element.options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-blur-radius"]
          ? `${element.options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-spread-radius"]
          ? `${element.options["box-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-color"]
          ? element.options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    let handleStyle = `
      ${options['field-handle-color'] ? 'background: ' + options['field-handle-color'] + ';' : ''} 
      ${options['field-handle-border-color'] ? 'border-color: ' + options['field-handle-border-color'] + ';' : ''}`

    // source: app/views/cta/_css.html.erb:2026
    let regularInputPadding = {
      top: 11,
      right: 0,
      bottom: 9,
      left: 10,
    };

    // source: app/views/cta/_css.html.erb:2074
    let largeInputPadding = {
      top: 12,
      right: 0,
      bottom: 10,
      left: 10,
    };

    // line-height has been deprecated from CF input forms due to incompatibility with select fields. This is a formula
    // to keep the same old looking but using padding.
    // Multiplies the fontSize for 1.5 bc it is the old line-height value for inputs ( now it's replace for this padding )
    let consideredFontSize =
      fieldFontSize || variant.options["field-font-size"] || 15;
    let inputPaddingFormula = Math.floor((consideredFontSize * 1.5) / 4 / 2);

    // prettier-ignore
    let regularInputPaddingCalculated = `${regularInputPadding["top"] + inputPaddingFormula}px
      ${regularInputPadding["right"]}px
      ${regularInputPadding["bottom"] + inputPaddingFormula}px
      ${regularInputPadding["left"]}px`;

    // prettier-ignore
    let largeInputPaddingCalculated = `${largeInputPadding["top"] + inputPaddingFormula}px
      ${largeInputPadding["right"]}px
      ${largeInputPadding["bottom"] + inputPaddingFormula}px
      ${largeInputPadding["left"]}px`;

    let intFieldSizeValue = parseInt(options["field-size-value"]);
    // prettier-ignore
    let customInputPaddingCalculated = `${intFieldSizeValue + inputPaddingFormula}px
      ${intFieldSizeValue}px
      ${intFieldSizeValue + inputPaddingFormula}px
      ${intFieldSizeValue}px`;

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2 {
  ${options["form-width"] == 'custom' && options["form-width-value"] ? 'max-width: ' + options["form-width-value"] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="text"], 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="email"], 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="tel"],
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="number"],
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default textarea, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default select {
  ${_.isNil(options["field-background-color"]) == false ? 'background: ' + options["field-background-color"] + ' !important;' : ''}
  ${_.isNil(options["field-text-color"]) == false ? 'color: ' + options["field-text-color"] + ' !important;' : ''}
  ${_.isNil(options["field-border-style"]) == false ? 'border-style: ' + options["field-border-style"] + ' !important;' : ''}
  ${_.isNil(options["field-border-width"]) == false ? 'border-width: ' + options["field-border-width"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-left"]) == false ? 'border-left-width: ' + options["field-border-width-left"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-right"]) == false ? 'border-right-width: ' + options["field-border-width-right"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-top"]) == false ? 'border-top-width: ' + options["field-border-width-top"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["field-border-width-bottom"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-color"]) == false ? 'border-color: ' + options["field-border-color"] + ' !important;' : ''}
  ${_.isNil(fieldRadius) == false ? 'border-radius: ' + fieldRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
  ${fontWeight ? `font-weight: ${fontWeight} !important;` : ''}
  ${options["field-size"] == 'custom' && _.isNil(options["field-size-value"]) == false ? `padding: ${customInputPaddingCalculated} !important; padding-right: 5px !important;` : `padding: ${regularInputPaddingCalculated} !important;`}
  ${fieldFontSize ? `font-size: ${fieldFontSize}px !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-field-large input[type="text"],
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-field-large input[type="email"],
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-field-large input[type="tel"],
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-field-large input[type="number"],
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-field-large textarea,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-field-large select {
  ${options["field-size"] != 'custom' ? `padding: ${largeInputPaddingCalculated} !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2 select.cf-sms-countries {
  ${consideredFontSize ? `font-size: ${consideredFontSize - 1}px !important;` : ''}
  padding-left: 10px !important;
  padding-right: 0px !important;
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .cf-label {
  ${options["label-color"] ? 'color: ' + options["label-color"] + ' !important;' : ''}
  ${fieldLabelFontSize ? `font-size: ${fieldLabelFontSize}px !important;` : ''}
  ${fieldLabelFontWeight ? `font-weight: ${fieldLabelFontWeight} !important;` : fontWeight ? `font-weight: ${fontWeight};` : ''}
  ${fieldLabelFontFamily ? `font-family: ${fieldLabelFontFamily} !important;` : options["field-font"] ? `font-weight: ${options["field-font"]};` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .cf-consent-label {
  ${fieldLabelFontWeight ? `font-weight: ${fieldLabelFontWeight} !important;` : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .cf-field-range-slider {
  ${_.isNil(options["field-background-color"]) == false ? 'background: ' + options["field-background-color"] + ' !important;' : ''}
  ${_.isNil(options["field-border-style"]) == false ? 'border-style: ' + options["field-border-style"] + ' !important;' : ''}
  ${_.isNil(options["field-border-width"]) == false ? 'border-width: ' + options["field-border-width"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-left"]) == false ? 'border-left-width: ' + options["field-border-width-left"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-right"]) == false ? 'border-right-width: ' + options["field-border-width-right"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-top"]) == false ? 'border-top-width: ' + options["field-border-width-top"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["field-border-width-bottom"] + 'px !important;' : ''}
  ${_.isNil(options["field-border-color"]) == false ? 'border-color: ' + options["field-border-color"] + ' !important;' : ''}
  ${_.isNil(fieldRadius) == false ? 'border-radius: ' + fieldRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .noUi-value {
  ${options["label-color"] ? 'color: ' + options["label-color"] + ';' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .noUi-marker {
  ${options["label-color"] ? 'background: ' + options["label-color"] + ';' : ''};
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .noUi-connect {
  ${_.isNil(options["field-border-color"]) == false ? 'background: ' + options["field-border-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .noUi-handle {
  ${handleStyle}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="text"]::placeholder, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="email"]::placeholder, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="tel"]::placeholder,
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default textarea::placeholder, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default select::placeholder {
  ${_.isNil(options["field-text-color"]) == false ? 'color: ' + options["field-text-color"] + ' !important;' : ''}
  ${fontWeight ? `font-weight: ${fontWeight} !important;` : ''}
}`;

    let fontSizeMobile;
    if (options["field-font-size-mobile"]) {
      fontSizeMobile = options["field-font-size-mobile"];
    }

    let fontLabelSizeMobile;
    if (options["field-label-font-size-mobile"]) {
      fontLabelSizeMobile = options["field-label-font-size-mobile"];
    }

    let consideredFontSizeMobile =
      fontSizeMobile ||
      fieldFontSize ||
      variant.options["field-font-size-mobile"] ||
      variant.options["field-font-size"] ||
      15;

    // prettier-ignore
    const mobileStyles =
    `/* mobile styles */
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="text"],
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="email"],
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="tel"],
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default input[type="number"],
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default textarea,
    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default select {
      ${fontSizeMobile ? `font-size: ${fontSizeMobile}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2 select.cf-sms-countries {
      ${consideredFontSizeMobile ? `font-size: ${consideredFontSizeMobile - 2}px !important;` : ''}
    }

    #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-form-2.cf-form-theme-default .cf-label {
      ${fontLabelSizeMobile ? `font-size: ${fontLabelSizeMobile}px !important;` : ''}
    }
    `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementForm;