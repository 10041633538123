import React, { useEffect, useRef, useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const ZoomableCanvas = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const stylesheet = style.stylesheet(contextData);

  const minScale = props.minScale || 0.25;
  const pinchZoomRatio = props.pinchZoomRatio || 2;
  const mouseZoomRatio = props.mouseZoomRatio || 3;
  const panningRatio = props.panningRatio || 0.75;

  const currentScale = useRef(props.initialScale || 1);
  const transformX = useRef(props.initialX || 0);
  const transformY = useRef(props.initialY || 0);
  const mousePosition = useRef({ startPositionX: 0, startPositionY: 0 });

  const zoomableDom = useRef(null);

  let lastCanvasUpdatedAt = Date.now();

  const updateZoomControls = () => {
    document.querySelectorAll(".cf-zoom-control-percentage").forEach((zoomable_percentage) => {
      zoomable_percentage.innerHTML = `${Math.round(
        currentScale.current * 100
      )}%`;
    });
  }

  const updateCanvas = () => {
    zoomableDom.current.style.transform = 'matrix(' +
      currentScale.current + ', 0, 0, ' +
      currentScale.current + ', ' +
      transformX.current + ', ' + transformY.current + ')';

    setTimeout(() => {
      if (Date.now() - lastCanvasUpdatedAt >= 100) {
        updateZoomControls();
      }
    }, 100);

    lastCanvasUpdatedAt = Date.now();
  };

  // Handlers

  const handleStopImmediatePropagation = (event) => {
    event.stopImmediatePropagation();
  };

  const handleWheelPanZoom = (event) => {
    handlePinchZoom(event);
    handlePanning(event);
  };

  const handlePinchZoom = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();

      const calculated_ratio =
        Math.abs(event.deltaY) > 10
          ? Math.sign(event.deltaY) * mouseZoomRatio
          : event.deltaY * pinchZoomRatio;

      const newScale = currentScale.current * Math.exp(-calculated_ratio / 100);
      const ratio = 1 - newScale / currentScale.current;
      const { clientX, clientY } = event;

      // Zoom relative to pointer position
      currentScale.current =
        newScale >= 0.25 && newScale <= 2
          ? newScale
          : newScale < 0.25
          ? 0.25
          : newScale > 2
          ? 2
          : 1;
      const calculatedX =
        transformX.current + (clientX - transformX.current) * ratio;
      const calculatedY =
        transformY.current + (clientY - transformY.current) * ratio;

      parsePanningDeltasWithinLimit(calculatedX, calculatedY, event);

      updateCanvas();
    }
  };

  const parsePanningDeltasWithinLimit = (calculatedX, calculatedY, event) => {
    let scrollHeight = zoomableDom.current.scrollHeight;
    let minX = 2000 * currentScale.current * -1;
    let maxX =
      currentScale.current > 0.7
        ? 500 * currentScale.current
        : 3500 * currentScale.current;
    let minY = Math.abs(scrollHeight) * currentScale.current * -1;
    let maxY = Math.abs(scrollHeight);

    if (calculatedX >= minX && calculatedX <= maxX) {
      transformX.current = calculatedX;
    } else if (calculatedX < minX) {
      transformX.current = minX;
    } else if (calculatedX > maxX) {
      transformX.current = maxX;
    }

    if (calculatedY >= minY && calculatedY <= maxY) {
      transformY.current = calculatedY;
    } else if (calculatedY < minY) {
      transformY.current = minY;
    } else if (calculatedY > maxY) {
      transformY.current = maxY;
    }
  };

  const handlePanning = (event) => {
    if (!event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();

      const direction = -1;
      const calculatedY =
        transformY.current + event.deltaY * panningRatio * direction;
      const calculatedX =
        transformX.current + event.deltaX * panningRatio * direction;

      parsePanningDeltasWithinLimit(calculatedX, calculatedY, event);
      updateCanvas();
    }
  };

  const handleMouseStart = function (event) {
    mousePosition.current.startPositionX = event.pageX - transformX.current;
    mousePosition.current.startPositionY = event.pageY - transformY.current;
  };

  const handleMouseMove = function (event) {
    if (event.which == 2) {
      event.stopPropagation();
      event.preventDefault();

      transformX.current = event.pageX - mousePosition.current.startPositionX;
      transformY.current = event.pageY - mousePosition.current.startPositionY;
      updateCanvas();
    }
  };

  // Controls

  const resetCanvas = () => {
    currentScale.current = contextData.device == "mobile" ? 1 : props.initialScale || 1;
    transformX.current = contextData.device == "mobile" ? 0 : props.initialX || 0;
    transformY.current = props.initialY || 0;
    mousePosition.current = { startPositionX: 0, startPositionY: 0 };

    updateCanvas();
  };

  const zoomIn = () => {
    currentScale.current += 0.25;

    updateCanvas();
  };

  const zoomOut = () => {
    if (currentScale.current - 0.25 < minScale) {
      currentScale.current = minScale;
    } else {
      currentScale.current -= 0.25;
    }

    updateCanvas();
  };

  useEffect(() => {
    resetCanvas();
  }, [contextData.zoomable_canvas_locked]);

  useEffect(() => {
    resetCanvas();
  }, [contextData.device]);

  useEffect(() => {
    if (contextData.zoomable_canvas_locked !== true) {
      const zoomable_percentage = document.querySelector("#cf-zoom-control-percentage");
      zoomable_percentage.onmouseover = function (e) { zoomable_percentage.innerHTML = "Reset" };
      zoomable_percentage.onmouseleave = function (e) { zoomable_percentage.innerHTML = `${Math.round(currentScale.current * 100)}%` };

      document.querySelector('.variant-edit').addEventListener("wheel", handleWheelPanZoom, { passive: false });
      document.querySelector('.variant-edit').addEventListener("mousedown", handleMouseStart, { passive: false });
      document.querySelector('.variant-edit').addEventListener("mousemove", handleMouseMove, { passive: false });
      document.querySelector('#options-panel').addEventListener("wheel", handleStopImmediatePropagation);

      let onboard = document.querySelector('#onboard-popup')
      if (onboard) { onboard.addEventListener("wheel", handleStopImmediatePropagation); }
    }

    document.addEventListener("zoomIn", zoomIn);
    document.addEventListener("zoomOut", zoomOut);
    document.addEventListener("resetCanvas", resetCanvas);
    updateCanvas();

    return function cleanup() {
      document.removeEventListener("zoomIn", zoomIn);
      document.removeEventListener("zoomOut", zoomOut);
      document.removeEventListener("resetCanvas", resetCanvas);

      document.querySelector('.variant-edit').removeEventListener("wheel", handleWheelPanZoom);
      document.querySelector('.variant-edit').removeEventListener("mousedown", handleMouseStart);
      document.querySelector('.variant-edit').removeEventListener("mousemove", handleMouseMove);
      document.querySelector("#options-panel").removeEventListener("wheel", handleStopImmediatePropagation);

      let onboard = document.querySelector('#onboard-popup')
      if (onboard) { onboard.removeEventListener("wheel", handleStopImmediatePropagation); }
    };
  });

  return (
    <React.Fragment>
      <div ref={zoomableDom} id="cf-zoomable-canvas">{props.children}</div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const style = {
  stylesheet: (contextData) => {
    // prettier-ignore
    const transformOrigin = contextData.zoomable_canvas_locked ? "top" : "0 0";

    const desktopStyles =
      `body {
        overflow-x: hidden;
      }

      #cf-zoomable-canvas {
        transform-origin: ${transformOrigin};
        will-change: transform;
        backface-visibility: hidden;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }

      .cf-zoom-controls {
        position: fixed;
        bottom: 5px;
        right: 300px;
        height: 36px;
        width: auto;
        z-index: 1;
        border: 1px solid #ddd;
        display: inline-flex;
        border-radius: 7px;
        padding: 0px;
        box-sizing: border-box;
        font-size: 17px;
        background-color: #fff;
        color: #000;
        margin-top: 15px;
        margin-bottom: 10px;
      }

      .cf-zoom-controls .cf-zoom-control-zoom-out {
        padding-left: 15px;
      }

      .cf-zoom-controls .cf-zoom-control-zoom-in {
        padding-right: 15px;
      }

      .cf-zoom-controls .cf-zoom-control-zoom-in,
      .cf-zoom-controls #cf-zoom-control-percentage,
      .cf-zoom-controls .cf-zoom-control-zoom-out,
      .cf-zoom-controls .cf-zoom-control-reset {
        padding: 5px 12px;
        cursor: pointer;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
      }
      `;

    return desktopStyles;
  },
};

export default ZoomableCanvas;
