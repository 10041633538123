import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsImage = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let element = builder.selected().object;
  let section = variant.sections[element.section_id];
  let step = variant.steps[section.step_id];

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={section}
        setting_name="[html]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Image Max Width"}
        value={
          element.html
            ? $(`<div>${element.html}</div>`)
                .find("img")
                .css("width")
                .replace("px", "")
            : null
        }
        device="desktop"
        condition={["image"].includes(element.element_type)}
        min={0}
        max={2560}
        allow_empty={true}
        range_step={"1"}
        skip_update={true}
        callback={(new_value) => {
          let newImage = $(element.html);

          if ($(newImage).prop("tagName") !== "IMG") {
            newImage = $(newImage).find("img");
          }

          $(newImage).css("width", `${new_value}px`);
          let newImageHTML = $(newImage)[0].outerHTML;

          builder.update([
            {
              object_type: "elements",
              object_id: element.id,
              setting_name: "[html]",
              value: newImageHTML,
            },
          ]);
        }}
      />

      <VariantBuilderSetting
        object={section}
        setting_name="[options][image-width-mobile]"
        setting_type="range"
        object_type="elements"
        object_id={element.id}
        label={"Image Max Width"}
        value={
          element.options['image-width-mobile'] ? element.options['image-width-mobile'] : element.html
            ? $(`<div>${element.html}</div>`)
                .find("img")
                .css("width")
                .replace("px", "")
            : null
        }
        device="mobile"
        min={0}
        max={2560}
        allow_empty={true}
        range_step={"1"}
        show_reset={true}
      />
    </React.Fragment>
  );
};

export default ElementSettingsImage;
