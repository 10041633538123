import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const useActions = (props) => {
  let contextData;
  let builder;

  if (!props || props.builder !== false) {
    [contextData, builder] = useContext(VariantContextData);
  }

  const Actions = {
    create: async function (action, callback) {
      builder.create("actions", { ...action }, { callback: callback });
    },

    update: async function (updates, callback) {
      builder.update(updates);

      if (callback) {
        callback();
      }
    },

    duplicate: async function (og_action, payload, skip_history, callback) {
      let attributes = {
        action_object: {
          action_type: og_action.action_type,
          tag_id: og_action.tag_id,
          contactable_id: og_action.contactable_id,
          contactable_type: og_action.contactable_type,
          cta_id: og_action.cta_id,
          data: og_action.data || {},
          recipients: og_action.recipients,
          element_id: og_action.element_id,
        },
      };

      if (payload) {
        attributes.action_object = { ...attributes.action_object, ...payload };
      }

      builder.create("actions", attributes, {
        callback: async function (action) {
          if (callback) {
            callback(action);
          }
        },
        skip_history: skip_history,
      });
    },

    remove: (action, show_message = false, callback) => {
      let message = show_message
        ? "Are you sure you want to remove this action? Once your changes are saved, it will be irreversibly deleted."
        : null;

      builder.remove("actions", action.id, message);
      if (callback) {
        callback();
      }
    },
  };

  const DetachedActions = {
    create: async function (parsedForm, callback) {
      let action = parsedForm.action_object;

      $.ajax({
        type: "POST",
        url: "/websites/" + parsedForm.website_id + "/actions",
        async: true,
        data: {
          action_object: {
            ...action,
          },
          builder: true,
        },
        success: function (data) {
          if (callback) {
            callback(data);
          }
        },
      });
    },

    update: async function (parsedForm, callback) {
      let action = parsedForm.action_object;

      $.ajax({
        type: "PUT",
        url: "/websites/" + parsedForm.website_id + "/actions/" + action.id,
        async: true,
        data: {
          action_object: {
            ...action,
          },
          builder: true,
        },
        success: function (data) {
          if (callback) {
            callback(data);
          }
        },
      });
    },

    duplicate: async function (og_action, payload, skip_history, callback) {
      let attributes = {
        action_object: {
          action_type: og_action.action_type,
          tag_id: og_action.tag_id,
          contactable_type: og_action.contactable_type,
          contactable_id: og_action.contactable_id,
          cta_id: og_action.cta_id,
          data: og_action.data || {},
          recipients: og_action.recipients,
        },
      };

      if (payload) {
        attributes.action_object = { ...attributes.action_object, ...payload };
      }

      $.ajax({
        type: "POST",
        url: "/websites/" + og_action.website_id + "/actions",
        async: true,
        data: { ...attributes },
        success: function (data) {
          if (callback) {
            callback(data);
          }
        },
      });
    },

    remove: (action, show_message = false, callback) => {
      let message = show_message
        ? "Are you sure you want to remove this action? Once your changes are saved, it will be irreversibly deleted."
        : null;

      if (confirm(message)) {
        $.ajax({
          type: "DELETE",
          url: "/websites/" + action.website_id + "/actions/" + action.id,
          async: true,
          success: function () {
            if (callback) {
              callback();
            }
          },
        });
      }
    },
  };

  let currentActionsHook =
    props && props.builder == false ? DetachedActions : Actions;
  return { Actions, currentActionsHook };
};

export default useActions;
