class ProductsIntegrationsService {
  constructor(integration_type, integration_object = null) {
    this.integration_type = integration_type == 'ShopifyGraphql' ? 'Shopify' : integration_type;
    this.integration_object = integration_object;
  }

  client() {
    switch (this.integration_type) {
      case 'Shopify':
        let parsed_store_name = window.convertflow?.app?.shopify_store_url?.replace('.myshopify.com', '')
          || (this.integration_object?.api_data?.api_url || this.integration_object?.api_data?.website)
            ?.replace("http://", "")
            ?.replace("https://", "")
            ?.replace(".myshopify.com", "")
            ?.replace("/", "")
            ?.replace(" ", "");
        let token = window.convertflow?.app?.shopify_storefront_token
          || this.integration_object?.api_data["storefront-token"];

        return new ShopifyGraphqlClient({
          store_name: parsed_store_name,
          token: token,
        });
      default:
        break;
    }
  }

  adapter() {
    switch (this.integration_type) {
      case 'Shopify':
        return new ProductsAdapterService(this.client(), 'ShopifyGraphql');
      default:
        break;
    }
  }

  saveOnCartSession(key, attributes, override = false) {
    const time_now = app.helpers.timeNow();
    let isNewSession = false;

    let session = convertflow.session || {};

    session.cart = session.cart || {};
    let parsedStoreKey = key == "ShopifyGraphql" ? "Shopify" : key;

    if (override == false) {
      session.cart[parsedStoreKey] = Object.assign({}, session.cart[parsedStoreKey], attributes);
    } else {
      session.cart[parsedStoreKey] = attributes;
    }

    app.helpers.compressAndStore(
      app.visitors.session_localstorage_key,
      session,
      false
    );

    convertflow.session = session;
    convertflow.session.newSession = isNewSession;
  }

  headlessSessionCart(force_creation = false) {
    return new Promise((resolve) => {
      if (this.client().isWithinOnlineStore()) {
        resolve(null);
      } else if (force_creation == false && convertflow.session.cart && convertflow.session.cart[this.integration_type]) {
        resolve(convertflow.session.cart[this.integration_type]);
      } else {
        if (!window.convertflow?.session.cart_created) {
          window.convertflow.session.cart_created = true;

          this.adapter().createCart().then((cartObject) => {
            this.saveOnCartSession(this.integration_type, cartObject, true);
            resolve(cartObject);
          });
        } else {
          let interval = setInterval(() => {
            if (convertflow.session.cart && convertflow.session.cart[this.integration_type]) {
              clearInterval(interval);
              resolve(convertflow.session.cart[this.integration_type]);
            }
          }, 100);
        }
      }
    });
  }

  storeCartURL(options = {}) {
    if (this.client().isWithinOnlineStore()) {
      return new Promise((resolve) => {
        resolve(this.client().storeCartURL(undefined, options));
      });
    } else {
      return new Promise((resolve) => {
        let interval = setInterval(() => {
          if (window.convertflow?.session.adding_to_cart !== true) {
            clearInterval(interval);

            this.headlessSessionCart().then((sessionCart) => {
              resolve(this.client().storeCartURL(sessionCart, options))
            });
          }
        }, 100);
      })
    }
  }

  storeCheckoutURL(options = {}) {
    if (this.client().isWithinOnlineStore()) {
      return new Promise((resolve) => {
        resolve(this.client().storeCheckoutURL(undefined, options))
      });

    } else {
      return new Promise((resolve) => {
        let interval = setInterval(() => {
          if (window.convertflow?.session.adding_to_cart !== true) {
            clearInterval(interval);

            this.headlessSessionCart().then((sessionCart) => {
              resolve(this.client().storeCheckoutURL(sessionCart, options))
            });
          }
        }, 100);
      })
    }
  }
}

if (typeof window !== 'undefined' && typeof window.convertflow == 'undefined') {
  window.ProductsIntegrationsService = ProductsIntegrationsService;
}
