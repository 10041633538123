import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

const ElementProgress = (props) => {
  let element = props.element;
  const classes = style.classes(element);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );
  const progressInner = useRef(null);

  if (props.builder == true) {
    useEffect(() => {
      if (element.options["progress-type"] == "dynamic") {
        const elementDiv = document.querySelector(
          `#builder .cf-element[data-element-id="${element.id}"]`
        );
        const stepDiv = elementDiv.closest(".cf-step");

        let stepPosition = parseInt(stepDiv.getAttribute("data-step-position"));

        const previousExcludedSteps = Array.from(
          document.querySelectorAll(
            "#builder .cf-step[data-progress-bar-count='false']"
          )
        ).filter(
          (stepDiv) => stepDiv.getAttribute("data-step-position") < stepPosition
        ).length;

        stepPosition = stepPosition - previousExcludedSteps;

        const stepCount = document.querySelectorAll(
          "#builder .cf-step[data-progress-bar-count='true']"
        ).length;

        const innerProgressWidth = (100 * stepPosition) / stepCount;
        progressInner.current.style.width = `${
          innerProgressWidth > 100 ? 100 : innerProgressWidth
        }%`;
        const progressMessageStepProgress = elementDiv.querySelector(
          ".cf-progress-step-progress"
        );
        if (progressMessageStepProgress) {
          $(progressMessageStepProgress).text(
            `${
              stepPosition > stepCount ? stepCount : stepPosition
            }/${stepCount}`
          );
        }
      } else if (element.options["progress-type"] == "static") {
        progressInner.current.style.width = null;
      }

      if (element.options["progress-type"] == "cart") {
        const elementDiv = document.querySelector(
          `#builder .cf-element[data-element-id="${element.id}"]`
        );
        const progressMessageDiv = elementDiv.querySelector(
          ".cf-progress-message"
        );

        if (progressMessageDiv) {
          let progressMessage = $(progressMessageDiv).text();
          let threshold =
            props.variant.options["cart-value-threshold"] ||
            props.website.data["cart-value-threshold"] ||
            50;
          let newProgressMessage = progressMessage
            .replace("({cart.value})", "$0.00")
            .replace("({cart.value_threshold_gap})", `$${threshold}.00`)
            .replace("({cart.value_threshold})", `$${threshold}.00`);
          $(progressMessageDiv).text(newProgressMessage);
          progressInner.current.style.width = null;
        }
      }
    }, [
      element.options["progress-type"],
      element.options["progress-format"],
      props.variant.options["cart-value-threshold"],
      element.lastUpdated,
    ]);
  }

  return (
    <React.Fragment>
      <div className="cf-progress">
        {element.options["progress-format"] == "line" && (
          <ElementProgressMessage element={element} />
        )}
        <div className={classes}>
          <div
            ref={progressInner}
            className="cf-progress-inner"
            data-progress-format={element.options["progress-format"]}
          >
            {element.options["progress-format"] == "box" && (
              <ElementProgressMessage element={element} />
            )}
          </div>
        </div>
      </div>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </React.Fragment>
  );
};

const ElementProgressMessage = (props) => {
  const element = props.element;
  return (
    <React.Fragment>
      {element.options["progress-type"] == "static" && (
        <div
          className="cf-progress-message"
          data-progress-format={element.options["progress-format"]}
          dangerouslySetInnerHTML={{
            __html: element.html,
          }}
          data-font={element.options["progress-text-font"]}
        ></div>
      )}

      {element.options["progress-type"] == "dynamic" && (
        <div
          className="cf-progress-message"
          data-font={element.options["progress-text-font"]}
        >
          {_.isNil(element.options["progress-step-translation"]) == false
            ? element.options["progress-step-translation"]
            : "Step"}{" "}
          <span className="cf-progress-step-progress"></span>
        </div>
      )}

      {element.options["progress-type"] == "cart" && (
        <div
          className="cf-progress-message"
          dangerouslySetInnerHTML={{
            __html: element.options["progress-message-cart"],
          }}
          data-font={element.options["progress-text-font"]}
        ></div>
      )}
    </React.Fragment>
  );
};

const style = {
  classes: (element) => {
    let options = element.options;
    let classes = "cf-row cf-progress-outer";

    let alignmentClass = "cf-outer-center";
    if (options["cf-alignment"]) {
      if (options["cf-alignment"].indexOf("left") > -1) {
        alignmentClass = "cf-outer-left";
      } else if (options["cf-alignment"].indexOf("center") > -1) {
        alignmentClass = "cf-outer-center";
      } else if (options["cf-alignment"].indexOf("right") > -1) {
        alignmentClass = "cf-outer-right";
      }
    }
    classes += " " + alignmentClass;

    classes +=
      " " +
      (options["progress-format"] !== "box"
        ? "cf-progress-format-line"
        : "cf-progress-format-box");

    return classes;
  },
  stylesheet: (builder, device, cta, element) => {
    let options = { ...element.options };

    let outerWidth = "400";
    if (options["progress-outer-width"]) {
      outerWidth = options["progress-outer-width"];
    }

    let innerWidth;
    if (options["progress-type"] == "static") {
      innerWidth = options["progress-inner-width"]
        ? options["progress-inner-width"]
        : "50";
    }

    let outerRadius = "";
    if (options["progress-outer-radius"] == "cf-not-rounded") {
      outerRadius = "0px";
    }
    if (options["progress-outer-radius"] == "cf-slightly-rounded") {
      outerRadius = "4px";
    }
    if (options["progress-outer-radius"] == "cf-fully-rounded") {
      outerRadius = "100px";
    }
    if (options["progress-outer-radius"] == "custom") {
      outerRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          outerRadius += `${
            _.isNil(options["progress-outer-radius-" + side]) == false
              ? options["progress-outer-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let boxShadow;

    if (options["box-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["box-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["box-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["box-shadow-horizontal-distance"]
          ? `${element.options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-vertical-distance"]
          ? `${element.options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-blur-radius"]
          ? `${element.options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-spread-radius"]
          ? `${element.options["box-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["box-shadow-color"]
          ? element.options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress {
  ${outerRadius ? 'border-radius: ' + outerRadius + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-outer {
  ${outerWidth ? 'max-width: ' + outerWidth + 'px !important;' : ''}
  ${options["progress-outer-background-color"] ? 'background: ' + options["progress-outer-background-color"] + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
  ${outerRadius ? 'border-radius: ' + outerRadius + ' !important;' : ''}
  ${options["progress-border-style"] ? 'border-style: ' + options["progress-border-style"] + ' !important;' : ''}
  ${_.isNil(options["progress-border-width"]) == false ? 'border-width: ' + options["progress-border-width"] + 'px !important;' : ''}
  ${_.isNil(options["progress-border-width-left"]) == false ? 'border-left-width: ' + options["progress-border-width-left"] + 'px !important;' : ''}
  ${_.isNil(options["progress-border-width-right"]) == false ? 'border-right-width: ' + options["progress-border-width-right"] + 'px !important;' : ''}
  ${_.isNil(options["progress-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["progress-border-width-bottom"] + 'px !important;' : ''}
  ${_.isNil(options["progress-border-width-top"]) == false ? 'border-top-width: ' + options["progress-border-width-top"] + 'px !important;' : ''}
  ${options["progress-border-color"] ? 'border-color: ' + options["progress-border-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-inner {
  ${innerWidth ? 'min-width: ' + innerWidth + '%;' : ''}
  ${element.options["progress-format"] !== "box" && options['progress-height'] ? 'min-height: ' + options['progress-height'] + 'px !important;' : ''}
  ${element.options["progress-format"] == "box" && options['progress-inner-padding'] ? 'padding: ' + options['progress-inner-padding'] + 'px !important;' : ''}
  ${options["progress-inner-background-color"] ? 'background: ' + options["progress-inner-background-color"] + ' !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h1, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message p, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message span {
  ${options["progress-text-font"] ? 'font-family: ' + options["progress-text-font"] + ', Helvetica, Arial, sans-serif !important;' : ''}
  ${options['progress-text-font-weight'] ? 'font-weight: ' + options['progress-text-font-weight'] + ' !important;' : ''}
  ${options["progress-text-color"] ? 'color: ' + options["progress-text-color"] + ' !important;' : ''} 
  ${options["progress-text-size"] ? 'font-size: ' + options["progress-text-size"] + 'px !important;' : ''}
}`;

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-inner {
  ${element.options["progress-format"] == "line" && options['progress-height-mobile'] ? 'min-height: ' + options['progress-height-mobile'] + 'px !important;' : ''}
  ${element.options["progress-format"] == "box" && options['progress-inner-padding-mobile'] ? 'padding: ' + options['progress-inner-padding-mobile'] + 'px !important;' : ''}
}

#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h1, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h2, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h3, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h4, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message h5, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message p, 
#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-progress-message span {
  ${options["progress-text-size-mobile"] ? 'font-size: ' + options["progress-text-size-mobile"] + 'px !important;' : ''}
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default ElementProgress;
