var upgrade_modal = function (e, limit, message, plan_to_display, event_type) {
  console.log(plan_to_display, message);
  
  if (e !== undefined && e !== "event") {
    e.preventDefault();
  }

  var modal_div = $("#upgrade");
  var gate_div = $(
    '.feature-gate-content[data-plan-type="' + plan_to_display + '"]'
  );
  var message_div = $(gate_div).find(".feature-message");
  var current_plan_type = $(modal_div).attr("data-plan-type");
  var websites_plan = $(modal_div).attr("data-websites-plan");
  var website_premium = $(modal_div).attr("data-website-premium");
  var website_plan = $(modal_div).attr("data-website-plan");

  // override plan_to_display if just a premium website upgrade is needed
  var plan_type_to_use =
    current_plan_type !== "free" && website_premium == "false" && websites_plan
      ? "premium_website"
      : current_plan_type == "marketer"
      ? "legacy"
      : plan_to_display;

  $(modal_div).modal("show");
  $(".modal-backdrop").css("z-index", "1");

  if (plan_type_to_use) {
    $(".feature-gate-content").hide();
    $('.feature-gate-content[data-plan-type="' + plan_type_to_use + '"]').show();
  }

  if (message) {
    $(message_div).text(message);
  }

  if (event_type) {
    userEvent(event_type);
  }
};

export default upgrade_modal;
