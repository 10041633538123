import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import _, { xor } from "lodash";
import VariantBuilderCanvasSelect from "../variants/VariantBuilderCanvasSelect";
import SectionColumn from "../sections/SectionColumn";
import arePropsEqual from "../../utils/arePropsEqual";
import SectionSpacingHandle from "../sections/SectionSpacingHandle";

const Section = (props) => {
  let cta = props.cta;
  let variant = props.variant;
  let section = props.section;
  let options = section.options["structure"];
  let important = props.builder !== true ? " !important" : "";
  let selected;
  const classes = style.classes(cta, section);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    cta,
    variant,
    section
  );

  let columns = [
    { name: "first", position: 1 },
    { name: "second", position: 2 },
    { name: "third", position: 3 },
    { name: "fourth", position: 4 },
  ];

  if (props.builder == true) {
    useEffect(() => {
      let section_tag = document.querySelector(
        "#builder .cf-section[data-section-id='" + section.id + "']"
      );
      let section_overlay = section_tag.querySelector(".cf-section-overlay");
      let selectLabel = section_tag.querySelector(
        '.select-label[data-object_type="sections"][data-object_id="' +
          section.id +
          '"]:not([data-column])'
      );
      let selectLabelPopover = section_tag.querySelector(
        '.select-label-popover[data-object_type="sections"][data-object_id="' +
          section.id +
          '"]'
      );

      section_tag.onclick = function (e) {
        if (section.id == e.target.getAttribute("data-section-id")) {
          if (section.content_toggle_item_id) {
            dispatchCustomEvent("selectObject", {
              object_type: "sections",
              object_id: section.id,
              tab: section.element_id ? "Row" : "Section",
              panel_closed: false,
            });
          } else if (e.target.classList.contains("cf-section-overlay")) {
            dispatchCustomEvent("selectObject", {
              object_type: "sections",
              object_id: section.id,
              tab: section.element_id ? "Row" : "Section",
              panel_closed: false,
            });
          }
        }
      };

      section_overlay.onmouseover = function (e) {
        if (e.target.classList.contains("cf-section-overlay")) {
          section_tag.classList.add("editor-select");
          section_overlay.classList.add("editor-select");
          selectLabel.style.display = "block";

          if (selectLabelPopover) {
            selectLabelPopover.style.display = "block";
          }
        }
      };

      section_overlay.onmouseleave = function (e) {
        if (
          !e.target.classList.contains("section-spacing-handle") &&
          !e.target.classList.contains("section-spacing-wrapper") &&
          !e.target.classList.contains("section-spacing-value") &&
          !section_tag.classList.contains("spacing") &&
          !(
            window.selected_object_type == "sections" &&
            window.selected_object_id == section.id
          )
        ) {
          section_tag.classList.remove("editor-select");
          section_overlay.classList.remove("editor-select");
          selectLabel.style.display = "none";
        }
      };

      if (selectLabelPopover) {
        section_tag.onmouseleave = function (e) {
          if (
            !(
              window.selected_object_type == "sections" &&
              window.selected_object_id == section.id
            )
          ) {
            selectLabelPopover.style.display = "none";
          }
        };

        selectLabelPopover.onmouseover = function (e) {
          section_tag.classList.add("editor-select");
          section_overlay.classList.add("editor-select");
        };
        selectLabelPopover.onmouseleave = function (e) {
          if (
            !(
              window.selected_object_type == "sections" &&
              window.selected_object_id == section.id
            )
          ) {
            section_tag.classList.remove("editor-select");
            section_overlay.classList.remove("editor-select");
          }
        };
      }
    });
  }

  const embedYoutubeLink = () => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = options["background-video"].match(regExp);
    const youtubeId = match && match[2].length === 11 ? match[2] : null;

    return `https://www.youtube.com/embed/${youtubeId}?autoplay=1&loop=1&mute=1&playlist=${youtubeId}`;
  };

  const embedVimeoLink = () => {
    const regExp = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    const match = options["background-video"].match(regExp);
    const vimeoId = match[1];

    return `https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&color=ED8374&title=0&byline=0&portrait=0&badge=0`;
  };

  const embedWistiaLink = () => {
    const regExp = /(?:wistia)\..*(?:medias\/|embed\/)([^#&? ]*)/i;
    const match = options["background-video"].match(regExp);
    const wistiaId = match[1];

    return `https://fast.wistia.net/embed/iframe/${wistiaId}`;
  };

  let backgroundVideoUrl = "";
  if (
    options["background-video"] &&
    options["background-video"].includes("youtube")
  ) {
    backgroundVideoUrl = embedYoutubeLink();
  } else if (
    options["background-video"] &&
    options["background-video"].includes("vimeo")
  ) {
    backgroundVideoUrl = embedVimeoLink();
  } else if (
    options["background-video"] &&
    options["background-video"].includes("wistia")
  ) {
    backgroundVideoUrl = embedWistiaLink();
  }

  return (
    <React.Fragment>
      <div
        id={"section" + section.id}
        className={classes.outer}
        section-id={section.id}
        data-section-id={section.id}
        data-pos={section.position}
        step-id={section.step_id}
        data-step-id={section.step_id}
        element-id={section.element_id ? section.element_id : ""}
        data-element-id={section.element_id ? section.element_id : ""}
        data-object-type="sections"
        data-object-id={section.id}
      >
        <div className="cf-section-overlay-outer" data-section-id={section.id}>
          <div
            className="cf-video-background-container"
            data-section-id={section.id}
            section-id={section.id}
          >
            {options["background-video"] && (
              <iframe
                className="cf-video-background-iframe"
                src={backgroundVideoUrl}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </div>
          <div
            className="cf-section-overlay"
            section-id={section.id}
            data-section-id={section.id}
            data-element-id={section.element_id}
          ></div>
        </div>
        <div
          className={classes.inner}
          section-id={section.id}
          data-section-id={section.id}
          data-mobile-responsive={
            options["mobile-responsive"] ? options["mobile-responsive"] : "true"
          }
        >
          {columns.map((column, i) => {
            return (
              <SectionColumn
                key={"section-" + section.id + "-column-" + column.position}
                column={column}
                {...props}
              ></SectionColumn>
            );
          })}
        </div>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        {props.builder == true && (
          <VariantBuilderCanvasSelect
            label={!section.element_id ? "Section" : "Row"}
            object_type="sections"
            object_id={section.id}
            object={section}
            tab={section.element_id ? "Row" : "Section"}
          />
        )}
        {props.builder == true && (
          <React.Fragment>
            <SectionSpacingHandle
              key={`section-${section.id}-spacer-top`}
              {...props}
              direction="top"
            ></SectionSpacingHandle>
            <SectionSpacingHandle
              key={`section-${section.id}-spacer-bottom`}
              {...props}
              direction="bottom"
            ></SectionSpacingHandle>
            <SectionSpacingHandle
              key={`section-${section.id}-spacer-left`}
              {...props}
              direction="left"
            ></SectionSpacingHandle>
            <SectionSpacingHandle
              key={`section-${section.id}-spacer-right`}
              {...props}
              direction="right"
            ></SectionSpacingHandle>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (cta, section) => {
    let options = section.options["structure"];
    let outer = "cf-row cf-section cf-outer-center";

    outer += options["hide-desktop"] == "true" ? " cf-hide-desktop" : "";

    outer += options["hide-mobile"] == "true" ? " cf-hide-mobile" : "";

    outer += options["background-position"]
      ? " cf-background-position-" + options["background-position"]
      : " cf-background-position-top-center";

    outer += options["background-size"]
      ? " cf-background-size-" + options["background-size"]
      : " cf-background-size-cover";

    outer += options["custom-class"] ? " " + options["custom-class"] : "";

    let inner = "cf-section-container cf-row cf-outer-center";

    inner +=
      options["column-container"] == "page" ||
      (!options["column-container"] && cta.cta_type == "page")
        ? " cf-section-page-width"
        : "";

    return { outer: outer, inner: inner };
  },

  stylesheet: (builder, device, cta, variant, section) => {
    let options = style.backport(section);

    let background_image;
    if (options["background-image"] && options["background-image"] !== "none") {
      background_image = options["background-image"]
        .replace("url(", "")
        .replace(")", "");
    }

    let minHeight;
    if (
      builder == true &&
      device == "desktop" &&
      section.options["structure"]["browser-height"] == "true" &&
      !section.element_id &&
      (cta.cta_type == "page" ||
        ((cta.cta_type == "inline" || cta.cta_type == "overlay") &&
          variant["options"]["container-width"] == "100%"))
    ) {
      minHeight = "700px";
    }

    let borderStyle =
      _.isNil(options[`section-border-style`]) == false
        ? options[`section-border-style`]
        : "solid";
    let borderWidth =
      _.isNil(options[`section-border-width`]) == false
        ? options[`section-border-width`]
        : 0;
    let borderWidthLeft =
      _.isNil(options[`section-border-width-left`]) == false
        ? options[`section-border-width-left`]
        : undefined;
    let borderWidthTop =
      _.isNil(options[`section-border-width-top`]) == false
        ? options[`section-border-width-top`]
        : undefined;
    let borderWidthBottom =
      _.isNil(options[`section-border-width-bottom`]) == false
        ? options[`section-border-width-bottom`]
        : undefined;
    let borderWidthRight =
      _.isNil(options[`section-border-width-right`]) == false
        ? options[`section-border-width-right`]
        : undefined;

    let borderColor = options["section-border-color"]
      ? options["section-border-color"]
      : "#CCCCCC";

    let borderRadius = "0px";
    if (options["section-border-radius"] == "0") {
      borderRadius = "0px";
    }
    if (options["section-border-radius"] == "4") {
      borderRadius = "4px";
    }
    if (options["section-border-radius"] == "20") {
      borderRadius = "20px";
    }
    if (options["section-border-radius"] == "1000") {
      borderRadius = "1000px";
    }
    if (options["section-border-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["section-border-radius-" + side]) == false
              ? options["section-border-radius-" + side]
              : "0"
          }px `;
        }
      );
    }

    let innerContainerMaxWidth;
    if (
      options["inner-container-max-width"] &&
      section.options["structure"]["column-container"] == "custom"
    ) {
      innerContainerMaxWidth = options["inner-container-max-width"];
    }

    let outerContainerWidth;
    if (
      options["outer-container-width"] &&
      section.element_id &&
      section.options["structure"]["outer-container-width-style"] == "custom"
    ) {
      outerContainerWidth = options["outer-container-width"];
    }

    let boxShadow;

    if (options["box-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["box-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["box-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (options["box-shadow-horizontal-distance"]
          ? `${options["box-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["box-shadow-vertical-distance"]
          ? `${options["box-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["box-shadow-blur-radius"]
          ? `${options["box-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["box-shadow-spread-radius"]
          ? `${options["box-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (options["box-shadow-color"]
          ? options["box-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-section[data-section-id="${section.id}"] {
  position: relative !important;
  background-image: ${background_image ? "url(" + background_image + ")" : ''} !important;
  min-height: ${minHeight} !important;
  margin-left: ${_.isNil(options['container-margin-left']) == false ? options['container-margin-left'] + 'px' : '0px'} !important;
  margin-right: ${_.isNil(options['container-margin-right']) == false ? options['container-margin-right'] + 'px' : '0px'} !important;
  margin-top: ${_.isNil(options['container-margin-top']) == false ? options['container-margin-top'] + 'px' : '0px'} !important;
  margin-bottom: ${_.isNil(options['container-margin-bottom']) == false ? options['container-margin-bottom'] + 'px' : '0px'} !important;
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
  ${outerContainerWidth ? `max-width: ${outerContainerWidth}px !important; margin-left: auto !important; margin-right: auto !important` : ''}
}

#cta_${cta.id} .cf-section-container[data-section-id="${section.id}"] {
  padding-left: ${_.isNil(options['container-padding-left']) == false ? options['container-padding-left'] + 'px' : '0px'} !important;
  padding-right: ${_.isNil(options['container-padding-right']) == false ? options['container-padding-right'] + 'px' : '0px'} !important;
  padding-top: ${_.isNil(options['container-padding-top']) == false ? options['container-padding-top'] + 'px' : '0px'} !important;
  padding-bottom: ${_.isNil(options['container-padding-bottom']) == false ? options['container-padding-bottom'] + 'px' : '0px'} !important;
  ${innerContainerMaxWidth ? `max-width: ${innerContainerMaxWidth}px !important; margin-left: auto !important; margin-right: auto !important` : ''}
}

#cta_${cta.id} .cf-section-overlay-outer[data-section-id="${section.id}"] {
  border: initial !important;
  ${_.isNil(borderStyle) == false ? 'border-style: ' + borderStyle + ' !important;' : ''}
  ${_.isNil(borderWidth) == false ? 'border-width: ' + borderWidth + 'px !important;' : ''}
  ${_.isNil(borderWidthLeft) == false ? 'border-left-width: ' + borderWidthLeft + 'px !important;' : ''}
  ${_.isNil(borderWidthRight) == false ? 'border-right-width: ' + borderWidthRight + 'px !important;' : ''}
  ${_.isNil(borderWidthTop) == false ? 'border-top-width: ' + borderWidthTop + 'px !important;' : ''}
  ${_.isNil(borderWidthBottom) == false ? 'border-bottom-width: ' + borderWidthBottom + 'px !important;' : ''}
  ${borderColor ? 'border-color: ' + borderColor + ' !important;' : ''}
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
}

#cta_${cta.id} .cf-section-overlay-outer[data-section-id="${section.id}"] .cf-section-overlay {
  background: ${options["background-color"] ? options["background-color"] : ''} !important;
  opacity: ${_.isNil(options["background-opacity"]) == false ? options["background-opacity"] : ''} !important;
}
`;

    let innerContainerMaxWidthMobile;
    if (
      options["inner-container-max-width-mobile"] &&
      section.options["structure"]["column-container"] == "custom"
    ) {
      innerContainerMaxWidthMobile =
        options["inner-container-max-width-mobile"];
    }

    let outerContainerWidthMobile;
    if (
      options["outer-container-width-mobile"] &&
      section.element_id &&
      section.options["structure"]["outer-container-width-style-mobile"] ==
        "custom"
    ) {
      outerContainerWidthMobile = options["outer-container-width-mobile"];
    }

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-section[data-section-id="${section.id}"] {
  ${_.isNil(options['container-margin-mobile-left']) == false ? 'margin-left: ' + options['container-margin-mobile-left'] + 'px !important' : ''};
  ${_.isNil(options['container-margin-mobile-right']) == false ? 'margin-right: ' + options['container-margin-mobile-right'] + 'px !important' : ''};
  ${_.isNil(options['container-margin-mobile-top']) == false ? 'margin-top: ' + options['container-margin-mobile-top'] + 'px !important' : ''};
  ${_.isNil(options['container-margin-mobile-bottom']) == false ? 'margin-bottom: ' + options['container-margin-mobile-bottom'] + 'px !important' : ''};
  ${_.isNil(options['background-position-mobile']) == false ? 'background-position: ' + options['background-position-mobile'].replace('-', ' ') + ' !important' : ''};
  ${_.isNil(options['background-size-mobile']) == false ? 'background-size: ' + (options['background-size-mobile'] !== 'expand-height' ? options['background-size-mobile'] : 'auto 100%') + ' !important' : ''};
  ${outerContainerWidthMobile ? `max-width: ${outerContainerWidthMobile}px !important; margin-left: auto !important; margin-right: auto !important` : ''}
}

#cta_${cta.id} .cf-section-container[data-section-id="${section.id}"] {
  ${_.isNil(options['container-padding-mobile-left']) == false ? 'padding-left: ' + options['container-padding-mobile-left'] + 'px !important' : ''};
  ${_.isNil(options['container-padding-mobile-right']) == false ? 'padding-right: ' + options['container-padding-mobile-right'] + 'px !important' : ''};
  ${_.isNil(options['container-padding-mobile-top']) == false ? 'padding-top: ' + options['container-padding-mobile-top'] + 'px !important' : ''};
  ${_.isNil(options['container-padding-mobile-bottom']) == false ? 'padding-bottom: ' + options['container-padding-mobile-bottom'] + 'px !important' : ''};
  ${innerContainerMaxWidthMobile ? `max-width: ${innerContainerMaxWidthMobile}px !important; margin-left: auto !important; margin-right: auto !important` : ''}
}`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },

  // converts old database values to current values
  backport: (section) => {
    let options = { ...section.options["structure"] };

    const levelsMap = {
      0: 0,
      1: 15,
      2: 25,
      3: 40,
      4: 70,
      5: 120,
    };

    ["top", "bottom", "left", "right"].forEach((side) => {
      // desktop
      const desktopKey = "container-padding-" + side;
      const desktopValue = options[desktopKey];

      if (
        desktopValue &&
        typeof desktopValue == "string" &&
        desktopValue.indexOf("_") > -1
      ) {
        let level = desktopValue.split("_").pop();
        options[desktopKey] = levelsMap[level];
      }

      // mobile
      const mobileKey = "container-padding-mobile-" + side;
      const mobileValue = options[mobileKey];

      if (
        mobileValue &&
        typeof mobileValue == "string" &&
        mobileValue.indexOf("_") > -1
      ) {
        let level = mobileValue.split("_").pop();
        options[mobileKey] = levelsMap[level];
      }
    });

    return options;
  },
};

export default React.memo(Section, arePropsEqual);
