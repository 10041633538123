const ConditionsMap = (
  variant,
  cta,
  website,
  contactable_id,
  contactable_type
) => {
  let context = variant;
  let countryMap = [];
  if (typeof window !== "undefined") {
    context = window.cta || variant;
    countryMap = window.countryMap;
  }

  let agroup;
  let conditionsMap = {};

  if (
    context &&
    ["ActionGroup", "ProductRecommendation"].includes(contactable_type)
  ) {
    let product_recommendation;

    if (contactable_type == "ActionGroup") {
      agroup = context.action_groups[contactable_id];
    }

    if (contactable_type == "ProductRecommendation") {
      product_recommendation = context.product_recommendations[contactable_id];
    }

    var cta_fields = [];
    Object.values(context.fields).forEach((field) => {
      if (
        field.slug &&
        cta_fields.filter((f) => f.value == field.slug).length == 0
      ) {
        cta_fields.push({
          value: field.slug,
          text: field.slug,
          custom_attributes: [{ name: "field_id", value: field.id }],
        });
      }
    });

    var cta_surveys = [];
    Object.values(context.fields).forEach((field) => {
      if (
        field.slug &&
        [
          null,
          "",
          "survey",
          "image",
          "color",
          "emoji",
          "icon",
          "letter",
          "dropdown",
          "range",
          "select",
          "checkbox",
          "radio"
        ].includes(field.field_type) &&
        cta_surveys.filter((s) => s.value == field.slug).length == 0
      ) {
        cta_surveys.push({
          value: field.slug,
          text: field.slug,
          custom_attributes: [{ name: "field_id", value: field.id }],
        });
      }
    });

    var field_options = [];

    Object.values(context.field_options).forEach((field_option) => {
      if (
        field_options.filter(
          (fo) =>
            context.fields[fo.field_id].slug ==
              context.fields[field_option.field_id].slug &&
            fo.value == field_option.value
        ).length == 0
      ) {
        field_options.push(field_option);
      }
    });

    if (agroup && agroup.step_id !== undefined && agroup.step_id !== null) {
      conditionsMap["If current step has been completed"] = {
        group: "This campaign",
        settings: [
          {
            input_type: "hidden",
            attribute: "[data][step_id]",
            value: agroup.step_id,
          },
          {
            input_type: "hidden",
            attribute: "[data][cta_id]",
            value: cta.id,
          },
          {
            input_type: "hidden",
            attribute: "[data][variant_id]",
            value: variant.id,
          },
        ],
        operator: "and",
      };
    }

    conditionsMap["If answer to question is"] = {
      group: "This campaign",
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Select a question",
          placeholder: "Select a question",
          options: cta_surveys,
          child_setting: {
            input_type: "select",
            attribute: "[keyword]",
            label: "Answer",
            placeholder: "Select an answer",
            options: $.map(field_options, function (option) {
              if (
                context.fields[option.field_id] &&
                context.fields[option.field_id].slug
              ) {
                const value = option.value || option.label;
                return {
                  value: value,
                  text: value,
                  parent: context.fields[option.field_id].slug,
                  custom_attributes: [
                    { name: "field_option_id", value: option.id },
                  ],
                };
              }
            }),
            callback: function (input) {
              $('.new-condition input[name="condition[data][field_option_id]"]').val(
                input.field_option_id
              );
            },
          },
          callback: function (input) {
            $('.new-condition input[name="condition[data][field_id]"]').val(input.field_id);
          },
        },
        {
          input_type: "hidden",
          attribute: "[data][field_id]",
          value: "",
        },
        {
          input_type: "hidden",
          attribute: "[data][field_option_id]",
          value: "",
        },
      ],
      operator: "or",
    };

    conditionsMap["If answer to question is NOT"] = {
      group: "This campaign",
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Select a question",
          placeholder: "Select a question",
          options: cta_surveys,
          child_setting: {
            input_type: "select",
            attribute: "[keyword]",
            label: "Answer",
            placeholder: "Select an answer",
            options: $.map(field_options, function (option) {
              if (
                context.fields[option.field_id] &&
                context.fields[option.field_id].slug
              ) {
                return {
                  value: option.value,
                  text: option.value,
                  parent: context.fields[option.field_id].slug,
                  custom_attributes: [
                    { name: "field_option_id", value: option.id },
                  ],
                };
              }
            }),
            callback: function (input) {
              $('.new-condition input[name="condition[data][field_option_id]"]').val(
                input.field_option_id
              );
            },
          },
          callback: function (input) {
            $('.new-condition input[name="condition[data][field_id]"]').val(input.field_id);
          },
        },
        {
          input_type: "hidden",
          attribute: "[data][field_id]",
          value: "",
        },
        {
          input_type: "hidden",
          attribute: "[data][field_option_id]",
          value: "",
        },
      ],
      operator: "or",
    };

    conditionsMap["If survey selection matches"] = {
      group: "This campaign",
      hidden: true,
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Select a question",
          placeholder: "Select a question",
          options: cta_surveys,
          child_setting: {
            input_type: "select",
            attribute: "[keyword]",
            label: "Answer",
            placeholder: "Select an answer",
            options: $.map(field_options, function (option) {
              if (
                context.fields[option.field_id] &&
                context.fields[option.field_id].slug
              ) {
                const value = option.value || option.label;
                return {
                  value: value,
                  text: value,
                  parent: context.fields[option.field_id].slug,
                  custom_attributes: [
                    { name: "field_option_id", value: option.id },
                  ],
                };
              }
            }),
            callback: function (input) {
              $('.new-condition input[name="condition[data][field_option_id]"]').val(
                input.field_option_id
              );
            },
          },
          callback: function (input) {
            $('.new-condition input[name="condition[data][field_id]"]').val(input.field_id);
          },
        },
        {
          input_type: "hidden",
          attribute: "[data][field_id]",
          value: "",
        },
        {
          input_type: "hidden",
          attribute: "[data][field_option_id]",
          value: "",
        },
      ],
      operator: "or",
    };

    conditionsMap["If survey selection does NOT match"] = {
      group: "This campaign",
      hidden: true,
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Select a question",
          placeholder: "Select a question",
          options: cta_surveys,
          child_setting: {
            input_type: "select",
            attribute: "[keyword]",
            label: "Answer",
            placeholder: "Select an answer",
            options: $.map(field_options, function (option) {
              if (
                context.fields[option.field_id] &&
                context.fields[option.field_id].slug
              ) {
                return {
                  value: option.value,
                  text: option.value,
                  parent: context.fields[option.field_id].slug,
                  custom_attributes: [
                    { name: "field_option_id", value: option.id },
                  ],
                };
              }
            }),
            callback: function (input) {
              $('.new-condition input[name="condition[data][field_option_id]"]').val(
                input.field_option_id
              );
            },
          },
          callback: function (input) {
            $('.new-condition input[name="condition[data][field_id]"]').val(input.field_id);
          },
        },
        {
          input_type: "hidden",
          attribute: "[data][field_id]",
          value: "",
        },
        {
          input_type: "hidden",
          attribute: "[data][field_option_id]",
          value: "",
        },
      ],
      operator: "or",
    };

    conditionsMap["If custom field contains"] = {
      group: "This campaign",
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "custom field",
          placeholder: "Select a custom field",
          options: cta_fields,
          callback: function (input) {
            $('.new-condition input[name="condition[data][field_id]"]').val(input.field_id);
          },
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Value",
          placeholder: "Value",
        },
        {
          input_type: "hidden",
          attribute: "[data][field_id]",
          value: "",
        },
      ],
      operator: "or",
    };

    conditionsMap["If custom field does NOT contain"] = {
      group: "This campaign",
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "custom field",
          placeholder: "Select a custom field",
          options: cta_fields,
          callback: function (input) {
            $('.new-condition input[name="condition[data][field_id]"]').val(input.field_id);
          },
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Value",
          placeholder: "Value",
        },
        {
          input_type: "hidden",
          attribute: "[data][field_id]",
          value: "",
        },
      ],
      operator: "and",
    };

    conditionsMap["If current score is greater than"] = {
      group: "This campaign",
      description:
        '"If current score is greater than" conditions are true if the current calculated score exceeds the given value',
      settings: [
        {
          input_type: "number",
          attribute: "[keyword]",
          label: "Number",
          placeholder: "0",
        },
      ],
      operator: "and",
    };

    conditionsMap["If current score is less than"] = {
      group: "This campaign",
      description:
        '"If current score is less than" conditions are true if the current calculated score is less than the given value',
      settings: [
        {
          input_type: "number",
          attribute: "[keyword]",
          label: "Number",
          placeholder: "0",
        },
      ],
      operator: "and",
    };

    conditionsMap["If quiz answers are mostly"] = {
      group: "This campaign",
      settings: [
        {
          input_type: "select",
          attribute: "[keyword]",
          label: "Answer Letter",
          placeholder: "Select a letter",
          options: Array.from({ length: 26 }, (_, i) =>
            String.fromCharCode(65 + i)
          ).map((letter) => {
            return {
              value: letter,
              text: letter,
            };
          }),
        },
      ],
      operator: "or",
    };

    conditionsMap["If quiz answers are NOT mostly"] = {
      group: "This campaign",
      settings: [
        {
          input_type: "select",
          attribute: "[keyword]",
          label: "Answer Letter",
          placeholder: "Select a letter",
          options: Array.from({ length: 26 }, (_, i) =>
            String.fromCharCode(65 + i)
          ).map((letter) => {
            return {
              value: letter,
              text: letter,
            };
          }),
        },
      ],
      operator: "and",
    };
  }

  let contact_fields = [];

  [
    "email",
    "name",
    "last_name",
    "phone",
    "city",
    "company_name",
    "company_title",
    "url",
    "state",
    "address",
    "zip_code",
    "country",
    "source_url",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "referral_source",
    "first_visit",
    "privacy_consent",
    "marketing_consent",
    "ip_address",
  ].forEach((contact_field) => {
    var label = contact_field.replace("_", " ");
    var label = label.charAt(0).toUpperCase() + label.slice(1);
    if (contact_field == "name") var label = "First name";
    if (contact_field == "url") var label = "Website URL";
    if (contact_field == "source_url") var label = "First submission URL";
    contact_fields.push({ value: contact_field, text: label });
  });

  if (website.conditions_groups !== undefined)
    if (website.ctas !== undefined) {
      var conditions_groups = Object.values(website.conditions_groups).map(
        (conditions_group) => {
          return { value: conditions_group.id, text: conditions_group.name };
        }
      );

      var ctas = Object.values(website.ctas).map((cta) => {
        return {
          value: cta.id,
          text:
            cta.cta_type.substr(0, 1).toUpperCase() +
            cta.cta_type.substr(1) +
            " – " +
            (cta.name || "Campaign " + cta.id),
        };
      });

      var pages = Object.values(website.ctas).map((cta) => {
        if (cta.cta_type == "page")
          return {
            value: cta.id,
            text:
              cta.cta_type.substr(0, 1).toUpperCase() +
              cta.cta_type.substr(1) +
              " – " +
              (cta.name || "Campaign " + cta.id),
          };
      });
    }

  if (website.fields !== undefined) {
    var fields = Object.values(website.fields).map((field) => {
      return { value: field.slug, text: field.slug };
    });
  }

  if (website.tags !== undefined) {
    var tags = Object.values(website.tags).map((tag) => {
      return { value: tag.id, text: tag.name };
    });
  }

  if (contactable_type !== "Website" && contactable_type !== "ActionGroup") {
    conditionsMap["Everywhere"] = {
      group: "Current page",
      description: "This type of condition is always true",
      operator: "and",
    };
  }

  Object.assign(conditionsMap, {
    "If page is": {
      group: "Current page",
      description:
        '"If page is" conditions are true if the page URL matches the given URL',
      settings: [
        {
          input_type: "text",
          attribute: "[url]",
          label: "URL",
          placeholder: "https://",
        },
      ],
      operator: "or",
    },
    "If page is NOT": {
      group: "Current page",
      description:
        '"If page is NOT" conditions are true if the page URL does NOT match the given URL',
      settings: [
        {
          input_type: "text",
          attribute: "[url]",
          label: "URL",
          placeholder: "https://",
        },
      ],
      operator: "and",
    },
    "If URL contains": {
      group: "Current page",
      description:
        '"If URL contains" conditions are true when the page URL contains the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Keyword",
        },
      ],
      operator: "or",
    },
    "If URL does NOT contain": {
      group: "Current page",
      description:
        '"If URL contains" conditions are true when the page URL does NOT contain the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Keyword",
        },
      ],
      operator: "and",
    },
    "If device type is": {
      group: "Current page",
      description:
        '"If device type" conditions are true when the width of the browser is for the given device type',
      settings: [
        {
          input_type: "select",
          attribute: "[data][device]",
          placeholder: "Select a device type",
          label: "Device type",
          options: [
            { value: "desktop", text: "Desktop" },
            { value: "mobile", text: "Mobile" },
          ],
        },
      ],
      operator: "and",
    },
    "If visiting ConvertFlow landing page": {
      group: "Current page",
      description:
        '"If visiting ConvertFlow landing page" conditions are true if the person is visiting a landing page built in ConvertFlow',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Landing Page",
          placeholder: "Select a landing page",
          options: pages,
        },
      ],
      operator: "or",
    },
    "If visiting from city": {
      group: "This session",
      description:
        '"If visiting from city" conditions are true when the visitor IP city location contains the keyword you specify',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "City",
          placeholder: "City",
        },
      ],
      operator: "or",
    },
    "If NOT visiting from city": {
      group: "This session",
      description:
        '"If NOT visiting from city" conditions are true when the visitor IP city location does NOT contain the keyword you specify',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "City",
          placeholder: "City",
        },
      ],
      operator: "and",
    },
    "If visiting from region": {
      group: "This session",
      description:
        '"If visiting from region" conditions are true when the visitor IP region/state name contains the keyword you specify',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Region",
          placeholder: "Region",
        },
      ],
      operator: "or",
    },
    "If NOT visiting from region": {
      group: "This session",
      description:
        '"If NOT visiting from region" conditions are true when the visitor IP region/state name does NOT contain the keyword you specify',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Region",
          placeholder: "Region",
        },
      ],
      operator: "or",
    },
    "If NOT visiting from city": {
      group: "This session",
      description:
        '"If NOT visiting from city" conditions are true when the visitor IP city location does NOT contain the keyword you specify',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "City",
          placeholder: "City",
        },
      ],
      operator: "and",
    },
    "If visiting from country": {
      group: "This session",
      description:
        '"If visiting from country" conditions are true if the visitor IP country code matches the selected country',
      settings: [
        {
          input_type: "select",
          attribute: "[country]",
          label: "Country",
          placeholder: "Select a country",
          options: countryMap,
        },
      ],
      operator: "or",
    },
    "If NOT visiting from country": {
      group: "This session",
      description:
        '"If NOT visiting from country" conditions are true if the visitor IP country code does NOT match the selected country',
      settings: [
        {
          input_type: "select",
          attribute: "[country]",
          label: "Country",
          placeholder: "Select a country",
          options: countryMap,
        },
      ],
      operator: "and",
    },
    "If person has viewed campaign": {
      group: "This session",
      description:
        '"If person has viewed campaign" conditions are true if the person has already been displayed the selected campaign',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Campaign",
          placeholder: "Select a campaign",
          options: ctas,
        },
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of views",
          placeholder: "2 times",
          min: "1",
        },
      ],
      operator: "and",
    },
    "If person has NOT viewed campaign": {
      group: "This session",
      description:
        '"If person has NOT viewed campaign" conditions are true if the person has NOT already been displayed the selected campaign',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Campaign",
          placeholder: "Select a campaign",
          options: ctas,
        },
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of views",
          placeholder: "2 times",
          min: "1",
        },
      ],
      operator: "and",
    },
    "If person has converted with campaign": {
      group: "This session",
      description:
        '"If person has converted with campaign" conditions are true if the person converted on the first step of the selected campaign',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Campaign",
          placeholder: "Select a campaign",
          options: ctas,
        },
      ],
      operator: "and",
    },
    "If person has NOT converted with campaign": {
      group: "This session",
      description:
        '"If person has NOT converted with campaign" conditions are true if the person has NOT converted on the first step of the selected campaign',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Campaign",
          placeholder: "Select a campaign",
          options: ctas,
        },
      ],
      operator: "and",
    },
    "If person has completed campaign": {
      group: "This session",
      description:
        '"If person has completed campaign" conditions are true if the person was tracked as having completed the selected campaign',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Campaign",
          placeholder: "Select a campaign",
          options: ctas,
        },
      ],
      operator: "and",
    },
    "If person has NOT completed campaign": {
      group: "This session",
      description:
        '"If person has NOT completed campaign" conditions are true if the person was NOT tracked as having completed the selected campaign',
      settings: [
        {
          input_type: "select",
          attribute: "[condition_cta]",
          label: "Campaign",
          placeholder: "Select a campaign",
          options: ctas,
        },
      ],
      operator: "and",
    },
    "If referral source contains": {
      group: "This session",
      description:
        '"If referral source contains" conditions are true if the person originally clicked from a site contain the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "URL Keyword",
          placeholder: "URL keyword",
        },
      ],
      operator: "and",
    },
    "If referral source does NOT contain": {
      group: "This session",
      description:
        '"If referral source does NOT contain" conditions are true if the person did NOT originally click from a site containing the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "URL Keyword",
          placeholder: "URL keyword",
        },
      ],
      operator: "and",
    },

    "If person has more than X sessions": {
      group: "This session",
      description:
        '"If person has more than X sessions" conditions are true if their total number of 30 minute sessions is greater than the given number',
      settings: [
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of sessions",
          placeholder: "2 sessions",
        },
      ],
      operator: "and",
    },
    "If person has fewer than X sessions": {
      group: "This session",
      description:
        '"If person has fewer than X sessions" conditions are true if their total number of 30 minute sessions is less than the given number',
      settings: [
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of sessions",
          placeholder: "2 sessions",
        },
      ],
      operator: "and",
    },
    "If current session is more than X minutes": {
      group: "This session",
      description:
        '"If current session is more than X minutes" conditions are true if their current session is longer than the given number of minutes',
      settings: [
        {
          input_type: "number",
          attribute: "[count]",
          label: "Session length in minutes",
          placeholder: "3 minutes",
        },
      ],
      operator: "and",
    },
    "If current session is less than X minutes": {
      group: "This session",
      description:
        '"If current session is less than X minutes" conditions are true if their current session is shorter than the given number of minutes',
      settings: [
        {
          input_type: "number",
          attribute: "[count]",
          label: "Session length in minutes",
          placeholder: "3 minutes",
        },
      ],
      operator: "and",
    },
    "If person has more than X page views in current session": {
      group: "This session",
      description:
        '"If person has more than X page views in current session" conditions are true if their total number of page hits in their current 30 minute session is greater than the given number',
      settings: [
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of page views in current session",
          placeholder: "2 views",
        },
      ],
      operator: "and",
    },
    "If person has fewer than X page views in current session": {
      group: "This session",
      description:
        '"If person has fewer than X page views in current session" conditions are true if their total number of page hits in their current 30 minute session is less than the given number',
      settings: [
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of page views in current session",
          placeholder: "2 views",
        },
      ],
      operator: "and",
    },
    "If person has visited URL in session with keyword": {
      group: "This session",
      description:
        '"If person has visited URL in session with keyword" conditions are true if their session URL history has a URL containing the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "URL Keyword",
          placeholder: "page-path",
        },
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of times",
          placeholder: "2 times",
          min: "1",
        },
      ],
      operator: "and",
    },
    "If person has NOT visited URL in session with keyword": {
      group: "This session",
      description:
        '"If person has NOT visited URL in session with keyword" conditions are true if their session page history does NOT have a URL containing the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "URL Keyword",
          placeholder: "page-path",
        },
        {
          input_type: "number",
          attribute: "[count]",
          label: "Number of times",
          placeholder: "2 times",
          min: "1",
        },
      ],
      operator: "and",
    },
    "If person is an identified contact": {
      group: "This session",
      description:
        '"If person is an identified contact" conditions are true if ConvertFlow knows the email address of the visitor',
      operator: "and",
    },
    "If person is NOT an identified contact": {
      group: "This session",
      description:
        '"If person is NOT an identified contact" conditions are true if ConvertFlow does NOT know the email address of the visitor',
      operator: "and",
    },
    "If person is in visitor segment": {
      group: "Contact profile",
      description:
        '"If person is in visitor segment" conditions are true if the person matches the conditions of the selected visitor segment',
      settings: [
        {
          input_type: "select",
          attribute: "[data][conditions_group_id]",
          label: "Segment",
          placeholder: "Select a segment",
          options: conditions_groups,
        },
      ],
      operator: "and",
    },
    "If person is NOT in visitor segment": {
      group: "Contact profile",
      description:
        '"If person is NOT in visitor segment" conditions are true if the person does NOT match the conditions of the selected visitor segment',
      settings: [
        {
          input_type: "select",
          attribute: "[data][conditions_group_id]",
          label: "Segment",
          placeholder: "Select a segment",
          options: conditions_groups,
        },
      ],
      operator: "and",
    },
    "If contact field value contains": {
      group: "Contact profile",
      description:
        '"If contact field value contains" conditions are true if their contact field value contains the given keyword',
      settings: [
        {
          input_type: "select",
          attribute: "[data][attribute]",
          label: "Contact field",
          placeholder: "Select a contact field",
          options: contact_fields,
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If contact field value does NOT contain": {
      group: "Contact profile",
      description:
        '"If contact field value does NOT contain" conditions are true if their contact field value does NOT contain the given keyword',
      settings: [
        {
          input_type: "select",
          attribute: "[data][attribute]",
          label: "Contact field",
          placeholder: "Select a contact field",
          options: contact_fields,
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If custom field value contains": {
      group: "Contact profile",
      description:
        '"If custom field value contains" conditions are true if their custom field value contains the given keyword',
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Custom field",
          placeholder: "Select a custom field",
          options: fields,
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If custom field value does NOT contain": {
      group: "Contact profile",
      description:
        '"If custom field value does NOT contain" conditions are true if their custom field value does NOT contain the given keyword',
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Custom field",
          placeholder: "Select a custom field",
          options: fields,
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If custom field is greater than": {
      group: "Contact profile",
      description:
        '"If custom field is greater than" conditions are true if the custom field value is a number that exceeds the given value',
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Custom field",
          placeholder: "Select a custom field",
          options: fields,
        },
        {
          input_type: "number",
          attribute: "[keyword]",
          label: "Number",
          placeholder: "0",
        },
      ],
      operator: "and",
    },
    "If custom field is less than": {
      group: "Contact profile",
      description:
        '"If custom field is less than" conditions are true if the custom field value is a number that is less than the given value',
      settings: [
        {
          input_type: "select",
          attribute: "[data][slug]",
          label: "Custom field",
          placeholder: "Select a custom field",
          options: fields,
        },
        {
          input_type: "number",
          attribute: "[keyword]",
          label: "Number",
          placeholder: "0",
        },
      ],
      operator: "and",
    },
    "If person has tag": {
      group: "Contact profile",
      description:
        '"If person has tag" conditions are true if the person visiting has the selected ConvertFlow tag',
      settings: [
        {
          input_type: "select",
          attribute: "[tag_id]",
          label: "Tag",
          placeholder: "Select a ConvertFlow tag",
          options: tags,
        },
      ],
      operator: "and",
    },
    "If person does NOT have tag": {
      group: "Contact profile",
      description:
        '"If person does NOT have tag" conditions are true if the person visiting does NOT have the selected ConvertFlow tag',
      settings: [
        {
          input_type: "select",
          attribute: "[tag_id]",
          label: "Tag",
          placeholder: "Select a ConvertFlow tag",
          options: tags,
        },
      ],
      operator: "and",
    },
  });

  if (website.integrations) {
    Object.values(website.integrations).forEach((integration) => {
      integration.condition_types.forEach((integration_condition_type) => {
        let condition_type = {
          group: integration.integration_type,
          icon: integration.icon,
          settings: [],
          operator: "and",
        };

        if (
          integration_condition_type.settings &&
          integration_condition_type.settings.length > 0
        ) {
          integration_condition_type.settings.forEach((setting) => {
            setting.name = `[data][${setting.attribute}]`;

            if (setting.dropdown && integration.api_data[setting.dropdown]) {
              setting.options = integration.api_data[setting.dropdown].map(
                (item) => {
                  return {
                    value: item[setting.attribute_key || "id"],
                    text: item["name"],
                  };
                }
              );
            }
          });
        }

        condition_type.settings = integration_condition_type.settings;
        conditionsMap[integration_condition_type.condition_type] =
          condition_type;
      });
    });
  }

  if (typeof integrationConditions !== "undefined") {
    Object.values(integrationConditions).forEach(
      (integration_condition_type, condition_map) => {
        Object.values(website.integrations).forEach((integration) => {
          if (integration.api_data["condition_types"]) {
            Object.values(integration.api_data["condition_types"]).forEach(
              (condition_type) => {
                if (
                  condition_type == integration_condition_type &&
                  integration_condition_type &&
                  integration_condition_type.replace(
                    "INTEGRATION_TYPE",
                    integration.integration_type
                  )
                ) {
                  var condition = {
                    group: integration.integration_type,
                    settings: [],
                    operator: "and",
                  };

                  if (
                    condition_map["list"] !== undefined &&
                    integration.api_data[condition_map["list"]] !== undefined
                  ) {
                    condition.settings.push({
                      input_type: "select",
                      attribute:
                        "[data][" + condition_map["condition_id"] + "]",
                      label: condition_map["label"],
                      placeholder: condition_map["placeholder"],
                      options: Object.values(
                        integration.api_data[condition_map["list"]]
                      ).map((item) => {
                        return { value: item["id"], text: item["name"] };
                      }),
                    });
                  }

                  if (
                    (condition_map["list"] == undefined ||
                      integration.api_data[condition_map["list"]] ==
                        undefined) &&
                    condition_map["condition_id"]
                  ) {
                    condition.settings.push({
                      input_type: "text",
                      attribute:
                        "[data][" + condition_map["condition_id"] + "]",
                      label: condition_map["label"],
                      placeholder: condition_map["placeholder"],
                    });
                  }

                  if (condition_map["value"] !== undefined) {
                    condition.settings.push({
                      input_type: "text",
                      attribute: "[data][" + condition_map["value"] + "]",
                      label: "Value",
                      placeholder: "Value",
                    });
                  }

                  conditionsMap[condition_type] = condition;
                }
              }
            );
          }
        });
      }
    );
  }

  // JAVASCRIPT CONDITIONS

  Object.assign(conditionsMap, {
    "If cookie value contains keyword": {
      group: "Javascript",
      description:
        '"If cookie value contains keyword" conditions are true if the specified cookie value contains the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[url]",
          label: "Cookie Name",
          placeholder: "Cookie name",
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Value",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If cookie value does NOT contain keyword": {
      group: "Javascript",
      description:
        '"If cookie value does NOT contain keyword" conditions are true if the specified cookie value does NOT contain the given keyword',
      settings: [
        {
          input_type: "text",
          attribute: "[url]",
          label: "Cookie Name",
          placeholder: "Cookie name",
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Value",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If global variable value contains keyword": {
      group: "Javascript",
      description:
        '"If global variable value contains keyword" conditions are true if the specified global variable is a string that contains the given keyword. Supports checking global object keys using "." characters (example: user.email)',
      settings: [
        {
          input_type: "text",
          attribute: "[url]",
          label: "Global Variable",
          placeholder: "Global variable",
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Value",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
    "If global variable value does NOT contain keyword": {
      group: "Javascript",
      description:
        '"If global variable value does NOT contain keyword" conditions are true if the specified global variable does NOT return a string containing the given keyword. Supports checking global object keys using "." characters (example: user.email)',
      settings: [
        {
          input_type: "text",
          attribute: "[url]",
          label: "Global Variable",
          placeholder: "Global variable",
        },
        {
          input_type: "text",
          attribute: "[keyword]",
          label: "Keyword",
          placeholder: "Value",
        },
      ],
      operator: "and",
    },
  });

  if (contactable_type == "ConditionsGroup") {
    delete conditionsMap["If person is in visitor segment"];
    delete conditionsMap["If person is NOT in visitor segment"];
  }

  return conditionsMap;
};

export default ConditionsMap;
