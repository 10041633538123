import React, { useContext, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import QuizSettings from "../fields/QuizSettings";
import NewQuizField from "../fields/NewQuizField";
import useFields from "../fields/useFields";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import ElementSettingsConfirmation from "./ElementSettingsConfirmation";

const ElementSettingsQuiz = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  const { Fields } = useFields(false);

  let sorted_fields = Object.values(variant.fields)
    .filter(
      (field) =>
        field?.element_id == element.id &&
        field?.toBeDeleted !== true &&
        !field?.field_id
    )
    .sort(function (a, b) {
      return a.position - b.position;
    });

  useEffect(() => {
    Fields.sortableStart(element);
    
    if (sorted_fields[0]) {
      dispatchCustomEvent("focusItem", {
        parent_id: element.id,
        item_type: "fields",
        item_id: sorted_fields[0].id
      });
    }
  }, [props.object_id]);

  return (
    <React.Fragment>
      <VariantBuilderSetting
        object={element}
        setting_name="[options][show-progress-bar]"
        setting_type="checkbox"
        label="Progress Bar"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["show-progress-bar"]
            ? element.options["show-progress-bar"]
            : "true"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Progress Label"
        setting_name="[options][quiz-progress-label]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["quiz-progress-label"]
            ? element.options["quiz-progress-label"]
            : undefined
        }
        condition={element.options["show-progress-bar"] !== "false"}
        placeholder={"Question"}
      />

      <div
        className="col-xs-12 text-xl text-black dark:text-white"
        style={{
          margin: "15px 0px",
          marginBottom: sorted_fields.length == 0 ? "15px" : "0px",
        }}
      >
        Questions
      </div>

      <div className="quiz-fields-container col-xs-12 w-full">
        <div
          className="col-xs-12 setting disabled-field"
          data-setting_type="item_v2"
          data-object_type="fields"
        ></div>

        {sorted_fields.map((field_item, index) => {
          return (
            <React.Fragment key={"quiz-field-item-" + field_item.id}>
              <QuizSettings
                field_item={field_item}
                field_item_position={index + 1}
                {...props}
              />
            </React.Fragment>
          );
        })}

        {element.element_type == "quiz" && (
          <NewQuizField
            element={element}
            payload={{
              position: sorted_fields.length + 1,
            }}
            {...props}
          />
        )}
      </div>

      <VariantBuilderSetting
        object={element}
        label="Next Button"
        setting_name="[options][next-button-label]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["next-button-label"]
            ? element.options["next-button-label"]
            : undefined
        }
        col={8}
        placeholder={"Next →"}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][hide-next-button]"
        setting_type="checkbox"
        label="Hide"
        object_type="elements"
        object_id={element.id}
        col={4}
        value={
          element.options["hide-next-button"]
            ? element.options["hide-next-button"]
            : "false"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Back Button"
        setting_name="[options][back-button-label]"
        setting_type="text"
        object_type="elements"
        object_id={element.id}
        col={8}
        value={
          element.options["back-button-label"]
            ? element.options["back-button-label"]
            : undefined
        }
        placeholder={"← Back"}
      />

      <VariantBuilderSetting
        object={element}
        setting_name="[options][hide-back-button]"
        setting_type="checkbox"
        label="Hide"
        object_type="elements"
        col={4}
        object_id={element.id}
        value={
          element.options["hide-back-button"]
            ? element.options["hide-back-button"]
            : "false"
        }
      />

      <VariantBuilderSetting
        object={element}
        label="Prefill fields"
        setting_name={"[options][prefill-fields]"}
        setting_type="checkbox"
        object_type="elements"
        object_id={element.id}
        value={
          element.options["prefill-fields"]
            ? element.options["prefill-fields"]
            : "true"
        }
        tooltip="Prefill fields with data already collected from the visitor and from URL parameters"
        col="6"
        classNames="some-padding-right"
      />
    </React.Fragment>
  );
};

export default ElementSettingsQuiz;
