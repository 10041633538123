import React, { useEffect } from "react";
import Section from "../sections/Section";
import arePropsEqual from "../../utils/arePropsEqual";

const ElementCollapse = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  let sorted_list_items = Object.values(element.content_toggle_items)
    .filter(
      (list_item) =>
        list_item.element_id == element.id && list_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  useEffect(() => {
    if (props.builder == true) {

      // Toggle Builder Implementation
      $(
        `#builder #cf-collapse${element.id} .cf-collapse-toggle[data-list-item-element-id="${element.id}"]`
      )
        .off("click")
        .on("click", function (e) {
          let target = $(e.target).attr("data-target");

          if (target == undefined) {
            let toggle = $(e.target).closest(".cf-collapse-toggle")[0];
            target = $(toggle).attr("data-target");
          }

          let targetParent = e.target.dataset["parent"];
          let targetCollapsed = e.target.classList.contains("cf-collapsed");

          // to change the toggle chevron correctly
          $(
            `${targetParent} .cf-collapse-toggle[data-list-item-element-id="${element.id}"]`
          ).removeClass("cf-collapsed");

          if (!targetCollapsed) {
            $(e.target).addClass("cf-collapsed");
          }

          let targetBox = $(`#builder ${target}`);
          let targetHeading = targetBox.parent().find(".cf-collapse-heading");

          if (targetBox.hasClass("cf-collapse-box-in")) {
            targetBox.removeClass("cf-collapse-box-in");
            targetHeading.removeClass("cf-collapse-box-in");
          } else {
            targetBox.addClass("cf-collapse-box-in");
            targetHeading.addClass("cf-collapse-box-in");
          }

          // save open collapse position
          let collapsed_position = e.target.dataset["listItemPosition"];
          if (element.options["collapsed-position"] == collapsed_position) {
            collapsed_position = null;
          }

          let updates = [
            {
              object_type: "elements",
              object_id: element.id,
              setting_name: "[options][collapsed-position]",
              value: collapsed_position,
              skip_history: false,
            },
          ];

          dispatchCustomEvent("updateBuilder", { updates: updates });

          dispatchCustomEvent("selectObject", {
            object_type: "elements",
            object_id: element.id
          });
        });

      $(`#builder #cf-collapse${element.id} .cf-collapse-heading`)
        .off("click")
        .on("click", function (e) {
          if (e.target.classList.contains("cf-collapse-heading")) {
            $(e.target).find(".cf-collapse-toggle").click();
          }
        });
    }
  }, [sorted_list_items]);

  return (
    <React.Fragment>
      <div className="element-content">
        <div
          className={classes}
          id={`cf-collapse${element.id}`}
          data-font={
            element.options["collapse-heading-font-family"] ||
            props.variant.options["collapse-heading-font-family"]
          }
        >
          {sorted_list_items.map((list_item) => {
            let collapse_item_section = Object.values(element.sections).filter(
              (section) =>
                section.content_toggle_item_id == list_item.id &&
                section.step_id == props.section.step_id
            )[0];

            let list_item_collapsed = element.options["collapsed-position"]
              ? element.options["collapsed-position"] == list_item.position
              : null;

            return (
              <React.Fragment key={list_item.id}>
                <div className="cf-collapse">
                  <div
                    id={`cf-collapse-item-heading-${list_item.id}`}
                    className={`cf-collapse-heading ${
                      list_item_collapsed ? "cf-collapse-box-in" : ""
                    }`}
                  >
                    <div className="cf-collapse-title">
                      <div
                        className={`cf-collapse-toggle ${
                          list_item_collapsed ? "cf-collapsed" : ""
                        }`}
                        data-parent={`#cf-collapse${element.id}`}
                        data-target={`#cf-collapse-item-${list_item.id}`}
                        data-list-item-position={list_item.position}
                        data-list-item-element-id={list_item.element_id}
                        dangerouslySetInnerHTML={{
                          __html: list_item.content,
                        }}
                      />
                    </div>
                  </div>

                  <div
                    id={`cf-collapse-item-${list_item.id}`}
                    className={`cf-collapse-box ${
                      list_item_collapsed ? "cf-collapse-box-in" : ""
                    }`}
                    data-collapse-item-id={list_item.id}
                    data-list-item-element-id={list_item.element_id}
                  >
                    <div className="cf-collapse-body">
                      {collapse_item_section ? (
                        <Section
                          key={`collapse-list-item-${list_item.id}`}
                          website={props.website}
                          cta={props.cta}
                          variant={props.variant}
                          builder={props.builder}
                          preview={props.preview}
                          step={props.step}
                          section={collapse_item_section}
                          device={props.device}
                        ></Section>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}

          <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        </div>
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "cf-collapse-element ";
    let alignmentClass = "cf-outer-center";

    if (device == "mobile") {
      if (options["cf-alignment-mobile"]) {
        if (options["cf-alignment-mobile"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment-mobile"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment-mobile"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    } else {
      if (options["cf-alignment"]) {
        if (options["cf-alignment"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    let maxWidth;
    if (
      options["collapse-max-width-style"] == "custom" &&
      options["collapse-max-width-value"]
    ) {
      maxWidth = `${options["collapse-max-width-value"]}px !important;`;
    } else if (options["collapse-max-width-style"] == "full") {
      maxWidth = "100% !important;";
    }

    let collapseHeadingFontFamily =
      options["collapse-heading-font-family"] || undefined;
    let collapseHeadingFontWeight =
      options["collapse-heading-font-weight"] || undefined;
    let collapseHeadingFontSize =
      options["collapse-heading-font-size"] || undefined;
    let collapseHeadingFontColor =
      options["collapse-heading-font-color"] || undefined;
    let collapseHeadingBackgroundColor =
      options["collapse-heading-background-color"] || undefined;

    let collapseHeadingChevronColor = (
      options["collapse-heading-font-color"] ||
      variant.options["collapse-heading-font-color"] ||
      "#000000"
    ).replace("#", "%23");

    let collapseHeadingBorderRadius = "";
    if (options["collapse-heading-radius"] == "cf-not-rounded") {
      collapseHeadingBorderRadius = "0px";
    }
    if (options["collapse-heading-radius"] == "cf-slightly-rounded") {
      collapseHeadingBorderRadius = "4px";
    }
    if (options["collapse-heading-radius"] == "cf-fully-rounded") {
      collapseHeadingBorderRadius = "100px";
    }
    if (options["collapse-heading-radius"] == "custom") {
      collapseHeadingBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          collapseHeadingBorderRadius += `${
            _.isNil(options["collapse-heading-radius-" + side]) == false
              ? options["collapse-heading-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let collapseBoxShadow;

    if (options["collapse-shadow"] == "None") {
      collapseBoxShadow = "none";
    }

    if (options["collapse-shadow"] == "soft") {
      collapseBoxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["collapse-shadow"] == "hard") {
      collapseBoxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["collapse-shadow"] == "custom") {
      collapseBoxShadow = "";
      collapseBoxShadow +=
        (element.options["collapse-shadow-horizontal-distance"]
          ? `${element.options["collapse-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      collapseBoxShadow +=
        (element.options["collapse-shadow-vertical-distance"]
          ? `${element.options["collapse-shadow-vertical-distance"]}px`
          : "0px") + " ";

      collapseBoxShadow +=
        (element.options["collapse-shadow-blur-radius"]
          ? `${element.options["collapse-shadow-blur-radius"]}px`
          : "0px") + " ";

      collapseBoxShadow +=
        (element.options["collapse-shadow-spread-radius"]
          ? `${element.options["collapse-shadow-spread-radius"]}px`
          : "0px") + " ";

      collapseBoxShadow +=
        (element.options["collapse-shadow-color"]
          ? element.options["collapse-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";
    }

    let collapseContentBackgroundColor =
      options["collapse-content-background-color"] || undefined;
    let collapseItemsDistance = options["collapse-items-distance"] || undefined;

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-element {
        ${maxWidth ? `max-width: ${maxWidth};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse {
        ${collapseItemsDistance ? `margin-bottom: ${collapseItemsDistance}px;` : ''}
        ${collapseBoxShadow ? `box-shadow: ${collapseBoxShadow} !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-title * {
        ${collapseHeadingFontFamily ? `font-family: ${collapseHeadingFontFamily}, Helvetica, Arial, sans-serif !important;` : ''}
        ${collapseHeadingFontWeight ? `font-weight: ${collapseHeadingFontWeight} !important;` : ''}
        ${collapseHeadingFontSize ? `font-size: ${collapseHeadingFontSize}px !important;` : ''}
        ${collapseHeadingFontColor ? `color: ${collapseHeadingFontColor};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse > .cf-collapse-heading .cf-collapse-toggle {
        display: flex;
        justify-content: space-between;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse > .cf-collapse-heading .cf-collapse-toggle:after {
        font-size: 12px;
        font-family: 'Glyphicons Halflings';
        content: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg id='Arrow / Chevron_Right'%3E%3Cpath id='Vector' d='M9 5L16 12L9 19' stroke='${collapseHeadingChevronColor}' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        float: right;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse > .cf-collapse-heading .cf-collapse-toggle.cf-collapsed:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 24 15' fill='currentColor'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.29297 6.70711C3.68349 6.31658 4.31666 6.31658 4.70718 6.70711L12.0001 14L19.293 6.70711C19.6835 6.31658 20.3167 6.31658 20.7072 6.70711L21.4143 7.41421C21.8048 7.80474 21.8048 8.4379 21.4143 8.82843L13.0607 17.182C12.4749 17.7678 11.5252 17.7678 10.9394 17.182L2.58586 8.82843C2.19534 8.4379 2.19534 7.80474 2.58586 7.41422L3.29297 6.70711Z' fill='${collapseHeadingChevronColor}'/%3E%3C/svg%3E");
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-heading {
        ${collapseHeadingBackgroundColor ? `background: ${collapseHeadingBackgroundColor};` : ''}
        ${options["collapse-heading-border-style"] ? `border-style: ${options["collapse-heading-border-style"]} !important;` : ''}
        ${_.isNil(options["collapse-heading-border-width"]) == false ? 'border-width: ' + options["collapse-heading-border-width"] + 'px !important;' : ''}
        ${_.isNil(options["collapse-heading-border-width-left"]) == false ? 'border-left-width: ' + options["collapse-heading-border-width-left"] + 'px !important;' : ''}
        ${_.isNil(options["collapse-heading-border-width-right"]) == false ? 'border-right-width: ' + options["collapse-heading-border-width-right"] + 'px !important;' : ''}
        ${_.isNil(options["collapse-heading-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["collapse-heading-border-width-bottom"] + 'px !important;' : ''}
        ${_.isNil(options["collapse-heading-border-width-top"]) == false ? 'border-top-width: ' + options["collapse-heading-border-width-top"] + 'px !important;' : ''}
        ${options["collapse-heading-border-color"] ? `border-color: ${options["collapse-heading-border-color"]} !important;` : ''}
        ${collapseHeadingBorderRadius ? 'border-radius: ' + collapseHeadingBorderRadius + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-box {
        ${collapseContentBackgroundColor ? `background: ${collapseContentBackgroundColor} !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-box.cf-collapse-box-in {
        ${collapseHeadingBorderRadius ? 'border-radius: ' + collapseHeadingBorderRadius + ' !important;' : ''}
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
      }
      `;

    let maxWidthMobile;
    if (
      options["collapse-max-width-style-mobile"] == "custom" &&
      options["collapse-max-width-value-mobile"]
    ) {
      maxWidthMobile = `${options["collapse-max-width-value-mobile"]}px !important;`;
    } else if (options["collapse-max-width-style-mobile"] == "full") {
      maxWidthMobile = "100% !important;";
    }

    let collapseHeadingFontSizeMobile =
      options["collapse-heading-font-size-mobile"] || undefined;

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-element {
        ${maxWidthMobile ? `max-width: ${maxWidthMobile};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-collapse-title * {
        ${collapseHeadingFontSizeMobile ? `font-size: ${collapseHeadingFontSizeMobile}px !important;` : ''}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default React.memo(ElementCollapse, arePropsEqual);
