var subscriptions = {};

import upgrade_modal from "packs/subscriptions/upgrade_modal.js";
subscriptions.upgrade_modal = upgrade_modal;

import account_plan_modal from "packs/subscriptions/account_plan_modal.js";
subscriptions.account_plan_modal = account_plan_modal;

import premium_website_modal from "packs/subscriptions/premium_website_modal.js";
subscriptions.premium_website_modal = premium_website_modal;

import pricing_table from "packs/subscriptions/pricing_table.js";
subscriptions.pricing_table = pricing_table;

import feature_gates from "packs/subscriptions/feature_gates.js";
subscriptions.feature_gates = feature_gates;

document.addEventListener("turbolinks:load", function () {
  if (
    ($("body").attr("data-controller") == "subscriptions" &&
      $("body").attr("data-action") == "plans") ||
    ($("body").attr("data-controller") == "start" &&
      $("body").attr("data-action") == "show") ||
    ($("body").attr("data-controller") == "accounts" &&
      $("body").attr("data-action") == "onboarding")
  ) {
    subscriptions.account_plan_modal(undefined, false);
  }

  if (
    $("body").attr("data-controller") == "subscriptions" &&
    $("body").attr("data-action") == "new"
  ) {
    $("#headingThree")
      .find("a")
      .on("click", function () {
        $(this).hide();
      });

    $("#new_subscription").on("submit", function () {
      if (
        $("body").attr("data-controller") == "subscriptions" &&
        $("body").attr("data-action") == "new"
      ) {
        const accountId = getUrlParameter("account_id");
        const stripePlan = getUrlParameter("features_plan_id") || getUrlParameter("stripe_plan");

        if (getUrlParameter("trial") == "true" && stripePlan) {
          dataLayer.push({
            event: "cf_start_trial",
          });
          console.log("cf_start_trial");

          ["funnels", "pro", "advanced", "scale"].forEach((plan) => {
            if (stripePlan.indexOf(plan) > -1) {
              const eventValue = `cf_start_trial_${plan}`;
              dataLayer.push({
                event: eventValue,
              });
              console.log(eventValue);

              if (window.convertflow && accountId) {
                convertflow.trackPurchase({
                  revenue: plan == 'pro' ? 99 : plan == 'advanced' ? 300 : plan == 'scale' ? 500 : 29, 
                  order_id: getUrlParameter("account_id")
                });
              }
            }
          });
        }

        if (getUrlParameter("trial") !== "true" && stripePlan) {
          dataLayer.push({
            event: "cf_purchase",
          });
          console.log("cf_purchase");

          [
            "funnels",
            "pro",
            "advanced",
            "scale",
            "enterprise",
          ].forEach((plan) => {
            if (stripePlan.indexOf(plan) > -1) {
              const eventValue = `cf_purchase_${plan}`;
              dataLayer.push({
                event: eventValue,
              });
              console.log(eventValue);

              if (window.convertflow && accountId) {
                convertflow.trackPurchase({
                  revenue: plan == 'pro' ? 99 : plan == 'advanced' ? 300 : plan == 'scale' ? 500 : plan == 'enterprise' ? 2000 : 29, 
                  order_id: accountId
                });
              }
            }
          });
        }
      }
    });
  }

  if (
    $("body").attr("data-controller") == "subscriptions" &&
    $("body").attr("data-action") == "index"
  ) {
    if (window.location.href.indexOf("modal=true") > -1) {
      $("#upgrade").modal("show");
      subscriptions.pricing_table();
    }
  }

  if (
    $("body").attr("data-controller") == "subscriptions" &&
    $("body").attr("data-action") == "plans"
  ) {
    if (window.location.href.indexOf("annual=true") > -1) {
      $('a[href="#annual"]').trigger('click');
    }
  }

  if ($("body").attr("data-controller") == "start") {
    $('[data-toggle="popover"]').popover();
  }

  subscriptions.feature_gates();
});

export default subscriptions;
