import React, { useContext, useState, useEffect, useRef } from "react";
import _, { xor } from "lodash";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VariantBuilderSettingSpacing = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [selectedSpacingType, selectSpacingType] = useState(null);
  const [selectedSide, selectSide] = useState(null);
  const setting_name = props.setting_name;
  const node = useRef(null);

  let currentSettingName;
  let current_value;

  if (selectedSide && selectedSpacingType) {
    currentSettingName =
      setting_name.replace("SPACING_TYPE", selectedSpacingType) +
      selectedSide +
      "]";
    current_value = _.get(props.object, currentSettingName);
  }

  function updateSpacing(spacingType, side, action, setInputValue) {
    let newSettingName =
      setting_name.replace("SPACING_TYPE", spacingType) + side + "]";

    let new_value = _.get(props.object, newSettingName);
    
    if ((_.isNil(new_value) || new_value == '') && new_value !== 0 && newSettingName.indexOf("-mobile") > -1) {
      let desktopSettingName = newSettingName.replace("-mobile", "");
      new_value = _.get(props.object, desktopSettingName);
    }

    new_value = new_value || 0;

    if (typeof new_value == "string" && new_value.indexOf("_") > -1) {
      const levelsMap = {
        0: 0,
        1: 15,
        2: 25,
        3: 40,
        4: 70,
        5: 120,
      };

      let level = new_value.split("_").pop();
      new_value = levelsMap[level] || 0;
    } else if (typeof new_value == "string") {
      new_value = parseInt(new_value);
    }

    let increment = 20;

    if (new_value < 60) {
      increment = 10;
    }

    if (new_value < 20) {
      increment = 5;
    }

    if (action == "add") {
      new_value = new_value + increment;
    }

    if (action == "subtract") {
      new_value = new_value - increment;

      if (spacingType == "padding" && new_value < 0) {
        new_value = 0;
      }
    }

    if (new_value !== current_value) {
      builder.update([
        {
          object_type: props.object_type,
          object_id: props.object_id,
          setting_name: newSettingName,
          value: new_value,
          skip_history: props.skip_history,
        },
      ]);
    }

    selectSpacingType(spacingType);
    selectSide(side);
    setInputValue(new_value);
  }

  function onChange(value, spacing_type, side) {
    const currentSettingName = `${setting_name.replace(
      "SPACING_TYPE",
      spacing_type
    )}${side}]`;

    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: currentSettingName,
        value: value,
      },
    ]);
  }

  const StyleControl = ({ spacing_type, side, disabled }) => {
    const inputRef = useRef(null);
    const currentSettingName = `${setting_name.replace("SPACING_TYPE", spacing_type)}${side}]`;
    const value = _.get(props.object, currentSettingName);
    const valueDesktop = _.get(props.object, currentSettingName.replace("-mobile", ""));
    const verticalSpacing = ["top", "bottom"].includes(side);
    const [inputValue, setInputValue] = useState(null);
    const iconVisibility =
      selectedSpacingType == spacing_type &&
      selectedSide == side &&
      disabled !== true
        ? "visible"
        : "hidden";

    return (
      <React.Fragment>
        <div
          className={`flex items-center justify-center ${
            verticalSpacing ? "flex-row" : "flex-col-reverse"
          }`}
          data-spacing-type={spacing_type}
          data-side={side}
          onMouseOver={(e) => {
            selectSpacingType(spacing_type);
            selectSide(side);
          }}
        >
          <div
            className={`cursor-pointer -mt-[3px] center`}
            title="Subtract"
            onClick={() => {
              updateSpacing(spacing_type, side, "subtract", setInputValue);
            }}
            style={{
              visibility: iconVisibility,
              width: "20px",
            }}
          >
            <FontAwesomeIcon
              icon="fas fa-minus"
              className="text-sm text-slate-500 dark:text-slate-200"
            />
          </div>
          <div
            className={`flex items-center justify-center ${
              verticalSpacing ? "px-3" : ""
            }`}
          >
            <input
              type="number"
              defaultValue={value}
              placeholder={currentSettingName.indexOf('mobile') > -1 && valueDesktop ? valueDesktop : "0"}
              ref={inputRef}
              min={spacing_type == "padding" ? 0 : null}
              onFocus={(e) => {
                selectSpacingType(spacing_type);
                selectSide(side);

                setTimeout(() => {
                  document
                    .querySelector(
                      `input[data-setting_name="${props.setting_name}-${spacing_type}${side}"]`
                    )
                    .focus();
                }, 100);
              }}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
              onBlur={(e) => {
                onChange(e.target.value, spacing_type, side);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onChange(inputValue, spacing_type, side);
                }
              }}
              disabled={disabled}
              className={`setting-spacing-input w-12 text-center bg-transparent text-slate-500 dark:text-slate-200 rounded-md border-none outline-none p-0`}
              data-setting_name={`${props.setting_name}-${spacing_type}${side}`}
            />
          </div>
          <div
            className={`cursor-pointer -mt-[3px] ${!verticalSpacing ? 'pb-2' : ''} center`}
            title="Add"
            style={{
              visibility: iconVisibility,
              width: "20px",
            }}
            onClick={() => {
              updateSpacing(spacing_type, side, "add", setInputValue);
            }}
          >
            <FontAwesomeIcon
              icon="fas fa-plus"
              className="text-sm text-slate-500 dark:text-slate-200"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    selectSide(null);
    selectSpacingType(null);
  };

  useEffect(() => {
    if (selectedSide && selectedSpacingType) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedSide, selectedSpacingType]);

  return (
    <React.Fragment>
      <div
        ref={node}
        className="w-full relative bg-slate-100 dark:bg-slate-800 rounded-lg"
      >
        <div className="absolute top left mt-2 pl-2 text-slate-600 dark:text-slate-200 font-semibold text-sm">
          MARGIN
        </div>
        <div className="w-full py-2">
          <StyleControl
            spacing_type="margin"
            side="top"
            disabled={props.padding_only == true}
          />
        </div>
        <div className="w-full">
          <div className="flex flex-row items-center justify-between">
            <div className="w-[40px] items-center justify-between">
              <StyleControl
                spacing_type="margin"
                side="left"
                disabled={props.padding_only == true}
              />
            </div>
            <div className="flex-grow bg-white dark:bg-slate-950 rounded-md">
              <div className="w-full mt-2 -mb-4">
                <StyleControl spacing_type="padding" side="top" />
              </div>
              <div className="w-full flex flex-row items-center justify-between">
                <div className="w-[40px] items-center justify-between">
                  <StyleControl spacing_type="padding" side="left" />
                </div>
                <div className="flex-grow text-slate-600 dark:text-slate-200 text-sm font-semibold text-center pt-4 pb-3">
                  PADDING
                </div>
                <div className="w-[40px] items-center justify-between">
                  <StyleControl spacing_type="padding" side="right" />
                </div>
              </div>
              <div className="w-full -mt-4 mb-2">
                <StyleControl spacing_type="padding" side="bottom" />
              </div>
            </div>
            <div className="w-[40px] items-center justify-between">
              <StyleControl
                spacing_type="margin"
                side="right"
                disabled={props.padding_only == true}
              />
            </div>
          </div>
        </div>
        <div className="w-full py-2">
          <StyleControl
            spacing_type="margin"
            side="bottom"
            disabled={props.padding_only == true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingSpacing;
