import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const useContentLists = () => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  const ContentLists = {
    create: async function (element, callback) {
      let position =
        Object.values(variant.content_list_items).filter(
          (list_item) =>
            list_item.element_id == element.id && element.toBeDeleted !== true
        ).length + 1;

      builder.create(
        "content_list_items",
        {
          position: position,
          element_id: element.id,
          content: `Item ${position}`,
        },
        { callback: callback }
      );
    },

    remove: (list_item) => {
      let message =
        "Are you sure you want to remove this item? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("content_list_items", list_item.id, message);
    },

    duplicate: async function (og_field, payload, skip_history) {
      let attributes = {
        position: og_field.position,
        content: og_field.content,
        visual_type: og_field.visual_type,
        image: og_field.image,
        icon: og_field.icon,
        letter: og_field.letter,
        emoji: og_field.emoji,
        options: og_field.options,
        element_id: og_field.element_id,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      builder.create("content_list_items", attributes, {
        skip_history: skip_history,
      });
    },

    sortableStart: (list_item) => {
      let containerSelector =
        '.sortable-container[data-sortable-name="content-list-items"][data-object_type="content_list_items"]';
      let container = document.querySelector(containerSelector);

      let item_selector =
        containerSelector +
        " .setting[data-setting_type='item_v2'][data-object_type='content_list_items']";

      let sortable_options = {
        sort: true,
        group: { name: "content-list-item-position" },
        animation: 150,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle: ".item-trigger[data-object_type='content_list_items'], .cf-item-v2",
        forceFallback: true,
        fallbackOnBody: true,
        fallbackClass: "builder-sortable-fallback",
        fallbackTolerance: 5,
        delay: 10,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        swapThreshold: 0.8,
        onUpdate: function (event) {
          ContentLists.sortableUpdate(event, list_item);
        },
      };

      if (container) {
        new Sortable(container, sortable_options);
      }
    },

    sortableUpdate: (event, list_item) => {
      let updates = [];

      Array.from(event.to.children).forEach((list_item_div, i) => {
        let new_position = i + 1;
        let object_id = list_item_div.getAttribute("data-object_id");
        if (object_id) {
          let list_item_id = parseInt(object_id);

          updates.push({
            object_type: "content_list_items",
            object_id: list_item_id,
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },
  };

  let currentHook = ContentLists;
  return { ContentLists, currentHook };
};

export default useContentLists;
