import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const useProductVariants = () => {
  const [contextData, builder] = useContext(VariantContextData);
  let variant = contextData.objects.variant;

  const ProductVariants = {
    create: async function (product, callback) {
      builder.create(
        "product_variants",
        {
          product_id: product.id,
        },
        {
          callback: callback,
        }
      );
    },

    duplicate: async function (og_product_variant, payload, skip_history) {
      let attributes = {
        title: og_product_variant.title,
        price: og_product_variant.price,
        image: og_product_variant.image,
        position: og_product_variant.position,
        redirect_url: og_product_variant.redirect_url,
      };

      if (payload) {
        attributes = { ...attributes, ...payload };
      }

      builder.create("product_variants", attributes, {
        callback: async function (product_variant) {},
        skip_history: skip_history,
      });
    },

    remove: (product_variant) => {
      let message =
        "Are you sure you want to remove this product variant? Once your changes are saved, it will be irreversibly deleted.";

      builder.remove("product_variants", product_variant.id, message);
    },

    sortableStart: (product) => {
      let container = document.querySelector(
        `.sortable-container[data-object_type="products"][data-object_id="${product.id}"]`
      );

      let item_selector =
        ".setting[data-setting_type='item_v2'][data-object_type='product_variants']";

      $(container)
        .find(item_selector)
        .sort(function (a, b) {
          return $(a).attr("data-position") - $(b).attr("data-position");
        })
        .prependTo(container);

      let sortable_options = {
        sort: true,
        group: { name: "product-variants-position" },
        animation: 0,
        draggable: item_selector,
        ghostClass: "drop-zone",
        handle: ".cf-item-v2[data-object_type='product_variants']",
        scroll: true,
        forceFallback: true,
        fallbackTolerance: 5,
        scrollSensitivity: 100,
        scrollSpeed: 10,
        onUpdate: (event) => {
          ProductVariants.sortableUpdate(event, product);
        },
      };
      new Sortable(container, sortable_options);
    },

    sortableUpdate: (event, product) => {
      let updates = [];

      Array.from(event.to.children).forEach((product_variant_div, i) => {
        let new_position = i + 1;
        let setting_name =
          "[options][" +
          product_variant_div.getAttribute("data-name") +
          "-position]";

        if (product_variant_div.getAttribute("data-setting_type") == "item_v2") {
          updates.push({
            object_type: "product_variants",
            object_id: parseInt(
              product_variant_div.getAttribute("data-object_id")
            ),
            setting_name: "[position]",
            value: new_position,
          });
        }
      });

      builder.update(updates);
    },
  };

  let currentHook = ProductVariants;
  return { ProductVariants, currentHook };
};

export default useProductVariants;
