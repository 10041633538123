document.addEventListener("turbolinks:load", function () {
  var account_id = $("body").attr("data-account-id");

  if (account_id) {
    if (!window.account || account.id + "" !== account_id) {
      $.getJSON(window.location.origin + "/accounts/" + account_id, function (
        data
      ) {
        if (data) {
          window.account = data;
          onboard.checklist.initialize();
        }
      });
    } else {
      onboard.checklist.initialize();
    }
  } else {
    window.account = undefined;
  }

  if (
    $("body").attr("data-controller") == "accounts" &&
    $("body").attr("data-action") == "partner"
  ) {
    if (window.convertflow) {
      convertflow.start();
    } else {
      var script = document.createElement("script");
      script.async = true;
      script.src = "https://js.convertflow.co/production/websites/3.js";
      document.body.appendChild(script);
    }

    var tab = getParameterByName("tab");
    if (tab) {
      $('a[aria-controls="' + tab + '"]').click();
    }
  }

  if (
    $("body").attr("data-controller") == "accounts" &&
    $("body").attr("data-action") == "cancel"
  ) {
    $("#schedule-nevermind").on("click", function () {
      $(".cancel-step").hide();
      $('.cancel-step[data-step="call-scheduled"]').show();
    });

    $("#benefits-nevermind, #confirmation-nevermind, #websites-nevermind").on(
      "click",
      function () {
        $(".cancel-step").hide();
        $('.cancel-step[data-step="nevermind"]').show();
      }
    );

    $("#schedule-continue").on("click", function () {
      $(".cancel-step").hide();
      $('.cancel-step[data-step="why"]').show();
    });

    $('.cancel-step[data-step="why"] .cancel-feedback').on("submit", function (
      e
    ) {
      e.preventDefault();
      $.ajax({
        type: "POST",
        url: "cancel_feedback",
        data: $(this).serialize(),
      });

      $(".cancel-step").hide();
      $('.cancel-step[data-step="feedback"]').show();
    });

    $('.cancel-step[data-step="feedback"] .cancel-feedback').on(
      "submit",
      function (e) {
        e.preventDefault();
        $.ajax({
          type: "POST",
          url: "cancel_feedback",
          data: $(this).serialize(),
        });

        $(".cancel-step").hide();
        $('.cancel-step[data-step="benefits"]').show();
      }
    );

    $("#benefits-confirmation").on("click", function () {
      $(".cancel-step").hide();
      $('.cancel-step[data-step="confirmation"]').show();
    });

    $("#confirmation-continue").on("click", function () {
      window.location.href = "downgrade";
    });

    $("#request-cancel button").on("click", function () {
      $.ajax({
        type: "POST",
        url: "request_churn"
      });

      $("#request-cancel").text("Request sent! We'll be in touch soon.")
    });
  }

  // onboarding modal
  $("#onboardingModal").modal("show");
  $(".onboarding-step").first().show();

  $(".onboarding-step").each(function (i, question_div) {
    var question = $(question_div).attr("data-question");

    function showNextStep(next_question) {
      $(question_div).hide();
      var next_step = $(
        '.onboarding-step[data-question="' + next_question + '"]'
      )[0];

      if (next_step) {
        $(next_step).show();
      } else {
        $("#onboardingModal").modal("hide");
        setTimeout(function () {
          var isModal = $("#onboardingModal").length > 0;

          if (isModal) {
            window.location.reload();
          } else {
            let name = $('[name="user[name]').val();
            let lastname = $('[name="user[lastname]').val();
            let company = $('[name="user[company]').val();
            let website = $('[name="user[website]').val();
            let isAgency = $('[name="user[data][agency]"]').val();
            let businessType = $('[name="user[data][business_type]"]').val();
            let trafficEstimate = $(
              '[name="user[data][traffic_estimate]"]'
            ).val();
            let queryStrings = `?name=${name}&lastname=${lastname}&company_name=${company}&url=${website}&industry=${
              isAgency ? "agency" : businessType
            }&traffic_estimate=${trafficEstimate}`;

            window.location.href = `https://pages.convertflow.com/welcome${queryStrings}`;
          }
        }, 500);
      }
    }
    

    $(question_div)
      .find("form")
      .on("submit", function () {
        var next_question = $(this).attr("data-next");
        if (next_question) {
          showNextStep(next_question);
        }
      });

    $(question_div)
      .find(".onboarding-step-survey")
      .on("click", function () {
        var answer = $(this).attr("data-answer");
        var next_question = $(this).attr("data-next");
        var input = $(question_div).find(".onboard-step-survey-input");

        if (input) {
          $(input).val(answer);
          $(question_div).find("form").trigger("submit.rails");
        }

        showNextStep(next_question);
      });
  });

  $("#accounts-promos-carousel .item").each(function (i, item) {
    $(".carousel-indicators").append(
      `<li data-target="#accounts-promos-carousel" data-slide-to="${i}"></li>`
    );
  });
  $("#accounts-promos-carousel .item").first().addClass("active");
  $(".carousel-indicators").children().first().addClass("active");
  $("#accounts-promos-carousel").carousel({
    interval: 8000,
  });

  $("#accounts-promos-carousel .close").on("click", function () {
    $("#accounts-promos-carousel").hide();

    setCookie("promos-hidden", "true");
  });

  if (getCookie("promos-hidden") !== "true") {
    $("#accounts-promos-carousel").show();
  }
});
