import React, { useState, useEffect, useContext } from "react";
import Section from "../sections/Section";
import arePropsEqual from "../../utils/arePropsEqual";
import { VariantContextData } from "../variants/VariantBuilderContext";

const ElementTabs = (props) => {
  let element = props.element;
  const classes = style.classes(element, props.builder, props.device);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element,
    props.variant
  );

  let sorted_tabs_items = Object.values(element.content_toggle_items)
    .filter(
      (tab_item) =>
        tab_item.element_id == element.id && tab_item.toBeDeleted !== true
    )
    .sort((a, b) => a.position - b.position);

  const [visibleTabPosition, setVisibleTabPosition] = useState(
    element.options["tabs-open-item-position"]
  );

  const choosedLayout =
    element.options["tabs-layout"] ||
    props.variant.options["tabs-layout"] ||
    "horizontal";

  useEffect(() => {
    if (props.builder == true) {
      if (visibleTabPosition == undefined && sorted_tabs_items[0]) {
        setVisibleTabPosition(1);
      }

      $(
        `#cta_${props.cta.id} .cf-element[data-element-id="${element.id}"] .cf-tab-item[data-tab-element-id="${element.id}"]`
      )
        .off("click")
        .on("click", function (e) {
          let tabPosition = e.currentTarget.dataset["tabPos"];

          if (tabPosition != visibleTabPosition) {
            setVisibleTabPosition(tabPosition);

            // save open tab position
            let updates = [
              {
                object_type: "elements",
                object_id: element.id,
                setting_name: "[options][tabs-open-item-position]",
                value: tabPosition,
                skip_history: false,
              },
            ];

            dispatchCustomEvent("updateBuilder", { updates: updates });

            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id
            });
          }
        });
    }
  }, [choosedLayout, sorted_tabs_items]);

  let sortedTabs = () => {
    return (
      <React.Fragment>
        {sorted_tabs_items.map((tab_item) => {
          let tab_item_active = visibleTabPosition
            ? visibleTabPosition == tab_item.position
            : tab_item.position == 1;

          return (
            <div
              key={`cf-tab-item-${tab_item.id}`}
              className={`cf-tab-item ${tab_item_active ? "active" : ""}`}
              data-section-id={
                tab_item.section ? tab_item.section.id : undefined
              }
              data-tab-id={tab_item.id}
              data-tab-pos={tab_item.position}
              data-tab-element-id={tab_item.element_id}
              dangerouslySetInnerHTML={{
                __html: tab_item.content,
              }}
            ></div>
          );
        })}
      </React.Fragment>
    );
  };

  let sortedSections = () => {
    return (
      <React.Fragment>
        {sorted_tabs_items.map((tab_item) => {
          let tab_item_section = Object.values(element.sections).filter(
            (section) =>
              section.content_toggle_item_id == tab_item.id &&
              section.step_id == props.section.step_id
          )[0];

          if (tab_item_section) {
            return (
              <div
                id={`cf-tab-item-${tab_item.id}-section-${tab_item_section.id}`}
                key={`cf-tab-item-${tab_item.id}-section-${tab_item_section.id}`}
                className="cf-tab-item-cf-section-box"
                style={{
                  display:
                    tab_item.position == visibleTabPosition ? "" : "none",
                }}
                data-tab-element-id={element.id}
                data-tab-id={tab_item.id}
              >
                <Section
                  website={props.website}
                  cta={props.cta}
                  variant={props.variant}
                  builder={props.builder}
                  preview={props.preview}
                  step={props.step}
                  section={tab_item_section}
                  device={props.device}
                ></Section>
              </div>
            );
          }
        })}
      </React.Fragment>
    );
  };

  const horizontalLayout = () => {
    return (
      <React.Fragment>
        <div className="cf-horizontal-tabs">{sortedTabs()}</div>
        <div className="cf-section-box">{sortedSections()}</div>
      </React.Fragment>
    );
  };

  const verticalLayout = () => {
    return (
      <React.Fragment>
        <div className="cf-vertical-tabs">
          <div className="cf-tabs-box">
            <div className="cf-tabs-list">{sortedTabs()}</div>
          </div>
          <div className="cf-section-box">{sortedSections()}</div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div
        className={classes}
        data-element-id={element.id}
        data-font={
          element.options["tabs-font-family"] ||
          props.variant.options["tabs-font-family"]
        }
      >
        {choosedLayout === "vertical" ? verticalLayout() : horizontalLayout()}

        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element, builder, device) => {
    let options = element.options;
    let classes = "element-content cf-tabs-element ";
    let alignmentClass = "cf-outer-center";

    if (device == "mobile") {
      if (options["cf-alignment-mobile"]) {
        if (options["cf-alignment-mobile"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment-mobile"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment-mobile"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    } else {
      if (options["cf-alignment"]) {
        if (options["cf-alignment"].indexOf("left") > -1) {
          alignmentClass = "cf-outer-left";
        } else if (options["cf-alignment"].indexOf("center") > -1) {
          alignmentClass = "cf-outer-center";
        } else if (options["cf-alignment"].indexOf("right") > -1) {
          alignmentClass = "cf-outer-right";
        }
      }
    }

    classes += " " + alignmentClass;

    return classes;
  },
  stylesheet: (builder, device, cta, element, variant) => {
    let options = { ...element.options };

    let maxWidth;
    if (
      options["tabs-max-width-style"] == "custom" &&
      options["tabs-max-width-value"]
    ) {
      maxWidth = `${options["tabs-max-width-value"]}px !important;`;
    } else if (options["tabs-max-width-style"] == "full") {
      maxWidth = "100% !important;";
    }

    let tabsAlignment = "";
    if (
      options["tabs-layout"] == "horizontal" ||
      (options["tabs-layout"] == "" &&
        variant.options["tabs-layout"] == "horizontal")
    ) {
      tabsAlignment = options["tabs-alignment"];
    }

    let tabsFontFamily = options["tabs-font-family"] || undefined;
    let tabsFontWeight = options["tabs-font-weight"] || undefined;
    let tabsFontSize = options["tabs-font-size"] || undefined;
    let tabsFontColor = options["tabs-font-color"] || undefined;
    let tabsFontColorHover = options["tabs-font-color-hover"] || undefined;
    let tabsFontColorActive = options["tabs-font-color-active"] || undefined;
    let tabsBackgroundColor = options["tabs-background-color"] || undefined;
    let tabsBackgroundColorHover =
      options["tabs-background-color-hover"] || undefined;
    let tabsBackgroundColorActive =
      options["tabs-background-color-active"] || undefined;

    let tabsBorderRadius = "";
    if (options["tabs-radius"] == "cf-not-rounded") {
      tabsBorderRadius = "0px";
    }
    if (options["tabs-radius"] == "cf-slightly-rounded") {
      tabsBorderRadius = "4px";
    }
    if (options["tabs-radius"] == "cf-fully-rounded") {
      tabsBorderRadius = "100px";
    }
    if (options["tabs-radius"] == "custom") {
      tabsBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          tabsBorderRadius += `${
            _.isNil(options["tabs-radius-" + side]) == false
              ? options["tabs-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let tabsItemPadding = "";
    if (options["tabs-padding-style"] == "custom") {
      tabsItemPadding = options["tabs-padding-value"];
    }

    let tabsContentBackgroundColor =
      options["tabs-content-background-color"] || undefined;

    let tabsContentBorderRadius = "";
    if (options["tabs-content-radius"] == "cf-not-rounded") {
      tabsContentBorderRadius = "0px";
    }
    if (options["tabs-content-radius"] == "cf-slightly-rounded") {
      tabsContentBorderRadius = "4px";
    }
    if (options["tabs-content-radius"] == "cf-fully-rounded") {
      tabsContentBorderRadius = "100px";
    }
    if (options["tabs-content-radius"] == "custom") {
      tabsContentBorderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          tabsContentBorderRadius += `${
            _.isNil(options["tabs-content-radius-" + side]) == false
              ? options["tabs-content-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let tabsContentSpacingHorizontal =
      options["tabs-content-spacing-horizontal"] || undefined;
    let tabsContentSpacingVertical =
      options["tabs-content-spacing-vertical"] || undefined;
    let tabsWidth = options["tabs-width"] || undefined;

    let tabsBorderColor = options["tabs-border-color"] || undefined;
    let tabsBorderColorActive =
      options["tabs-border-color-active"] || undefined;
    let tabsBorderColorHover = options["tabs-border-color-hover"] || undefined;

    // prettier-ignore
    const desktopStyles =
      `#cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element {
        ${maxWidth ? `max-width: ${maxWidth};` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .disabled-item {
        height: unset;
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-section-box {
        ${tabsContentBackgroundColor ? `background: ${tabsContentBackgroundColor};` : ''}
        ${options["tabs-content-border-style"] ? `border-style: ${options["tabs-content-border-style"]} !important;` : ''}
        ${_.isNil(options["tabs-content-border-width"]) == false ? 'border-width: ' + options["tabs-content-border-width"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-content-border-width-left"]) == false ? 'border-left-width: ' + options["tabs-content-border-width-left"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-content-border-width-right"]) == false ? 'border-right-width: ' + options["tabs-content-border-width-right"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-content-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["tabs-content-border-width-bottom"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-content-border-width-top"]) == false ? 'border-top-width: ' + options["tabs-content-border-width-top"] + 'px !important;' : ''}
        ${options["tabs-content-border-color"] ? `border-color: ${options["tabs-content-border-color"]} !important;` : 'border-color: #000000;'}
        ${tabsContentBorderRadius ? 'border-radius: ' + tabsContentBorderRadius + ' !important;' : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item {
        ${tabsItemPadding ? `padding: ${tabsItemPadding}px !important;` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item {
        ${tabsFontFamily ? `font-family: ${tabsFontFamily}, Helvetica, Arial, sans-serif !important;` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-vertical-tabs .cf-tab-item .cf-label {
        ${tabsFontSize ? `font-size: ${tabsFontSize - 3}px;` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-vertical-tabs .cf-tabs-box .cf-tabs-list {
        gap: 10px;
        ${tabsAlignment ? `justify-content: ${tabsAlignment} !important;` : ''}
        ${tabsFontWeight ? `font-weight: ${tabsFontWeight} !important;` : ''}
        ${tabsFontSize ? `font-size: ${tabsFontSize}px !important;` : ''}
        ${tabsFontColor ? `color: ${tabsFontColor} !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs {
        ${tabsContentSpacingHorizontal ? `margin-bottom: ${tabsContentSpacingHorizontal}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-box {
        ${tabsContentSpacingVertical ? `margin-right: ${tabsContentSpacingVertical}px !important;` : ''}
        ${tabsWidth ? `width: ${tabsWidth}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs .cf-tab-item {
        ${tabsWidth ? `width: ${tabsWidth}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs .cf-tab-item:hover,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs .cf-tab-item.active:hover {
        ${options["tabs-border-style"] || variant.options["tabs-border-style"] ? '' : tabsFontColorHover ? `border-bottom: 2px solid ${tabsFontColorHover} !important;` : 'border-bottom: 2px solid #1e90ff;' }
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs .cf-tab-item.active {
        ${options["tabs-border-style"] || variant.options["tabs-border-style"] ? '' : tabsFontColorActive ? `border-bottom: 2px solid ${tabsFontColorActive} !important;` : 'border-bottom: 2px solid #1e90ff;' }
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item:hover,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item.active:hover {
        ${tabsFontColorHover ? `color: ${tabsFontColorHover} !important;` : ''}
        ${tabsBackgroundColorHover ? `background: ${tabsBackgroundColorHover} !important;` : ''}
        ${tabsBorderColorHover ? `border-color: ${tabsBorderColorHover} !important;` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item {
        overflow: hidden;
        ${tabsBackgroundColor ? `background: ${tabsBackgroundColor};` : ''}
        ${options["tabs-border-style"] ? `border-style: ${options["tabs-border-style"]} !important;` : ''}
        ${_.isNil(options["tabs-border-width"]) == false ? 'border-width: ' + options["tabs-border-width"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-border-width-left"]) == false ? 'border-left-width: ' + options["tabs-border-width-left"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-border-width-right"]) == false ? 'border-right-width: ' + options["tabs-border-width-right"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["tabs-border-width-bottom"] + 'px !important;' : ''}
        ${_.isNil(options["tabs-border-width-top"]) == false ? 'border-top-width: ' + options["tabs-border-width-top"] + 'px !important;' : ''}
        ${tabsBorderColor ? `border-color: ${tabsBorderColor} !important;` : ''}
        ${tabsBorderRadius ? 'border-radius: ' + tabsBorderRadius + ' !important;' : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item.active {
        ${tabsFontColorActive ? `color: ${tabsFontColorActive} !important;` : ''}
        ${tabsBackgroundColorActive ? `background: ${tabsBackgroundColorActive};` : ''}
        ${tabsBorderColorActive ? `border-color: ${tabsBorderColorActive} !important;` : ''}
      }
      `;

    let maxWidthMobile;
    if (
      options["tabs-max-width-style-mobile"] == "custom" &&
      options["tabs-max-width-value-mobile"]
    ) {
      maxWidthMobile = `${options["tabs-max-width-value-mobile"]}px !important;`;
    } else if (options["tabs-max-width-style-mobile"] == "full") {
      maxWidthMobile = "100% !important;";
    }

    let tabsFontSizeMobile = options["tabs-font-size-mobile"] || undefined;

    let tabsItemPaddingMobile = "";
    if (options["tabs-padding-style-mobile"] == "custom") {
      tabsItemPaddingMobile = options["tabs-padding-value-mobile"];
    }

    let tabsContentSpacingHorizontalMobile =
      options["tabs-content-spacing-horizontal-mobile"] || undefined;
    let tabsContentSpacingVerticalMobile =
      options["tabs-content-spacing-vertical-mobile"] || undefined;
    let tabsWidthMobile = options["tabs-width-mobile"] || undefined;

    // prettier-ignore
    const mobileStyles =
      `/* mobile styles */
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element {
        ${maxWidthMobile ? `max-width: ${maxWidthMobile};` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs,
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-vertical-tabs .cf-tabs-box .cf-tabs-list {
        ${tabsFontSizeMobile ? `font-size: ${tabsFontSizeMobile}px !important;` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-vertical-tabs .cf-tab-item .cf-label {
        ${tabsFontSizeMobile ? `font-size: ${tabsFontSizeMobile - 3}px;` : ''}
      }
      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-element .cf-tab-item {
        ${tabsItemPaddingMobile ? `padding: ${tabsItemPaddingMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-tabs-box {
        ${tabsWidthMobile ? `width: ${tabsWidthMobile}px !important;` : ''}
        ${tabsContentSpacingVerticalMobile ? `margin-right: ${tabsContentSpacingVerticalMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs .cf-tab-item {
        ${tabsWidthMobile ? `width: ${tabsWidthMobile}px !important;` : ''}
      }

      #cta_${cta.id} .cf-element[data-element-id="${element.id}"] .cf-horizontal-tabs {
        ${tabsContentSpacingHorizontalMobile ? `margin-bottom: ${tabsContentSpacingHorizontalMobile}px !important;` : ''}
      }
      `;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },
};

export default React.memo(ElementTabs, arePropsEqual);
