import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import StepReport from "../steps/StepReport";

const VariantReport = (props) => {
  const cta = props.cta;
  const [tab, setTab] = useState("conversions");
  const [timeframeType, setTimeframeType] = useState("All Time");
  const [rangeStartValue, setRangeStartValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_start
  );
  const [rangeEndValue, setRangeEndValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_end
  );
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);
  const [variantId, setVariantId] = useState(props.variantId);
  const variant = variantId
    ? Object.values(props.variants).filter((v) => v.id + "" == variantId)[0]
    : null;
  const wistia = useRef(null);
  const gateReports = props.gateReports;

  let group_by = ReportsHelpers.getGroupBy(timeframeType);
  let range_start = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_start;
  let range_end = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_end;

  if (timeframeType == "Date Range") {
    range_start = rangeStartValue;
    range_end = rangeEndValue;
    group_by = ReportsHelpers.getGroupBy(null, range_start, range_end);
  }

  useEffect(() => {
    $(rangeStartInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(0, 0, 0, 0),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date(
          $(e.currentTarget).find("input").val()
        ).setUTCHours(0, 0, 0, 0);
        const value = new Date(timestamp).toISOString();
        setRangeStartValue(value);
      });

    $(rangeEndInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(23, 59, 59, 999),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date(
          $(e.currentTarget).find("input").val()
        ).setUTCHours(23, 59, 59, 999);
        const value = new Date(timestamp).toISOString();
        setRangeEndValue(value);
      });
  }, [tab, timeframeType, variantId, rangeStartValue, rangeEndValue]);

  useEffect(() => {
    if (gateReports) {
      $.getScript('https://fast.wistia.com/assets/external/E-v1.js');
      $.getScript('https://fast.wistia.com/embed/medias/s1hrpbbk2u.jsonp');
    }
  }, [props.cta.id])

  return (
    <React.Fragment>
      <div className="row hard-center smooth VariantReport" style={{minWidth: '765px'}}>
        {props.legacyReports == true && timeframeType !== "All Time" && (
          <div className="row hard-center" style={{ marginTop: "15px" }}>
            <div className="col-xs-12">
              <div className="col-xs-12 dark alert-blue">
                <p className="smooth semibold">
                  This variant was created before the 2023 analytics update was
                  released, when the new charts began.{" "}
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target={`#variant-${variantId}-report`}
                  >
                    See legacy reports →
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="row hard-center some-vertical-space">
          <div className="col-xs-5">
            <h4 className="bold">Funnel Breakdown </h4>
            <p className="light ellipsis">
              See how people progress through variant{" "}
              <span style={{ textTransform: "capitalize" }}>
                {variant.variation}
                {variant.name ? ` - ${variant.name}` : ""}
              </span>
            </p>
          </div>
          <div className="col-xs-7">
            <div className="col-xs-3 timeframe-date-selector">
              <select
                className="text-field"
                style={{ float: "right" }}
                onChange={(event) => setTimeframeType(event.target.value)}
                name="timeframe"
                defaultValue={
                  props.timeframeTypes.filter(
                    (option) => option.selected == true
                  )[0].value
                }
              >
                {props.timeframeTypes.map((option) => (
                  <option
                    value={option.value}
                    key={`timeframe-option-${option.value}`}
                  >
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-end"
                ref={rangeEndInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="End"
                  value={rangeEndValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeEndValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-start"
                ref={rangeStartInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="Start"
                  value={rangeStartValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeStartValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3"
              style={{ float: "right", paddingTop: "10px" }}
            >
              <select
                className="text-field"
                onChange={(event) => {
                  setVariantId(event.target.value);
                }}
                value={variantId}
              >
                {" "}
                {props.cta.variants.map((variant) => (
                  <option
                    key={`variant-option-${variant.id}`}
                    value={variant.id}
                  >{`Variant ${variant.variation.toUpperCase()} ${
                    variant.name ? `(${variant.name})` : ""
                  }`}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="row hard-center steps-reports-container border-solid border-slate-100 border-0 border-t-[1px]" style={{width: '100%'}}>
            {variant.steps.map((step) => {
              return (
                <StepReport
                  key={`step-${step.id}`}
                  cta={cta}
                  step={step}
                  timeframeType={timeframeType}
                  range_start={range_start}
                  range_end={range_end}
                  firstStep={variant.steps[0]}
                  externalProductValues={props.externalProductValues}
                  currency={props.currency}
                  gateReports={gateReports}
                />
              );
            })}
          </div>
          {gateReports && (
            <div className="p-7 mt-10 mb-5">
              <div className="row hard-center bg-slate-100 rounded-lg p-7 !pb-0">
                <div className="row hard-center some-vertical-space">
                  <div
                    className="wistia_responsive_padding"
                    data-id="s1hrpbbk2u"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <div
                      className="wistia_responsive_wrapper"
                      style={{
                        height: "100%",
                        left: "0",
                        position: "absolute",
                        top: "0",
                        width: "100%",
                      }}
                    >
                      <div
                        className="wistia_embed wistia_async_s1hrpbbk2u popover=true videoFoam=true"
                        style={{
                          display: "inline-block",
                          height: "100%",
                          position: "relative",
                          width: "100%",
                        }}
                        ref={wistia}
                      >
                        <img
                          src="https://fast.wistia.com/embed/medias/s1hrpbbk2u/swatch"
                          style={{
                            filter: "blur(5px)",
                            height: "100%",
                            objectFit: "contain",
                            width: "100%",
                          }}
                          alt=""
                          aria-hidden="true"
                          onLoad={() => {
                            wistia.current.style.opacity = 1;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row hard-center">
                  <p className="bold">Advanced revenue insights</p>
                  <p>
                    For each funnel step, element and answer, get revenue insights:
                  </p>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                        stroke="#003FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Orders placed
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                        stroke="#003FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Purchase rate
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                        stroke="#003FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Total revenue
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-1 !pr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                        stroke="#003FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="col-xs-10 no-padding-right text-xl">
                    Average order value
                  </div>
                </div>
                <div className="row mb-5 mt-2">
                  <div className="col-xs-12">
                    <button className="btn bg-black !text-white font-bold py-2 px-4" onClick={(event) => {
                      subscriptions.upgrade_modal(
                        event,
                        false,
                        "get full revenue insights",
                        "2024_advanced",
                        "Triggered feature CTA – Revenue Reports"
                      );
                    }}>
                      <svg
                        className="mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M3.30039 4.40019V3.94305C3.30039 2.4236 4.50468 1.2002 6.00039 1.2002C7.49611 1.2002 8.70039 2.4236 8.70039 3.94305V4.40019M3.30039 4.40019C2.80539 4.40019 2.40039 4.81162 2.40039 5.31448V9.88591C2.40039 10.3888 2.80539 10.8002 3.30039 10.8002H8.70039C9.19539 10.8002 9.60039 10.3888 9.60039 9.88591V5.31448C9.60039 4.81162 9.19539 4.40019 8.70039 4.40019M3.30039 4.40019H8.70039"
                          stroke="white"
                          strokeWidth="1.3"
                          strokeLinecap="round"
                        />
                      </svg>
                      Upgrade To Unlock
                    </button>
                    <p className="text-xl mt-5">
                      <a href="https://support.convertflow.com/en/articles/8573269-attributing-revenue-to-convertflow-campaigns" target="_blank">
                        Learn more →
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VariantReport;
