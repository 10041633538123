import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const CtaCampaignToggle = (props) => {
  const [live, setLive] = useState(props.broadcast.live);
  const websiteLink = "/websites/" + props.website.id;
  const broadcastEndpoint =
    window.location.origin + websiteLink + "/broadcasts/" + props.broadcast.id;

  async function saveStatus(status) {
    await fetch(broadcastEndpoint, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": csrfToken(),
      },
      method: "PUT",
      body: JSON.stringify({ broadcast: { live: status } }),
    });
  }

  function toggleLive(e, status) {
    status = status || e.target.checked;
    const plan_type = window.plan_type || props.plan_type;

    if (
      props.cta.cta_type !== "inline" &&
      (["free", "2023_build"].includes(plan_type) || props.premium == "false")
    ) {
      subscriptions.upgrade_modal(
        event,
        false,
        plan_type == "2023_build"
          ? "launch popup, sticky bar & embed campaigns"
          : "go live on your own website and try the full funnel builder.",
        plan_type == "2023_build" ? "2023_launch" : "2024_pro",
        "Triggered feature CTA – Campaigns"
      );
    } else if (
      props.cta.cta_type == "inline" &&
      (["free", "2024_funnels", "2024_pro"].includes(plan_type) ||
        props.premium == "false")
    ) {
      subscriptions.upgrade_modal(
        event,
        false,
        "launch targeted embed campaigns",
        "2024_advanced",
        "Triggered feature CTA – Targeted Embeds"
      );
    } else {
      saveStatus(status);
      setLive(status);

      if (status == true) {
        $("#quickLaunchModal").modal("show");
      }
    }
  }

  const StatusLabel = () => {
    return (
      <span className="semibold smooth campaign-status">
        <a
          style={{
            color: live ? "#1CBCAB" : undefined,
          }}
          href={websiteLink + "/cta/" + props.cta.id + "/launch"}
        >
          <span>{live ? "Active" : "Inactive"}</span>
        </a>
      </span>
    );
  };

  return (
    <React.Fragment>
      {props.labelLocation !== "left" && (
        <React.Fragment>
          <div className="flex relative">
            <div>
              <div className="cta-campaign-toggle">
                <input
                  type="checkbox"
                  className="ios-switch campaign-toggle-checkbox"
                  checked={live}
                  onChange={toggleLive}
                  style={{
                    marginTop: "-10px",
                  }}
                />
                <article className="campaign-toggle !m-0">
                  <article></article>
                </article>
              </div>
            </div>
            <div
              onClick={(e) => {
                toggleLive(e, !live);
              }}
              className="pl-5 light text-xl cursor-pointer"
            >
              Campaign is {live ? "live" : "inactive"}
            </div>
          </div>
        </React.Fragment>
      )}
      {props.labelLocation == "left" && (
        <React.Fragment>
          <div
            className="cta-campaign-toggle"
            data-toggle="tooltip"
            data-placement="top"
            title="Campaign status"
          >
            <input
              type="checkbox"
              className="ios-switch campaign-toggle-checkbox"
              checked={live}
              onChange={toggleLive}
            />
            <article className="campaign-toggle">
              <article></article>
            </article>
            <StatusLabel {...props} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CtaCampaignToggle;
