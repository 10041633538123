import React, { useContext, useState } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";

const VariantBuilderSettingOptionsButtons = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const [activeOption, setActiveOption] = useState(props.value || props.placeholder || "");

  function updateOption(value) {
    setActiveOption(value);

    builder.update([
      {
        object_type: props.object_type,
        object_id: props.object_id,
        setting_name: props.setting_name,
        value: value,
        skip_history: props.skip_history,
      },
    ]);
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center p-1 text-black dark:text-white bg-slate-200 dark:bg-slate-700 rounded-lg">
        {props.options.map((option) => {
          return (
            <button
              key={option.title + '-' + option.value}
              className={`flex-1 h-[30px] text-base p-2 border-none bg-transparent rounded-lg ${
                activeOption == option.value ? "bg-white dark:bg-slate-950 font-bold" : ""
              }`}
              onClick={() => {
                updateOption(option.value);
              }}
              title={option.title}
            >
              {option.title}
            </button>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default VariantBuilderSettingOptionsButtons;
