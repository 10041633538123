import React, { useEffect } from "react";
import PropTypes from "prop-types";

const ElementHtml = (props) => {
  let element = props.element;

  return (
    <React.Fragment>
      <div
        id={"element" + element.id + "-content"}
        className="element-content cf-text-element cf-html"
        style={{ minHeight: "20px" }}
      >
        {props.builder !== true && (
          <div
            dangerouslySetInnerHTML={{
              __html: element.html,
            }}
          ></div>
        )}
        {props.builder == true && (
          <div className="cf-html-placeholder element-content">
            <span
              style={{
                fontSize: "12px",
              }}
              className="glyphicon glyphicon-menu-left"
              aria-hidden="true"
            ></span>
            <strong>Custom HTML</strong>
            <span
              className="glyphicon glyphicon-menu-right"
              style={{
                fontSize: "12px",
              }}
              aria-hidden="true"
            ></span>
            <br></br>
            Double click to edit
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ElementHtml;
