import React, { useContext, useState, useEffect } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import htmlToLabel from "../../utils/htmlToLabel";

const ElementSettingsLogic = (props) => {
  const [contextData, builder] = useContext(VariantContextData);
  const variant = contextData.objects.variant;
  const element = props.element;

  const actionGroups = Object.values(variant.action_groups).filter(
    (action_group) =>
      action_group.element_id == element.id && !action_group.toBeDeleted
  );

  const field = ["survey", "quiz"].includes(element.element_type)
    ? Object.values(variant.fields).filter(
        (field) => field.element_id == element.id
      )[0]
    : null;

  const warning =
    element.options["confirmation-type"] == "step" &&
    (!element.options["redirect-step"] ||
      (element.options["redirect-step"] !== "previous" &&
        Object.values(variant.steps).filter(
          (s) =>
            s.id + "" == element.options["redirect-step"] &&
            s.toBeDeleted !== true
        ).length == 0))
      ? "Step to jump to not yet set"
      : element.options["confirmation-type"] == "redirect" &&
        !element.options["redirect-url"] &&
        element.options["confirmation-close"] !== "true"
      ? "Redirect URL not yet set"
      : element.options["confirmation-type"] == "cta" &&
        !element.options["confirmation-cta"]
      ? "Campaign to display not yet set"
      : element.options["confirmation-type"] == "sms" &&
        !element.options["sms-number"]
      ? "SMS opt-in information incomplete"
      : undefined;

  return (
    <React.Fragment>
      <div className="row hard-center my-3">
        <div className="row hard-center">
          {warning && (
            <div className="w-full setting-warning-label text-xl text-red-600 dark:text-red-400 mb-3">
              <FontAwesomeIcon
                icon="fas fa-exclamation-triangle"
                className="mr-5"
              />
              {warning}
            </div>
          )}
        </div>
        <div
          className="row hard-center cf-item-v2"
          data-object-id={element.id}
          data-object_type={"elements"}
          data-position={element.position}
          onClick={() => {
            dispatchCustomEvent("selectObject", {
              object_type: "elements",
              object_id: element.id,
              tab: "Actions",
            });
          }}
        >
          <div
            className={`cf-item-box my-3 text-xl py-2 pl-3 bg-white dark:bg-slate-950 border-solid border-[1px] text-slate-500 dark:text-slate-200 border-slate-200 dark:border-slate-700 rounded-md`}
          >
            <div className="cf-item-title relative">
              <FontAwesomeIcon
                className={`mt-4 ${actionGroups.length ? "rotate-180" : ""}`}
                icon={
                  actionGroups.length > 0
                    ? "fa-solid fa-bolt"
                    : element.options["confirmation-type"] == "step"
                    ? "fa-solid fa-code-branch"
                    : element.options["confirmation-type"] == "redirect"
                    ? "fa-solid fa-link"
                    : element.options["confirmation-type"] == "cta"
                    ? "fa-solid fa-message"
                    : element.options["confirmation-type"] == "sms"
                    ? "fa-solid fa-mobile"
                    : element.options["confirmation-type"] == "message"
                    ? "fa-solid fa-quote-left"
                    : ""
                }
                title={
                  actionGroups.length > 0
                    ? `Has ${actionGroups.length} conditional actions`
                    : element.options["confirmation-type"] == "step"
                    ? "Jump to funnel step"
                    : element.options["confirmation-type"] == "redirect"
                    ? "Redirect to a URL"
                    : element.options["confirmation-type"] == "cta"
                    ? "Display a popup campaign"
                    : element.options["confirmation-type"] == "sms"
                    ? "Subscribe via SMS code"
                    : element.options["confirmation-type"] == "message"
                    ? "Show a thank you message"
                    : ""
                }
              />
              {actionGroups.length > 0 && (
                <div
                  className="absolute top-0 left-0 w-5 h-5 text-slate-500 dark:text-white flex items-center justify-center text-xs font-bold border-solid border-[1px] border-slate-500 dark:border-white rounded-full"
                  style={{ transform: "translate(0%, -20%)" }}
                  title={`Has ${actionGroups.length} conditional actions`}
                >
                  {actionGroups.length}
                </div>
              )}
            </div>
            <div
              className="cf-item-title overflow-hidden overflow-ellipsis"
              style={{
                textWrap: "nowrap",
              }}
            >
              {element.element_type == "form"
                ? `Form: ${htmlToLabel(element.html)}`
                : element.element_type == "button"
                ? `Button: ${htmlToLabel(element.html)}`
                : element.element_type == "quiz"
                ? `Quiz: ${htmlToLabel(
                    field
                      ? field.label
                        ? field.label
                        : field.slug
                      : element.id
                  )}`
                : element.element_type == "survey"
                ? `Survey: ${field ? field.slug : element.id}`
                : element.element_type == "products"
                ? `Products Feed: ${
                    element.options["products-source"]
                      ? element.options["products-source"]
                      : element.id
                  }`
                : `Element ${element.id}`}
            </div>
            <div className="cf-item-controls" style={{ display: "flex" }}>
              <button
                title="Edit"
                className={`w-[25px] h-[25px] p-0 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
                onClick={() => {}}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0869 5.21357L12.7869 7.91357M3.33691 14.6636L6.6114 14.0038C6.78524 13.9688 6.94485 13.8832 7.0702 13.7577L14.4005 6.42346C14.7519 6.07182 14.7517 5.50183 14.3999 5.15048L12.8471 3.59943C12.4955 3.24823 11.9258 3.24846 11.5745 3.59996L4.24349 10.935C4.11838 11.0602 4.03295 11.2195 3.99789 11.3929L3.33691 14.6636Z"
                    stroke={contextData.dark_mode ? "white" : "black"}
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ElementSettingsLogic;
