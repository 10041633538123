import React, { useState, useEffect, useRef } from "react";
import ReportsHelpers from "../../utils/ReportsHelpers";
import ReportCount from "../reports/ReportCount";
import ReportChart from "../reports/ReportChart";
import ReportList from "../reports/ReportList";

const WebsiteCustomFields = (props) => {
  const website = props.website;
  const [timeframeType, setTimeframeType] = useState("All Time");
  const [customField, setCustomField] = useState(null);
  const wistia = useRef(null);

  let customFields = [];
  let fieldOptions = [];

  Object.values(website.fields).forEach((field) => {
    if (customFields.filter((f) => f.slug == field.slug).length == 0) {
      customFields.push(field);
    }
  });

  if (customField) {
    website.fields
      .filter((field) => field.slug == customField)
      .forEach((field) => {
        Object.values(field.field_options).forEach((field_option) => {
          if (
            fieldOptions.filter(
              (fOption) => field_option.value == fOption.value
            ).length == 0
          ) {
            field_option.field = field.slug;
            fieldOptions.push(field_option);
          }
        });
      });
  }

  const [rangeStartValue, setRangeStartValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_start
  );
  const [rangeEndValue, setRangeEndValue] = useState(
    props.timeframeTypes.filter((t) => t.value == "Today")[0].range_end
  );
  const rangeStartInput = useRef(null);
  const rangeEndInput = useRef(null);

  let group_by = ReportsHelpers.getGroupBy(timeframeType);
  let range_start = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_start;
  let range_end = props.timeframeTypes.filter(
    (t) => t.value == timeframeType
  )[0].range_end;

  if (timeframeType == "Date Range") {
    range_start = rangeStartValue;
    range_end = rangeEndValue;
    group_by = ReportsHelpers.getGroupBy(null, range_start, range_end);
  }

  const updateAOV = (ordersSelector, revenueSelector, aovSelector) => {
    const ordersSpan = $(ordersSelector);
    const revenueSpan = $(revenueSelector);

    const orders = parseInt($(ordersSpan).text().replaceAll(",", ""));
    const revenue = parseFloat($(revenueSpan).text().replaceAll(",", ""));

    let aov = orders && revenue ? revenue / orders : 0;
    $(aovSelector).text(aov.toFixed(2));
  };

  useEffect(() => {
    $(rangeStartInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(0, 0, 0, 0),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date(
          $(e.currentTarget).find("input").val()
        ).setUTCHours(0, 0, 0, 0);
        const value = new Date(timestamp).toISOString();
        setRangeStartValue(value);
      });

    $(rangeEndInput.current)
      .datetimepicker({
        defaultDate: new Date().setUTCHours(23, 59, 59, 999),
      })
      .off("dp.change")
      .on("dp.change", function (e) {
        const timestamp = new Date(
          $(e.currentTarget).find("input").val()
        ).setUTCHours(23, 59, 59, 999);
        const value = new Date(timestamp).toISOString();
        setRangeEndValue(value);
      });
  }, [timeframeType, rangeStartValue, rangeEndValue]);

  useEffect(() => {
    if (props.gateReports) {
      $.getScript("https://fast.wistia.com/assets/external/E-v1.js");
      $.getScript("https://fast.wistia.com/embed/medias/hqds0fxgj3.jsonp");
    }
  }, [props.website.id]);

  return (
    <React.Fragment>
      <div className="row hard-center smooth">
        <div
          className="row hard-center some-vertical-space"
          style={{ marginBottom: "15px" }}
        >
          <div className={`col-xs-3 no-padding-right`}>
            {props.overview == true && timeframeType == "Date Range" && (
              <h4 className="bold">
                <a
                  href={`/websites/${website.id}/insights?insight=custom_fields`}
                >
                  Custom Fields Report →
                </a>
              </h4>
            )}
            {props.overview == true && timeframeType !== "Date Range" && (
              <h4 className="bold">
                <a
                  href={`/websites/${website.id}/insights?insight=custom_fields`}
                >
                  Custom Fields Report →
                </a>
              </h4>
            )}
            {props.overview !== true && (
              <h4 className="bold">Custom Fields Report</h4>
            )}
          </div>
          <div className={`col-xs-9 no-padding-sides`}>
            <div className="col-xs-3 timeframe-date-selector no-padding-right">
              <select
                className="text-field"
                style={{ float: "right" }}
                onChange={(event) => setTimeframeType(event.target.value)}
                defaultValue={timeframeType}
              >
                {props.timeframeTypes.map((option) => (
                  <option
                    value={option.value}
                    key={`timeframe-option-${option.value}`}
                  >
                    {option.value}
                  </option>
                ))}
              </select>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-end"
                ref={rangeEndInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="End"
                  value={rangeEndValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeEndValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3 timeframe-date-selector"
              style={{
                display: timeframeType == "Date Range" ? "block" : "none",
              }}
            >
              <div
                className="input-group date range-start"
                ref={rangeStartInput}
                style={{ width: "130px" }}
              >
                <input
                  type="text"
                  className="text-field"
                  placeholder="Start"
                  value={rangeStartValue}
                  style={{ borderRadius: "3px 0px 0px 3px" }}
                  onChange={(event) => setRangeStartValue(event.target.value)}
                />
                <span className="input-group-addon !border-slate-200 !bg-white !text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.35714 5.92632H12.5859M4.86384 1.82812V3.05773M12.9844 1.82812V3.05758M15.375 6.05758L15.375 13.1719C15.375 14.8287 14.0319 16.1719 12.375 16.1719H5.625C3.96815 16.1719 2.625 14.8287 2.625 13.1719V6.05758C2.625 4.40073 3.96815 3.05758 5.625 3.05758H12.375C14.0319 3.05758 15.375 4.40073 15.375 6.05758Z"
                      stroke="black"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="col-xs-3"
              style={{ float: "right", paddingTop: "10px", width: "150px" }}
            >
              <select
                className="text-field"
                onChange={(event) => {
                  setCustomField(event.target.value);
                }}
              >
                <option value="">All fields</option>
                {customFields.map((field) => (
                  <option key={`field-${field.id}`} value={field.slug}>
                    {field.slug}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div
          className="row hard-center"
          style={{ borderTop: "1px solid rgb(233, 235, 241)" }}
        >
          <div className="col-xs-12" style={{ marginTop: "15px" }}>
            <p className="light" style={{ marginBottom: "6px" }}>
              {!customField
                ? `See how many people have submitted your custom fields`
                : `See how many people selected each option for ${customField}`}
            </p>
          </div>
          <div className={`col-xs-${props.gateReports ? "9" : "12"}`}>
            <div className="row hard-center list-stat">
              <div className="col-xs-4 left no-padding-sides cta-insight-column">
                <p>
                  <strong>{!customField ? `Field Slug` : `Option`}</strong>
                </p>
              </div>
              <div className={`col-xs-8 no-padding-sides`}>
                <div className="col-xs-3 left no-padding-sides cta-insight-column">
                  <p>
                    <strong>{!customField ? "Responded" : "Selected"}</strong>
                  </p>
                </div>
                {!props.gateReports && (
                  <React.Fragment>
                    <div
                      className="col-xs-3 left no-padding-sides cta-insight-column"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        "Customers who submitted a response to this field within 7 days before purchasing"
                      }
                    >
                      <p>
                        <strong>Purchased</strong>
                      </p>
                    </div>
                    <div
                      className="col-xs-3 left no-padding-sides cta-insight-column"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        "Revenue attributed to customers who submitted a response to this field within 7 days before purchasing"
                      }
                    >
                      <p>
                        <strong>Revenue</strong>
                      </p>
                    </div>
                    <div
                      className="col-xs-3 left no-padding-sides cta-insight-column"
                      data-toggle="tooltip"
                      data-placement="top"
                      title={
                        "Average revenue attributed to customers who submitted a response to this field"
                      }
                    >
                      <p>
                        <strong>AOV</strong>
                      </p>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div
            className={`col-xs-${props.gateReports ? 9 : 12}`}
            style={{
              display: "flex",
              flexBasis: "100%",
              flexWrap: "wrap",
              flexDirection: "column-reverse",
            }}
          >
            {!customField && (
              <React.Fragment>
                {customFields
                  .slice(0, props.overview == true ? 5 : 25)
                  .map((field) => {
                    return (
                      <React.Fragment>
                        <div
                          id={`field-${field.slug}-wrapper`}
                          className="row list-stat"
                          style={{ width: "100%" }}
                        >
                          <div className="col-xs-4 cta-insight-column">
                            <p className="light ellipsis">{field.slug}</p>
                          </div>
                          <div className="col-xs-8 no-padding-sides">
                            <div className="col-xs-3 cta-insight-column">
                              <p className="light">
                                <ReportCount
                                  inline={true}
                                  id={`field-${field.slug}-count`}
                                  path={`/websites/${props.website.id}/events_reports`}
                                  payload={{
                                    report_type: "distinct_count",
                                    report: {
                                      website_id: props.website.id,
                                      event_type: ["Complete", "Selection"],
                                      field: field.slug,
                                      range_start: range_start,
                                      range_end: range_end,
                                    },
                                  }}
                                  callback={() => {
                                    const count = $(
                                      `#field-${field.slug}-count`
                                    ).text();
                                    $(`#field-${field.slug}-wrapper`).css(
                                      "order",
                                      count ? count : 0
                                    );
                                  }}
                                  unstyled={true}
                                />
                              </p>
                            </div>
                            {!props.gateReports && (
                              <React.Fragment>
                                <div className="col-xs-3 cta-insight-column">
                                  <p className="light">
                                    <ReportCount
                                      inline={true}
                                      id={`field-${field.slug}-orders`}
                                      path={`/websites/${props.website.id}/events_reports`}
                                      payload={{
                                        report_type: "distinct_count",
                                        report: {
                                          website_id: props.website.id,
                                          event_type: "Order",
                                          contactable_type: null,
                                          contactable_id: null,
                                          variant_id: null,
                                          step_id: null,
                                          element_id: null,
                                          field: field.slug,
                                          value: null,
                                          product_id: null,
                                          broadcast_id: null,
                                          range_start: range_start,
                                          range_end: range_end,
                                        },
                                      }}
                                      callback={() => {
                                        updateAOV(
                                          `#field-${field.slug}-orders`,
                                          `#field-${field.slug}-revenue`,
                                          `#field-${field.slug}-aov`
                                        );
                                      }}
                                      unstyled={true}
                                    />
                                  </p>
                                </div>
                                <div className="col-xs-3 cta-insight-column">
                                  <p className="light">
                                    <ReportCount
                                      inline={true}
                                      id={`field-${field.slug}-revenue`}
                                      path={`/websites/${props.website.id}/events_reports`}
                                      payload={{
                                        report_type: "revenue",
                                        report: {
                                          website_id: props.website.id,
                                          event_type: "Order",
                                          contactable_type: null,
                                          contactable_id: null,
                                          variant_id: null,
                                          step_id: null,
                                          element_id: null,
                                          field: field.slug,
                                          value: null,
                                          product_id: null,
                                          broadcast_id: null,
                                          range_start: range_start,
                                          range_end: range_end,
                                        },
                                      }}
                                      callback={() => {
                                        updateAOV(
                                          `#field-${field.slug}-orders`,
                                          `#field-${field.slug}-revenue`,
                                          `#field-${field.slug}-aov`
                                        );
                                      }}
                                      currency={props.currency}
                                      unstyled={true}
                                    />
                                  </p>
                                </div>
                                <div className="col-xs-3 cta-insight-column">
                                  <p className="light">
                                    {props.currency}
                                    <span id={`field-${field.slug}-aov`}>
                                      0.00
                                    </span>
                                  </p>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            )}
            {customField && (
              <React.Fragment>
                {fieldOptions
                  .slice(0, props.overview == true ? 5 : 25)
                  .map((field_option) => {
                    const fieldOptionId = field_option.value.replace(" ", "_");
                    return (
                      <React.Fragment>
                        <div
                          id={`field-option-${fieldOptionId}-wrapper`}
                          className="row list-stat"
                          style={{ width: "100%" }}
                        >
                          <div className="col-xs-4 cta-insight-column">
                            <p className="light">{field_option.value}</p>
                          </div>
                          <div className="col-xs-8 no-padding-sides cta-insight-column">
                            <div className="col-xs-3">
                              <p className="light">
                                <ReportCount
                                  inline={true}
                                  id={`field-option-${fieldOptionId}-count`}
                                  path={`/websites/${props.website.id}/events_reports`}
                                  payload={{
                                    report_type: "distinct_count",
                                    report: {
                                      website_id: props.website.id,
                                      event_type: ["Complete", "Selection"],
                                      field: field_option.field,
                                      value: field_option.value,
                                      range_start: range_start,
                                      range_end: range_end,
                                    },
                                  }}
                                  callback={() => {
                                    const count = $(
                                      `#field-option-${fieldOptionId}-count`
                                    ).text();
                                    $(
                                      `#field-option-${fieldOptionId}-wrapper`
                                    ).css("order", count ? count : 0);
                                  }}
                                  unstyled={true}
                                />
                              </p>
                            </div>
                            {!props.gateReports && (
                              <React.Fragment>
                                <div className="col-xs-3 cta-insight-column">
                                  <p className="light">
                                    <ReportCount
                                      inline={true}
                                      id={`field-option-${fieldOptionId}-orders`}
                                      path={`/websites/${props.website.id}/events_reports`}
                                      payload={{
                                        report_type: "distinct_count",
                                        report: {
                                          website_id: props.website.id,
                                          event_type: "Order",
                                          contactable_type: null,
                                          contactable_id: null,
                                          variant_id: null,
                                          step_id: null,
                                          element_id: null,
                                          field: field_option.field,
                                          value: field_option.value,
                                          product_id: null,
                                          broadcast_id: null,
                                          range_start: range_start,
                                          range_end: range_end,
                                        },
                                      }}
                                      callback={() => {
                                        updateAOV(
                                          `#field-option-${fieldOptionId}-orders`,
                                          `#field-option-${fieldOptionId}-revenue`,
                                          `#field-option-${fieldOptionId}-aov`
                                        );
                                      }}
                                    />
                                  </p>
                                </div>
                                <div className="col-xs-3 cta-insight-column">
                                  <p className="light">
                                    {props.currency}
                                    <ReportCount
                                      inline={true}
                                      id={`field-option-${fieldOptionId}-revenue`}
                                      path={`/websites/${props.website.id}/events_reports`}
                                      payload={{
                                        report_type: "revenue",
                                        report: {
                                          website_id: props.website.id,
                                          event_type: "Order",
                                          contactable_type: null,
                                          contactable_id: null,
                                          variant_id: null,
                                          step_id: null,
                                          element_id: null,
                                          field: field_option.field,
                                          value: field_option.value,
                                          product_id: null,
                                          broadcast_id: null,
                                          range_start: range_start,
                                          range_end: range_end,
                                        },
                                      }}
                                      callback={() => {
                                        updateAOV(
                                          `#field-option-${fieldOptionId}-orders`,
                                          `#field-option-${fieldOptionId}-revenue`,
                                          `#field-option-${fieldOptionId}-aov`
                                        );
                                      }}
                                      unstyled={true}
                                    />
                                  </p>
                                </div>
                                <div className="col-xs-3 cta-insight-column">
                                  <p className="light">
                                    {props.currency}
                                    <span
                                      id={`field-option-${fieldOptionId}-aov`}
                                    >
                                      0.00
                                    </span>
                                  </p>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            )}
          </div>
          {props.gateReports && (
            <React.Fragment>
              <div className="col-xs-3 !float-right" style={{ marginTop: "-80px", maxWidth: '250px' }}>
                <div className="row hard-center bg-slate-100 rounded-lg px-7">
                  <div className="row hard-center some-vertical-space">
                    <div
                      className="wistia_responsive_padding"
                      data-id="hqds0fxgj3"
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <div
                        className="wistia_responsive_wrapper"
                        style={{
                          height: "100%",
                          left: "0",
                          position: "absolute",
                          top: "0",
                          width: "100%",
                        }}
                      >
                        <div
                          className="wistia_embed wistia_async_hqds0fxgj3 popover=true videoFoam=true"
                          style={{
                            display: "inline-block",
                            height: "100%",
                            position: "relative",
                            width: "100%",
                          }}
                          ref={wistia}
                        >
                          <img
                            src="https://fast.wistia.com/embed/medias/hqds0fxgj3/swatch"
                            style={{
                              filter: "blur(5px)",
                              height: "100%",
                              objectFit: "contain",
                              width: "100%",
                            }}
                            alt=""
                            aria-hidden="true"
                            onLoad={() => {
                              wistia.current.style.opacity = 1;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row hard-center">
                    <p className="bold">Advanced revenue insights</p>
                    <p>Get revenue insights on quiz answers & field values:</p>
                  </div>
                  <div className="row">
                    <div className="col-xs-1 !pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                          stroke="#003FFF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="col-xs-10 no-padding-right text-xl">
                      Orders placed
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-1 !pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                          stroke="#003FFF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="col-xs-10 no-padding-right text-xl">
                      Total revenue
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-1 !pr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                      >
                        <path
                          d="M10.4861 4.72222C10.3479 4.33003 10.0955 3.98817 9.7613 3.74065C9.42715 3.49313 9.02657 3.35127 8.61111 3.33333H5.83333C5.2808 3.33333 4.75089 3.55283 4.36019 3.94353C3.96949 4.33423 3.75 4.86413 3.75 5.41667C3.75 5.9692 3.96949 6.49911 4.36019 6.88981C4.75089 7.28051 5.2808 7.5 5.83333 7.5H8.61111C9.16364 7.5 9.69355 7.71949 10.0842 8.11019C10.475 8.5009 10.6944 9.0308 10.6944 9.58333C10.6944 10.1359 10.475 10.6658 10.0842 11.0565C9.69355 11.4472 9.16364 11.6667 8.61111 11.6667H5.83333C5.41788 11.6487 5.0173 11.5069 4.68315 11.2593C4.34899 11.0118 4.09656 10.67 3.95833 10.2778M7.22222 1.25V3.33333M7.22222 11.6667V13.75"
                          stroke="#003FFF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="col-xs-10 no-padding-right text-xl">
                      Average order value
                    </div>
                  </div>
                  <div className="row mb-5 mt-2">
                    <div className="col-xs-12">
                      <button
                        className="btn bg-black !text-white font-bold py-2 px-4"
                        onClick={(event) => {
                          subscriptions.upgrade_modal(
                            event,
                            false,
                            "get full revenue insights",
                            "2024_advanced",
                            "Triggered feature CTA – Revenue Reports"
                          );
                        }}
                      >
                        <svg
                          className="mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M3.30039 4.40019V3.94305C3.30039 2.4236 4.50468 1.2002 6.00039 1.2002C7.49611 1.2002 8.70039 2.4236 8.70039 3.94305V4.40019M3.30039 4.40019C2.80539 4.40019 2.40039 4.81162 2.40039 5.31448V9.88591C2.40039 10.3888 2.80539 10.8002 3.30039 10.8002H8.70039C9.19539 10.8002 9.60039 10.3888 9.60039 9.88591V5.31448C9.60039 4.81162 9.19539 4.40019 8.70039 4.40019M3.30039 4.40019H8.70039"
                            stroke="white"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                          />
                        </svg>
                        Upgrade To Unlock
                      </button>
                      <div className="text-xl mt-3">
                        <a
                          href="https://support.convertflow.com/en/articles/2514576-discovering-your-lead-generation-insights"
                          target="_blank"
                        >
                          Learn more →
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebsiteCustomFields;
