import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";
import VariantSettingsTwoTap from "./VariantSettingsTwoTap";
import VariantBuilderPanelTitle from "../variants/VariantBuilderPanelTitle";

const VariantSettings = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let website = contextData.objects.website;
  let cta = contextData.objects.cta;
  let variant = contextData.objects.variant;
  let current_user = contextData.objects.current_user;

  let field_slugs = [];

  let customFields = [];

  let cfFields = {
    label: "Saved Custom Fields",
    options: [
      {
        value: "",
        label: "Select a custom field",
        isDisabled: true,
      }
    ],
  };

  const allFields = [
    ...Object.values(website.fields),
    ...Object.values(variant.fields),
  ];

  Object.values(allFields).forEach(function (field) {
    if (
      field.slug !== "" &&
      cfFields.options.filter((option) => option.value == field.slug)[0] ==
        undefined &&
      (!field.service ||
        !website.integrations ||
        Object.values(website.integrations).map(function (integration) {
          if (integration.integration_type == field.service) return integration;
        }).length == 0)
    ) {
      cfFields.options.push({
        value: field.slug,
        label: field.slug,
        icon: contextData.logo,
      });

      field_slugs.push({
        value: field.slug,
        label: field.slug,
        group: "ConvertFlow Custom Fields",
      });
    }
  });

  if (
    variant.score_field &&
    cfFields.options.filter((field_slug) => field_slug.value == variant.score_field)
      .length == 0
  ) {
    cfFields.options.push({
      value: variant.score_field,
      label: variant.score_field,
    });
  }

  cfFields.options.push({
    label: "New custom field",
    value: "New Field",
    icon: contextData.dark_mode
      ? `/assets/add-icon-white.svg`
      : `/assets/add-icon.svg`,
  });

  customFields.push(cfFields);

  Object.values(website.integrations)
    .filter((integration) => integration.features.includes("contact"))
    .sort((a, b) => a.integration_type.localeCompare(b.integration_type))
    .forEach(function (integration) {
      let serviceFields = {
        label: integration.integration_type,
        options: [],
      };

      if (integration.api_data["properties"]) {
        Object.values(integration.api_data["properties"]).forEach(function (
          property
        ) {
          let property_option = {
            value: property.id,
            label: property.name,
            plan_tier: integration.plan_tier,
            icon: `/assets/integrations/${
              integration.icon ? integration.icon.split("/").pop() : null
            }`,
          };

          property_option.integration_id = integration.id;
          property_option.property_id = property.id;
          if (property.options !== undefined) {
            property_option.field_type = "select";
          } else {
            property_option.field_type = "text";
          }

          serviceFields.options.push(property_option);
          field_slugs.push(property_option);
        });
      } else {
        serviceFields.options.push({
          label: `${integration.integration_type} automatically receives ConvertFlow's saved custom fields. Save fields above!`,
          isDisabled: true,
          icon: `/assets/integrations/${
            integration.icon ? integration.icon.split("/").pop() : null
          }`,
        });
      }
      customFields.push(serviceFields);
    });

  function slugInputCallback(new_value, old_value) {
    if (new_value !== "New Field") {
      let formatted_value = new_value.replace(/[^a-z0-9+]+/gi, "_");

      let changesToRegister = [];

      changesToRegister.push({
        object_type: "variants",
        setting_name: "[score_field]",
        value: formatted_value,
        old_value:
          old_value && new_value !== old_value ? old_value : "New Field",
      });

      builder.update(changesToRegister);
    }
  }

  function slugDropdownCallback(new_value, old_value) {
    if (new_value == "New Field") {
      builder.update([
        {
          object_type: "variants",
          setting_name: "[score_field]",
          value: "New Field",
          skip_history: true,
        },
        {
          object_type: "variants",
          setting_name: "[service]",
          value: "",
          skip_history: true,
        },
      ]);
    } else {
      let service = "";

      let fields = field_slugs.filter(
        (field_slug) => field_slug.value == new_value
      );

      let field_slug = fields
        ? fields.filter((field) => field.integration_id)[0] || fields[0]
        : undefined;

      if (field_slug && field_slug.integration_id) {
        let integration = website.integrations[field_slug.integration_id];
        if (integration) {
          service = integration.integration_type;
        }
      }

      let changesToRegister = [];
      changesToRegister.push(
        {
          object_type: "variants",
          setting_name: "[score_field]",
          value: new_value,
          old_value:
            old_value && new_value !== old_value ? old_value : "New Field",
        },
        {
          object_type: "variants",
          setting_name: "[service]",
          value: service,
        }
      );

      builder.update(changesToRegister);
    }
  }

  return (
    <React.Fragment>
      <div className="w-full">
        <VariantBuilderPanelTitle
          title={`${
            cta.cta_type == "inline"
              ? "Embed Campaign"
              : cta.cta_type == "overlay"
              ? "Overlay Popup"
              : cta.cta_type == "hook"
              ? "Hook Popup"
              : cta.cta_type == "page"
              ? "Landing Page"
              : cta.cta_type == "bar"
              ? "Sticky Bar"
              : ""
          } Settings`}
        />
        {["overlay", "hook", "bar"].includes(cta.cta_type) && (
          <React.Fragment>
            <VariantBuilderPanelCollapse
              name={
                cta.cta_type !== "bar" ? "Popup Trigger" : "Sticky Bar Trigger"
              }
              open={true}
            >
              <VariantBuilderSetting
                object={variant}
                setting_name="[options][trigger_type]"
                setting_type="dropdown"
                object_type="variants"
                label="Trigger Type"
                options={[
                  ...[
                    { value: "", text: "Select trigger type", disabled: true },
                    { value: "exit", text: "Exit-Intent" },
                    { value: "scroll", text: "Scroll Point" },
                    { value: "delayed", text: "Time Delayed" },
                    { value: "click", text: "Upon Click Only" },
                    { value: "prehook", text: "On-site Message" },
                    { value: "two-tap", text: "Two-tap Floating Button" },
                    { value: "cart", text: "Add To Cart (Shopify)" },
                  ],
                ]}
                value={
                  variant.options["trigger_type"]
                    ? variant.options["trigger_type"]
                    : ""
                }
                condition={
                  cta.cta_type == "overlay" ||
                  cta.cta_type == "hook" ||
                  cta.cta_type == "bar"
                }
                feature_gate_type="Targeting – Site activity"
              />

              {["two-tap", "prehook"].includes(
                variant.options["trigger_type"]
              ) && (
                <VariantSettingsTwoTap
                  {...props}
                  cta={cta}
                  variant={variant}
                  current_user={current_user}
                />
              )}

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][delay]"
                setting_type="number"
                object_type="variants"
                label="Seconds before triggering"
                value={
                  variant.options["delay"] ? variant.options["delay"] : "3"
                }
                condition={
                  (cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                  (variant.options["trigger_type"] == "delayed" ||
                    (["two-tap", "prehook"].includes(
                      variant.options["trigger_type"]
                    ) &&
                      variant.options["prehook-trigger-type"] == "delayed"))
                }
                min={0}
                max={10000}
              />

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][scroll]"
                setting_type="range"
                object_type="variants"
                label={"Trigger percentage"}
                value={
                  variant.options["scroll"] ? variant.options["scroll"] : "10"
                }
                condition={
                  ((cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                    variant.options["trigger_type"] == "scroll") ||
                  (["two-tap", "prehook"].includes(
                    variant.options["trigger_type"]
                  ) &&
                    variant.options["prehook-trigger-type"] == "scroll")
                }
                min={1}
                max={100}
              />

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][aggressive]"
                setting_type="dropdown"
                object_type="variants"
                label="Trigger suppression"
                value={
                  variant.options["aggressive"]
                    ? variant.options["aggressive"]
                    : "true"
                }
                options={[
                  { value: "true", text: "Always trigger" },
                  { value: "minutes", text: "Hide for X minutes after close" },
                  { value: "false", text: "Hide for X days after close" },
                ]}
                condition={
                  (cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                  [
                    "delayed",
                    "scroll",
                    "exit",
                    "prehook",
                    "two-tap",
                    "cart",
                  ].includes(variant.options["trigger_type"])
                }
              />

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][hide]"
                setting_type="number"
                object_type="variants"
                label="Number of days to hide"
                value={variant.options["hide"] ? variant.options["hide"] : "1"}
                condition={
                  (cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                  variant.options["aggressive"] == "false" &&
                  [
                    "delayed",
                    "scroll",
                    "exit",
                    "prehook",
                    "two-tap",
                    "cart",
                  ].includes(variant.options["trigger_type"])
                }
                min={0}
                max={10000}
              />

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][minutes-hide]"
                setting_type="number"
                object_type="variants"
                label="Number of minutes to hide"
                value={
                  variant.options["minutes-hide"]
                    ? variant.options["minutes-hide"]
                    : "30"
                }
                condition={
                  (cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                  variant.options["aggressive"] == "minutes" &&
                  [
                    "delayed",
                    "scroll",
                    "exit",
                    "prehook",
                    "two-tap",
                    "cart",
                  ].includes(variant.options["trigger_type"])
                }
                min={0}
                max={10000}
              />

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][trigger-mobile-exit]"
                setting_type="checkbox"
                object_type="variants"
                label="Mobile exit intent detection"
                value={
                  variant.options["trigger-mobile-exit"]
                    ? variant.options["trigger-mobile-exit"]
                    : "true"
                }
                condition={
                  (cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                  variant.options["trigger_type"] == "exit"
                }
              />

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][prehook-trigger-on-close]"
                setting_type="checkbox"
                object_type="variants"
                label="Show two-tap button on close"
                value={
                  variant.options["prehook-trigger-on-close"]
                    ? variant.options["prehook-trigger-on-close"]
                    : "false"
                }
                condition={
                  (cta.cta_type == "overlay" ||
                    cta.cta_type == "hook" ||
                    cta.cta_type == "bar") &&
                  variant.options["trigger_type"] !== "prehook"
                }
                tooltip="Enabling this will keep a floating two-tap button visible after closing the campaign, until the visitor closes the two-tap button"
                col="12"
              />

              {(cta.cta_type == "overlay" ||
                cta.cta_type == "hook" ||
                cta.cta_type == "bar") &&
                ["two-tap", "prehook"].includes(
                  variant.options["trigger_type"]
                ) == false &&
                variant.options["prehook-trigger-on-close"] == "true" && (
                  <VariantSettingsTwoTap
                    {...props}
                    cta={cta}
                    variant={variant}
                    current_user={current_user}
                  />
                )}

              <VariantBuilderSetting
                object={variant}
                setting_name="[options][trigger_class]"
                setting_type="message"
                object_type="variants"
                label="Custom Click Trigger Class"
                value={"cta-" + cta.id + "-trigger"}
                condition={
                  cta.cta_type == "overlay" ||
                  cta.cta_type == "hook" ||
                  cta.cta_type == "bar"
                }
                tooltip="Need to trigger this popup upon clicking a custom HTML element on your site? Add this class to anything you want to trigger this popup upon click"
              />
            </VariantBuilderPanelCollapse>
          </React.Fragment>
        )}

        <VariantBuilderPanelCollapse name="Funnel Settings" open={false}>
          <VariantBuilderSetting
            object={variant}
            label="Funnel Progress Scrolls Top"
            setting_name="[options][scroll-to-top]"
            setting_type="checkbox"
            object_type="variants"
            value={
              variant.options["scroll-to-top"]
                ? variant.options["scroll-to-top"]
                : true
            }
            condition={cta.cta_type == "page"}
          />

          <VariantBuilderSetting
            object={variant}
            label="Starting Funnel Score"
            setting_name="[score]"
            setting_type="range"
            object_type="variants"
            value={variant.score + "" ? variant.score : 0}
            min={-1000}
            max={1000}
            step={1}
            tooltip={"All score calculations start from this number"}
          />

          <VariantBuilderSetting
            object={variant}
            label="Map funnel score to custom field"
            setting_name="[score_field]"
            setting_type="select"
            object_type="variants"
            value={variant.score_field ? variant.score_field : null}
            options={customFields}
            skip_history={true}
            callback={slugDropdownCallback}
            tooltip="Save the score accumulated to a custom field"
          />

          <VariantBuilderSetting
            object={variant}
            label="New field data name"
            setting_name="[score_field]"
            setting_type="text"
            object_type="variants"
            value=""
            placeholder="field_data_name"
            condition={variant.score_field == "New Field"}
            skip_history={true}
            callback={slugInputCallback}
            snakecase={true}
          />
        </VariantBuilderPanelCollapse>

        <VariantBuilderPanelCollapse name="Additional" open={false}>
          <VariantBuilderSetting
            object={variant}
            setting_name="[options][cart-value-threshold]"
            setting_type="number"
            object_type="variants"
            label="Cart Value Offer Threshold"
            value={variant.options["cart-value-threshold"]}
            placeholder="50"
            min="0"
            allow_empty={true}
            tooltip="This value overrides this website's cart value offer threshold for just this campaign. Leave empty to use site-wide threshold."
          />
        </VariantBuilderPanelCollapse>

        <VariantBuilderPanelCollapse name="Custom Code" open={false}>
          <VariantBuilderSetting
            object={variant}
            setting_name="[options][script]"
            setting_type="code"
            label="Custom Javascript & CSS"
            object_type="variants"
            value={variant.options["script"] ? variant.options["script"] : ""}
            feature_gate_type="Custom HTML"
            tooltip={`Fires after ${
              cta.cta_type == "page" ? "landing page" : "campaign"
            } displays. Javascript must have &lt;script&gt; tags, scripts will run asynchronous. CSS must have &lt;style&gt; tags.`}
          />
        </VariantBuilderPanelCollapse>
      </div>
    </React.Fragment>
  );
};

export default VariantSettings;
