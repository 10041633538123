import React from "react";
import PropTypes from "prop-types";

const ElementFormButton = (props) => {
  let element = props.element;
  let options = element.options;
  let important = props.builder !== true ? " !important" : "";
  const classes = style.classes(element);
  const stylesheet = style.stylesheet(
    props.builder,
    props.device,
    props.cta,
    element
  );

  return (
    <React.Fragment>
      <div className={classes.outer} data-element-id={element.id}>
        <button
          className={classes.inner}
          data-element-id={element.id}
          dangerouslySetInnerHTML={{
            __html:
              props.builder == true
                ? "<div class='froala-editor'>" + element.html + "</div>"
                : element.html,
          }}
        ></button>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      </div>
    </React.Fragment>
  );
};

const style = {
  classes: (element) => {
    let options = element.options;
    let outer = "cf-button-outer";
    let inner =
      "cf-button-inner cf-button cf-button-primary cf-text-element cf-button";

    outer +=
      " " +
      (options["button-width"] && options["button-width"] !== "custom"
        ? options["button-width"]
        : !options["button-width"]
        ? "cf-full-width"
        : "");

    inner +=
      " " +
      (options["button-width"] && options["button-width"] !== "custom"
        ? options["button-width"]
        : "cf-full-width");

    outer +=
      " " + (options["button-alignment"] ? options["button-alignment"] : "");

    outer += " " + (options["button-radius"] ? options["button-radius"] : "");

    inner +=
      " " +
      (options["button-size"] ? options["button-size"] : "cf-button-small");

    return {
      outer: outer,
      inner: inner,
    };
  },

  stylesheet: (builder, device, cta, element) => {
    let options = style.backport(element);

    let borderRadius = "";
    if (options["button-radius"] == "cf-not-rounded") {
      borderRadius = "0px";
    }
    if (options["button-radius"] == "cf-slightly-rounded") {
      borderRadius = "4px";
    }
    if (options["button-radius"] == "cf-fully-rounded") {
      borderRadius = "100px";
    }
    if (options["button-radius"] == "custom") {
      borderRadius = "";
      ["top-left", "top-right", "bottom-right", "bottom-left"].forEach(
        (side) => {
          borderRadius += `${
            _.isNil(options["button-radius-" + side]) == false
              ? options["button-radius-" + side]
              : "4"
          }px `;
        }
      );
    }

    let fontWeight;
    if (
      options["button-font-weight"] &&
      options["button-font-weight"] !== "theme"
    ) {
      fontWeight = options["button-font-weight"];
    }

    let fontSize;
    if (options["button-font-size"]) {
      fontSize = options["button-font-size"];
    }

    let boxShadow;
    let boxShadowHover;

    if (options["button-shadow"] == "soft") {
      boxShadow = "0 5px 15px 0 rgb(179 179 179 / 78%)";
    }

    if (options["button-shadow"] == "hard") {
      boxShadow = "-5px 5px 0 0 rgb(0 0 0 / 10%)";
    }

    if (options["button-shadow"] == "custom") {
      boxShadow = "";
      boxShadow +=
        (element.options["button-shadow-horizontal-distance"]
          ? `${element.options["button-shadow-horizontal-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["button-shadow-vertical-distance"]
          ? `${element.options["button-shadow-vertical-distance"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["button-shadow-blur-radius"]
          ? `${element.options["button-shadow-blur-radius"]}px`
          : "0px") + " ";

      boxShadow +=
        (element.options["button-shadow-spread-radius"]
          ? `${element.options["button-shadow-spread-radius"]}px`
          : "0px") + " ";

      boxShadowHover =
        boxShadow +
        (element.options["button-hover-shadow-color"]
          ? element.options["button-hover-shadow-color"]
          : element.options["button-shadow-color"]
          ? element.options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)");

      boxShadow +=
        (element.options["button-shadow-color"]
          ? element.options["button-shadow-color"]
          : "rgba(0,0,0, 0.35)") + " ";

      boxShadow +=
        element.options["button-shadow-inset"] == "true" ? "inset" : "";
    }

    // prettier-ignore
    const desktopStyles = 
`#cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] button.cf-button-inner {
  ${options["button-color"] ? 'background: ' + options["button-color"] + '!important;' : ''}
  ${options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
  ${options["button-width"] == 'custom' && options["button-width-value"] ? 'min-width: ' + options["button-width-value"] + 'px !important;' : ''}
  ${options["button-size"] == 'custom' && options["button-size-value"] ? 'padding: ' + options["button-size-value"] + 'px 20px !important;' : ''}
  ${options["button-border-style"] ? 'border-style: ' + options["button-border-style"] + ' !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width"]) == false ? 'border-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-left"]) == false ? 'border-left-width: ' + options["button-border-width-left"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-left-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-right"]) == false ? 'border-right-width: ' + options["button-border-width-right"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-right-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-top"]) == false ? 'border-top-width: ' + options["button-border-width-top"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-top-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && _.isNil(options["button-border-width-bottom"]) == false ? 'border-bottom-width: ' + options["button-border-width-bottom"] + 'px !important;' : _.isNil(options["button-border-width"]) == false ? 'border-bottom-width: ' + options["button-border-width"] + 'px !important;' : ''}
  ${options["button-border-style"] !== "" && options["button-border-color"] ? 'border-color: ' + options["button-border-color"] + ' !important;' : options["button-color"] ? 'border-color: ' + options["button-color"] + ' !important;' : ''}
  ${borderRadius ? 'border-radius: ' + borderRadius + ' !important;' : ''}
  ${boxShadow ? 'box-shadow: ' + boxShadow + ' !important;' : ''}
}

#cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] .cf-button-inner:hover, #cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] .cf-button-inner:active {
  ${options["button–hover-color"] ? 'background: ' + options["button–hover-color"] + ' !important;' : options["button-color"] ? 'background: ' + options["button-color"] + ' !important;' : ''}
  ${options["button–hover-text-color"] ? 'color: ' + options["button–hover-text-color"] + ' !important;' : options["button-text-color"] ? 'color: ' + options["button-text-color"] + ' !important;' : ''}
  ${options["button-hover-border-color"] ? 'border-color: ' + options["button-hover-border-color"] + ' !important;' : options["button-border-color"] ? 'border-color: ' +  options["button-border-color"] + ' !important;' : ''}
  ${boxShadowHover ? 'box-shadow: ' + boxShadowHover + ' !important;' : ''}
}

#cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] .cf-text-element {
  font-family: ${options["button-font"] ? options["button-font"] : ''} !important;
}

#cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] .cf-text-element p, #cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] button.cf-text-element, #cta_${cta.id} .cf-form-2.cf-form-theme-default.cf-form-horizontal .cf-button-outer[data-element-id="${element.id}"] button.cf-text-element p {
  ${fontWeight ? `font-weight: ${fontWeight} !important;` : ''}
  ${fontSize ? `font-size: ${fontSize}px !important;` : ''}
}
`;

    let fontSizeMobile;
    if (options["button-font-size-mobile"]) {
      fontSizeMobile = options["button-font-size-mobile"];
    }

    // prettier-ignore
    const mobileStyles = 
`/* mobile styles */
#cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] .cf-text-element p, #cta_${cta.id} .cf-form-2.cf-form-theme-default.cf-form-horizontal .cf-button-outer[data-element-id="${element.id}"] button.cf-text-element p {
  ${fontSizeMobile ? `font-size: ${fontSizeMobile}px !important;` : ''}
}

#cta_${cta.id} .cf-button-outer[data-element-id="${element.id}"] button.cf-button-inner {
  ${options["button-width"] == 'custom' && options["button-width-value-mobile"] ? 'min-width: ' + options["button-width-value-mobile"] + 'px !important;' : ''}
}

`;

    if (builder !== true) {
      return desktopStyles + "@media (max-width: 599px) {" + mobileStyles + "}";
    } else {
      if (device == "desktop") {
        return desktopStyles;
      } else {
        return desktopStyles + mobileStyles;
      }
    }
  },

  // converts old database values to current values
  backport: (element) => {
    let options = { ...element.options };

    let buttonBorderWidth = options["button-border-width"];
    if (buttonBorderWidth && buttonBorderWidth.indexOf("px") > -1) {
      options["button-border-width"] = buttonBorderWidth.replace("px", "");
    }

    return options;
  },
};

export default ElementFormButton;