import React, { useContext } from "react";
import { VariantContextData } from "../variants/VariantBuilderContext";
import VariantBuilderSetting from "../variants/VariantBuilderSetting";
import VariantBuilderPanelCollapse from "../variants/VariantBuilderPanelCollapse";

const ElementSettingsProductsDesign = (props) => {
  const [contextData, builder, setContextData] = useContext(VariantContextData);
  let variant = contextData.objects.variant;
  let element = builder.selected().object;

  return (
    <React.Fragment>
      <VariantBuilderPanelCollapse name="Products Feed" open={false}>
        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-width]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Container maximum width"}
          value={
            element.options["products-item-width"]
              ? element.options["products-item-width"]
              : undefined
          }
          placeholder={
            variant.options["products-item-width"]
              ? variant.options["products-item-width"]
              : 2560
          }
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="desktop"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-width-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Container maximum width"}
          value={
            element.options["products-item-width-mobile"]
              ? element.options["products-item-width-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-item-width-mobile"]
              ? variant.options["products-item-width-mobile"]
              : element.options["products-item-width-mobile"]
              ? element.options["products-item-width-mobile"]
              : undefined
          }
          min={0}
          max={2560}
          range_step={"1"}
          allow_empty={true}
          device="mobile"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-layout]"
          setting_type="layout"
          label="Products Layout"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-layout"]
              ? element.options["products-layout"]
              : "grid"
          }
          valueMappings={{
            responsive: "grid",
            stacked: "list",
          }}
          condition={element.element_type == "products"}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-padding]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products outer padding"}
          value={
            element.options["products-padding"]
              ? element.options["products-padding"]
              : undefined
          }
          placeholder={variant.options["products-padding"]}
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-padding-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products outer padding"}
          value={
            element.options["products-padding-mobile"]
              ? element.options["products-padding-mobile"]
              : undefined
          }
          placeholder={variant.options["products-padding-mobile"]}
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-padding]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products inner padding"}
          value={
            element.options["products-item-padding"]
              ? element.options["products-item-padding"]
              : undefined
          }
          placeholder={
            variant.options["products-item-padding"]
              ? variant.options["products-item-padding"]
              : 15
          }
          show_reset={true}
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-padding-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products inner padding"}
          value={
            element.options["products-item-padding-mobile"]
              ? element.options["products-item-padding-mobile"]
              : undefined
          }
          placeholder={
            element.options["products-item-padding"]
              ? element.options["products-item-padding"]
              : variant.options["products-item-padding-mobile"]
              ? variant.options["products-item-padding-mobile"]
              : 15
          }
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-margin-vertical]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products vertical spacing"}
          value={
            element.options["products-margin-vertical"]
              ? element.options["products-margin-vertical"]
              : undefined
          }
          placeholder={
            variant.options["products-margin-vertical"]
              ? variant.options["products-margin-vertical"]
              : 0
          }
          min={0}
          max={100}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-margin-vertical-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products vertical spacing"}
          value={
            element.options["products-margin-vertical-mobile"]
              ? element.options["products-margin-vertical-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-margin-vertical-mobile"]
              ? variant.options["products-margin-vertical-mobile"]
              : 0
          }
          min={0}
          max={100}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-content-type-spacing]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products Content Spacing"}
          value={
            element.options["products-content-type-spacing"]
              ? element.options["products-content-type-spacing"]
              : undefined
          }
          placeholder={
            variant.options["products-content-type-spacing"]
              ? variant.options["products-content-type-spacing"]
              : 10
          }
          min={0}
          max={500}
          range_step={"1"}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-content-type-spacing-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Products Content Spacing"}
          value={
            element.options["products-content-type-spacing-mobile"]
              ? element.options["products-content-type-spacing-mobile"]
              : undefined
          }
          placeholder={
            variant.options["products-content-type-spacing-mobile"]
              ? variant.options["products-content-type-spacing-mobile"]
              : variant.options["products-content-type-spacing"]
              ? variant.options["products-content-type-spacing"]
              : 10
          }
          min={0}
          max={500}
          range_step={"1"}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Product background color"
          setting_name="[options][products-item-background-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-background-color"]
              ? element.options["products-item-background-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-background-color"]
              ? variant.options["products-item-background-color"]
              : ""
          }
          show_reset={true}
          gradient={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Product border style"
          setting_name="[options][products-item-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-border-style"]
              ? element.options["products-item-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Product border color"
          setting_name="[options][products-item-border-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-border-color"]
              ? element.options["products-item-border-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-border-color"]
              ? variant.options["products-item-border-color"]
              : "#000"
          }
          condition={["dotted", "dashed", "solid"].includes(
            element.options["products-item-border-style"]
          )}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Product Corner Radius"
          setting_name="[options][products-item-radius]"
          setting_type="border_radius"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-radius"]
              ? element.options["products-item-radius"]
              : undefined
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
        />

        {element.options["products-item-radius"] == "custom" && (
          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][products-item-radius-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={element}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="elements"
                    object_id={element.id}
                    value={
                      _.isNil(
                        element.options[`products-item-radius-${side}`]
                      ) == false
                        ? element.options[`products-item-radius-${side}`]
                        : "3"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        )}

        <VariantBuilderSetting
          object={element}
          label="Products Shadow"
          setting_name="[options][products-item-shadow]"
          setting_type="shadow"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-shadow"]
              ? element.options["products-item-shadow"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["products-item-shadow-horizontal-distance"]
              ? element.options["products-item-shadow-horizontal-distance"]
              : undefined
          }
          placeholder={
            variant.options["products-item-shadow-horizontal-distance"]
          }
          condition={element.options["products-item-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["products-item-shadow-vertical-distance"]
              ? element.options["products-item-shadow-vertical-distance"]
              : undefined
          }
          placeholder={
            variant.options["products-item-shadow-vertical-distance"]
          }
          condition={element.options["products-item-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["products-item-shadow-blur-radius"]
              ? element.options["products-item-shadow-blur-radius"]
              : undefined
          }
          placeholder={variant.options["products-item-shadow-blur-radius"]}
          condition={element.options["products-item-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][products-item-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["products-item-shadow-spread-radius"]
              ? element.options["products-item-shadow-spread-radius"]
              : undefined
          }
          placeholder={variant.options["products-item-shadow-spread-radius"]}
          condition={element.options["products-item-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Shadow Color"
          setting_name="[options][products-item-shadow-color]"
          setting_type="color_v2"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-shadow-color"]
              ? element.options["products-item-shadow-color"]
              : undefined
          }
          placeholder={
            variant.options["products-item-shadow-color"]
              ? variant.options["products-item-shadow-color"]
              : "rgba(0,0,0, 0.35)"
          }
          condition={element.options["products-item-shadow"] == "custom"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Inset shadow position"
          setting_name="[options][products-item-shadow-inset]"
          setting_type="checkbox"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["products-item-shadow-inset"]
              ? element.options["products-item-shadow-inset"]
              : "false"
          }
          condition={element.options["products-item-shadow"] == "custom"}
        />
      </VariantBuilderPanelCollapse>

      <VariantBuilderPanelCollapse name="Content Labels" open={false}>
        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label={`Label Font`}
          setting_name="[options][products-content-label-font-family]"
          setting_type="select"
          object_type="elements"
          value={element.options["products-content-label-font-family"]}
          option_groups={["System fonts", "Google fonts"]}
          options={[
            { value: "", text: "Default" },
            ...window.builder_fonts_dropdown,
          ]}
          col="8"
          callback={(new_value, old_value) => {
            ctas.builder.fonts.load_font_family(new_value);
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          setting_name="[options][products-content-label-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          value={element.options["products-content-label-font-weight"]}
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label={`Label Font Size`}
          setting_name="[options][products-content-label-font-size]"
          setting_type="range"
          object_type="elements"
          value={element.options["products-content-label-font-size"]}
          placeholder={
            variant.options["products-content-label-font-size"] || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label={`Label Font Size`}
          setting_name="[options][products-content-label-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          value={element.options["products-content-label-font-size-mobile"]}
          placeholder={
            variant.options["products-content-label-font-size-mobile"] || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label={`Label line height`}
          setting_name="[options][products-content-label-line-height]"
          setting_type="range"
          object_type="elements"
          value={element.options["products-content-label-line-height"]}
          placeholder={variant.options["products-content-label-line-height"]}
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label={`Label line height`}
          setting_name="[options][products-content-label-line-height-mobile]"
          setting_type="range"
          object_type="elements"
          value={element.options["products-content-label-line-height-mobile"]}
          placeholder={
            variant.options["products-content-label-line-height-mobile"]
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          object_id={element.id}
          label={`Label Font color`}
          setting_name="[options][products-content-label-font-color]"
          setting_type="color_v2"
          object_type="elements"
          value={element.options["products-content-label-font-color"]}
          placeholder={
            variant.options["products-content-label-font-color"] || "#000"
          }
          show_reset={true}
        />
      </VariantBuilderPanelCollapse>

      {element.options["image-show"] !== "false" && (
        <VariantBuilderPanelCollapse name="Images" open={false}>
          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-image-height]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            label={"Products image height"}
            value={
              element.options["products-item-image-height"]
                ? element.options["products-item-image-height"]
                : undefined
            }
            placeholder={variant.options["products-item-image-height"]}
            min={0}
            max={500}
            range_step={"1"}
            device="desktop"
            allow_empty={true}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-image-height-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            label={"Products image height"}
            value={
              element.options["products-item-image-height-mobile"]
                ? element.options["products-item-image-height-mobile"]
                : undefined
            }
            placeholder={variant.options["products-item-image-height-mobile"]}
            min={0}
            max={500}
            range_step={"1"}
            device="mobile"
            allow_empty={true}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-carousel-image-height]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            label={"Carousel image height"}
            value={
              element.options["products-carousel-image-height"]
                ? element.options["products-carousel-image-height"]
                : undefined
            }
            placeholder={
              variant.options["products-carousel-image-height"] || 50
            }
            condition={
              element.options["products-images-to-show"] == "carousel" &&
              element.options["products-source"] == "ShopifyGraphql"
            }
            min={0}
            max={500}
            range_step={"1"}
            device="desktop"
            allow_empty={true}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-carousel-image-height-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            label={"Carousel image height"}
            value={
              element.options["products-carousel-image-height-mobile"]
                ? element.options["products-carousel-image-height-mobile"]
                : undefined
            }
            placeholder={
              variant.options["products-carousel-image-height-mobile"] || 50
            }
            condition={
              element.options["products-images-to-show"] == "carousel" &&
              element.options["products-source"] == "ShopifyGraphql"
            }
            min={0}
            max={500}
            range_step={"1"}
            device="mobile"
            allow_empty={true}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-image-width]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            label={"Products image width"}
            placeholder={variant.options["products-item-image-width"]}
            value={
              element.options["products-item-image-width"]
                ? element.options["products-item-image-width"]
                : undefined
            }
            min={0}
            max={1200}
            range_step={"1"}
            device="desktop"
            allow_empty={true}
            condition={element.options["products-layout"] === "list"}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-image-width-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            label={"Products image width"}
            placeholder={variant.options["products-item-image-width-mobile"]}
            value={
              element.options["products-item-image-width-mobile"]
                ? element.options["products-item-image-width-mobile"]
                : undefined
            }
            min={0}
            max={1200}
            range_step={"1"}
            device="mobile"
            allow_empty={true}
            condition={element.options["products-layout"] === "list"}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Image Corner Rounding"
            setting_name="[options][products-image-corner-rounding]"
            setting_type="border_radius"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-corner-rounding"]
                ? element.options["products-image-corner-rounding"]
                : undefined
            }
            valueMappings={{
              none: "cf-not-rounded",
              some: "cf-slightly-rounded",
              full: "cf-fully-rounded",
              custom: "custom",
            }}
            show_reset={true}
          />

          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][products-image-corner-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={element}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="elements"
                    object_id={element.id}
                    value={
                      _.isNil(
                        element.options[`products-image-corner-${side}`]
                      ) == false
                        ? element.options[`products-image-corner-${side}`]
                        : undefined
                    }
                    condition={
                      element.options["products-image-corner-rounding"] ==
                      "custom"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>

          <VariantBuilderSetting
            object={element}
            label="Image Badge Background"
            setting_name="[options][products-image-badge-background]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-background"]
                ? element.options["products-image-badge-background"]
                : undefined
            }
            placeholder={
              variant.options["products-image-badge-background"]
                ? variant.options["products-image-badge-background"]
                : "#003FFF"
            }
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Badge Font Family"
            setting_name="[options][products-image-badge-font-family]"
            setting_type="select"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-font-family"]
                ? element.options["products-image-badge-font-family"]
                : undefined
            }
            placeholder={
              variant.options["products-image-badge-font-family"]
                ? variant.options["products-image-badge-font-family"]
                : undefined
            }
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            option_groups={["System fonts", "Google fonts"]}
            options={[
              {
                value: "",
                text: "Theme font",
                group: "System fonts",
              },
              ...window.builder_fonts_dropdown,
            ]}
            callback={ctas.builder.fonts.load_font_family}
            col="8"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-image-badge-font-family-weight]"
            setting_type="font_weight"
            label="Weight"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-font-family-weight"]
                ? element.options["products-image-badge-font-family-weight"]
                : undefined
            }
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            col="4"
            classNames="no-padding-left"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge Font Size"
            setting_name="[options][products-image-badge-font-size]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-font-size"]
                ? element.options["products-image-badge-font-size"]
                : undefined
            }
            placeholder={
              variant.options["products-image-badge-font-size"]
                ? variant.options["products-image-badge-font-size"]
                : "14"
            }
            allow_empty={true}
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            min={0}
            max={100}
            range_step={1}
            device="desktop"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge Font Size"
            setting_name="[options][products-image-badge-font-size-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-font-size-mobile"]
                ? element.options["products-image-badge-font-size-mobile"]
                : undefined
            }
            placeholder={
              variant.options["products-image-badge-font-size-mobile"]
                ? variant.options["products-image-badge-font-size-mobile"]
                : "14"
            }
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            allow_empty={true}
            min={0}
            max={100}
            range_step={1}
            device="mobile"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge Text Color"
            setting_name="[options][products-image-badge-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-text-color"]
                ? element.options["products-image-badge-text-color"]
                : undefined
            }
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            placeholder={
              variant.options["products-image-badge-text-color"]
                ? variant.options["products-image-badge-text-color"]
                : "#FFFFFF"
            }
          />

          <VariantBuilderSetting
            object={element}
            label="Image Badge Corner Rounding"
            setting_name="[options][products-image-badge-corner-rounding]"
            setting_type="border_radius"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-image-badge-corner-rounding"]
                ? element.options["products-image-badge-corner-rounding"]
                : undefined
            }
            valueMappings={{
              none: "cf-not-rounded",
              some: "cf-slightly-rounded",
              full: "cf-fully-rounded",
              custom: "custom",
            }}
            condition={
              element.options["products-show-image-badge"] == "true" &&
              element.element_type !== "product_hero"
            }
            show_reset={true}
          />

          <div className="col-xs-12">
            {["top-left", "top-right", "bottom-right", "bottom-left"].map(
              (side) => {
                const settingName = `[options][products-image-badge-corner-${side}]`;
                return (
                  <VariantBuilderSetting
                    key={settingName}
                    object={element}
                    setting_name={settingName}
                    setting_type="number"
                    object_type="elements"
                    object_id={element.id}
                    value={
                      _.isNil(
                        element.options[`products-image-badge-corner-${side}`]
                      ) == false
                        ? element.options[`products-image-badge-corner-${side}`]
                        : undefined
                    }
                    condition={
                      element.options["products-image-badge-corner-rounding"] ==
                        "custom" &&
                      element.options["products-show-image-badge"] == "true"
                    }
                    min={0}
                    note={capitalizeFirstLetter(side).replace("-", " ")}
                    col="3"
                    placeholder="0px"
                    classNames="spacing-inputs"
                  />
                );
              }
            )}
          </div>
        </VariantBuilderPanelCollapse>
      )}

      {element.options["reviews-show"] == "true" &&
        element.options["products-source"] == "ShopifyGraphql" && (
          <VariantBuilderPanelCollapse name="Reviews" open={false}>
            <VariantBuilderSetting
              object={element}
              label="Reviews Star Color"
              setting_name="[options][products-reviews-star-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-star-color"]
                  ? element.options["products-reviews-star-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-star-color"]
                  ? variant.options["products-reviews-star-color"]
                  : "#EFAB40"
              }
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Reviews Star Size"
              setting_name="[options][products-reviews-star-size]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-star-size"]
                  ? element.options["products-reviews-star-size"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-star-size"]
                  ? variant.options["products-reviews-star-size"]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="desktop"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Reviews Star Size"
              setting_name="[options][products-reviews-star-size-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-star-size-mobile"]
                  ? element.options["products-reviews-star-size-mobile"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-star-size-mobile"]
                  ? variant.options["products-reviews-star-size-mobile"]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="mobile"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Reviews Font Family"
              setting_name="[options][products-reviews-font-family]"
              setting_type="select"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-font-family"]
                  ? element.options["products-reviews-font-family"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-font-family"]
                  ? variant.options["products-reviews-font-family"]
                  : undefined
              }
              option_groups={["System fonts", "Google fonts"]}
              options={[
                {
                  value: "",
                  text: "Theme font",
                  group: "System fonts",
                },
                ...window.builder_fonts_dropdown,
              ]}
              callback={ctas.builder.fonts.load_font_family}
              col="8"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-reviews-font-weight]"
              setting_type="font_weight"
              label="Weight"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-font-weight"]
                  ? element.options["products-reviews-font-weight"]
                  : undefined
              }
              col="4"
              classNames="no-padding-left"
            />

            <VariantBuilderSetting
              object={element}
              label="Reviews Font Size"
              setting_name="[options][products-reviews-font-size]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-font-size"]
                  ? element.options["products-reviews-font-size"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-font-size"]
                  ? variant.options["products-reviews-font-size"]
                  : "11"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="desktop"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Reviews Font Size"
              setting_name="[options][products-reviews-font-size-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-font-size-mobile"]
                  ? element.options["products-reviews-font-size-mobile"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-font-size-mobile"]
                  ? variant.options["products-reviews-font-size-mobile"]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="mobile"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Reviews Text Color"
              setting_name="[options][products-reviews-text-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-reviews-text-color"]
                  ? element.options["products-reviews-text-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-reviews-text-color"]
                  ? variant.options["products-reviews-text-color"]
                  : "#000"
              }
              show_reset={true}
            />
          </VariantBuilderPanelCollapse>
        )}

      {element.options["title-show"] !== "false" && (
        <VariantBuilderPanelCollapse name="Title" open={false}>
          <VariantBuilderSetting
            object={element}
            label="Products title font"
            setting_name="[options][products-item-title-font]"
            setting_type="select"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-font"]
                ? element.options["products-item-title-font"]
                : undefined
            }
            placeholder={
              variant.options["products-item-title-font"]
                ? variant.options["products-item-title-font"]
                : undefined
            }
            option_groups={["System fonts", "Google fonts"]}
            options={[
              {
                value: "",
                text: "Theme font",
                group: "System fonts",
              },
              ...window.builder_fonts_dropdown,
            ]}
            callback={ctas.builder.fonts.load_font_family}
            col="8"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-title-font-weight]"
            setting_type="font_weight"
            label="Weight"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-font-weight"]
                ? element.options["products-item-title-font-weight"]
                : undefined
            }
            col="4"
            classNames="no-padding-left"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products title color"
            setting_name="[options][products-item-title-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-color"]
                ? element.options["products-item-title-color"]
                : undefined
            }
            placeholder={
              variant.options["products-item-title-color"]
                ? variant.options["products-item-title-color"]
                : "#000"
            }
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products title size"
            setting_name="[options][products-item-title-size]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-size"]
                ? element.options["products-item-title-size"]
                : undefined
            }
            placeholder={
              variant.options["products-item-title-size"]
                ? variant.options["products-item-title-size"]
                : "15"
            }
            min={0}
            max={100}
            range_step={1}
            device={"desktop"}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products title size"
            setting_name="[options][products-item-title-size-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-size-mobile"]
                ? element.options["products-item-title-size-mobile"]
                : undefined
            }
            placeholder={
              variant.options["products-item-title-size-mobile"]
                ? variant.options["products-item-title-size-mobile"]
                : "13"
            }
            min={0}
            max={100}
            range_step={1}
            device="mobile"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Title Line Height"
            setting_name="[options][products-item-title-line-height]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-line-height"]
                ? element.options["products-item-title-line-height"]
                : undefined
            }
            placeholder={
              variant.options["products-item-title-line-height"]
                ? variant.options["products-item-title-line-height"]
                : "13"
            }
            allow_empty={true}
            min={0}
            max={100}
            range_step={1}
            device="desktop"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Title Line Height"
            setting_name="[options][products-item-title-line-height-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-title-line-height-mobile"]
                ? element.options["products-item-title-line-height-mobile"]
                : undefined
            }
            placeholder={
              variant.options["products-item-title-line-height-mobile"]
                ? variant.options["products-item-title-line-height-mobile"]
                : "13"
            }
            allow_empty={true}
            min={0}
            max={100}
            range_step={1}
            device="mobile"
            show_reset={true}
          />
        </VariantBuilderPanelCollapse>
      )}

      {element.options["description-show"] !== "false" && (
        <VariantBuilderPanelCollapse name="Descriptions" open={false}>
          <VariantBuilderSetting
            object={element}
            label="Description font"
            setting_name="[options][products-item-description-font]"
            setting_type="select"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-font"]
                ? element.options["products-item-description-font"]
                : undefined
            }
            placeholder={
              variant.options["products-item-description-font"]
                ? variant.options["products-item-description-font"]
                : undefined
            }
            option_groups={["System fonts", "Google fonts"]}
            options={[
              {
                value: "",
                text: "Theme font",
                group: "System fonts",
              },
              ...window.builder_fonts_dropdown,
            ]}
            callback={ctas.builder.fonts.load_font_family}
            col="8"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-description-font-weight]"
            setting_type="font_weight"
            label="Weight"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-font-weight"]
                ? element.options["products-item-description-font-weight"]
                : undefined
            }
            col="4"
            classNames="no-padding-left"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Description color"
            setting_name="[options][products-item-description-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-color"]
                ? element.options["products-item-description-color"]
                : undefined
            }
            placeholder={
              variant.options["products-item-description-color"]
                ? variant.options["products-item-description-color"]
                : "#000"
            }
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products description size"
            setting_name="[options][products-item-description-size]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-size"]
                ? element.options["products-item-description-size"]
                : undefined
            }
            placeholder={
              variant.options["products-item-description-size"]
                ? variant.options["products-item-description-size"]
                : "15"
            }
            min={0}
            max={100}
            range_step={1}
            device={"desktop"}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products description size"
            setting_name="[options][products-item-description-size-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-size-mobile"]
                ? element.options["products-item-description-size-mobile"]
                : undefined
            }
            placeholder={
              variant.options["products-item-description-size-mobile"]
                ? variant.options["products-item-description-size-mobile"]
                : "13"
            }
            min={0}
            max={100}
            range_step={1}
            device="mobile"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Description Line Height"
            setting_name="[options][products-item-description-line-height]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-line-height"]
                ? element.options["products-item-description-line-height"]
                : undefined
            }
            placeholder={
              variant.options["products-item-description-line-height"]
                ? variant.options["products-item-description-line-height"]
                : "13"
            }
            allow_empty={true}
            min={0}
            max={100}
            range_step={1}
            device="desktop"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Description Line Height"
            setting_name="[options][products-item-description-line-height-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-description-line-height-mobile"]
                ? element.options[
                    "products-item-description-line-height-mobile"
                  ]
                : undefined
            }
            placeholder={
              variant.options["products-item-description-line-height-mobile"]
                ? variant.options[
                    "products-item-description-line-height-mobile"
                  ]
                : "13"
            }
            allow_empty={true}
            min={0}
            max={100}
            range_step={1}
            device="mobile"
            show_reset={true}
          />
        </VariantBuilderPanelCollapse>
      )}
      {element.options["price-show"] !== "false" && (
        <VariantBuilderPanelCollapse name="Prices" open={false}>
          <VariantBuilderSetting
            object={element}
            label="Products price font"
            setting_name="[options][products-item-price-font]"
            setting_type="select"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-price-font"]
                ? element.options["products-item-price-font"]
                : undefined
            }
            placeholder={
              variant.options["products-item-price-font"]
                ? variant.options["products-item-price-font"]
                : undefined
            }
            option_groups={["System fonts", "Google fonts"]}
            options={[
              {
                value: "",
                text: "Theme font",
                group: "System fonts",
              },
              ...window.builder_fonts_dropdown,
            ]}
            callback={ctas.builder.fonts.load_font_family}
            col="8"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            setting_name="[options][products-item-price-font-weight]"
            setting_type="font_weight"
            show_reset={true}
            label="Weight"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-price-font-weight"]
                ? element.options["products-item-price-font-weight"]
                : undefined
            }
            col="4"
            classNames="no-padding-left"
          />

          <VariantBuilderSetting
            object={element}
            label="Price color"
            setting_name="[options][products-item-price-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-price-color"]
                ? element.options["products-item-price-color"]
                : undefined
            }
            placeholder={
              variant.options["products-item-price-color"]
                ? variant.options["products-item-price-color"]
                : "#000"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Discount color"
            setting_name="[options][products-item-discount-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-discount-color"]
                ? element.options["products-item-discount-color"]
                : undefined
            }
            placeholder={
              variant.options["products-item-discount-color"]
                ? variant.options["products-item-discount-color"]
                : "#000"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products price size"
            setting_name="[options][products-item-price-size]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-price-size"]
                ? element.options["products-item-price-size"]
                : undefined
            }
            placeholder={
              variant.options["products-item-price-size"]
                ? variant.options["products-item-price-size"]
                : "15"
            }
            min={0}
            max={100}
            range_step={1}
            device={"desktop"}
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Products price size"
            setting_name="[options][products-item-price-size-mobile]"
            setting_type="range"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["products-item-price-size-mobile"]
                ? element.options["products-item-price-size-mobile"]
                : undefined
            }
            placeholder={
              variant.options["products-item-price-size-mobile"]
                ? variant.options["products-item-price-size-mobile"]
                : "13"
            }
            min={0}
            max={100}
            range_step={1}
            device="mobile"
            show_reset={true}
          />
        </VariantBuilderPanelCollapse>
      )}

      {element.options["products-source"] == "Shopify" ||
        (element.options["variant-picker-show"] !== "false" &&
          element.options["products-source"] == "ShopifyGraphql" && (
            <VariantBuilderPanelCollapse name="Variant Picker" open={false}>
              <React.Fragment>
                <VariantBuilderSetting
                  object={element}
                  label="Options Button Minimum Width"
                  setting_name="[options][products-variant-picker-button-minimum-width]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options[
                      "products-variant-picker-button-minimum-width"
                    ]
                      ? element.options[
                          "products-variant-picker-button-minimum-width"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options[
                      "products-variant-picker-button-minimum-width"
                    ]
                      ? variant.options[
                          "products-variant-picker-button-minimum-width"
                        ]
                      : "auto"
                  }
                  condition={
                    element.options["products-variant-picker-style"] ==
                    "cf-options-buttons"
                  }
                  min={0}
                  max={1000}
                  allow_empty={true}
                  range_step={1}
                  device="desktop"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Options Button Minimum Width"
                  setting_name="[options][products-variant-picker-button-minimum-width-mobile]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options[
                      "products-variant-picker-button-minimum-width-mobile"
                    ]
                      ? element.options[
                          "products-variant-picker-button-minimum-width-mobile"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options[
                      "products-variant-picker-button-minimum-width-mobile"
                    ]
                      ? variant.options[
                          "products-variant-picker-button-minimum-width-mobile"
                        ]
                      : "auto"
                  }
                  condition={
                    element.options["products-variant-picker-style"] ==
                    "cf-options-buttons"
                  }
                  min={0}
                  max={1000}
                  allow_empty={true}
                  range_step={1}
                  device="mobile"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Font Family"
                  setting_name="[options][products-variant-picker-font-family]"
                  setting_type="select"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-font-family"]
                      ? element.options["products-variant-picker-font-family"]
                      : undefined
                  }
                  placeholder={
                    variant.options["products-variant-picker-font-family"]
                      ? variant.options["products-variant-picker-font-family"]
                      : undefined
                  }
                  option_groups={["System fonts", "Google fonts"]}
                  options={[
                    {
                      value: "",
                      text: "Theme font",
                      group: "System fonts",
                    },
                    ...window.builder_fonts_dropdown,
                  ]}
                  callback={ctas.builder.fonts.load_font_family}
                  col="8"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  setting_name="[options][products-variant-picker-font-weight]"
                  setting_type="font_weight"
                  label="Weight"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-font-weight"]
                      ? element.options["products-variant-picker-font-weight"]
                      : undefined
                  }
                  col="4"
                  classNames="no-padding-left"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Font Size"
                  setting_name="[options][products-variant-picker-font-size]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-font-size"]
                      ? element.options["products-variant-picker-font-size"]
                      : undefined
                  }
                  placeholder={
                    variant.options["products-variant-picker-font-size"]
                      ? variant.options["products-variant-picker-font-size"]
                      : "14"
                  }
                  allow_empty={true}
                  min={0}
                  max={100}
                  range_step={1}
                  device="desktop"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Font Size"
                  setting_name="[options][products-variant-picker-font-size-mobile]"
                  setting_type="range"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-font-size-mobile"]
                      ? element.options[
                          "products-variant-picker-font-size-mobile"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options["products-variant-picker-font-size-mobile"]
                      ? variant.options[
                          "products-variant-picker-font-size-mobile"
                        ]
                      : "14"
                  }
                  min={0}
                  max={100}
                  range_step={1}
                  device="mobile"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Text"
                  setting_name="[options][products-variant-picker-text-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-text-color"]
                      ? element.options["products-variant-picker-text-color"]
                      : undefined
                  }
                  placeholder={
                    variant.options["products-variant-picker-text-color"]
                      ? variant.options["products-variant-picker-text-color"]
                      : "#000"
                  }
                  col="6"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Text Hover"
                  setting_name="[options][products-variant-picker-text-hover-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-text-hover-color"]
                      ? element.options[
                          "products-variant-picker-text-hover-color"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options["products-variant-picker-text-hover-color"]
                      ? variant.options[
                          "products-variant-picker-text-hover-color"
                        ]
                      : element.options["products-variant-picker-text-color"]
                      ? element.options["products-variant-picker-text-color"]
                      : element.options["products-variant-picker-style"] ==
                        "cf-options-buttons"
                      ? "#fff"
                      : "#000"
                  }
                  col="6"
                  show_reset={true}
                  offset_left={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Color"
                  setting_name="[options][products-variant-picker-background-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-background-color"]
                      ? element.options[
                          "products-variant-picker-background-color"
                        ]
                      : undefined
                  }
                  placeholder={"#fff"}
                  col="6"
                  show_reset={true}
                  gradient={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Hover Color"
                  setting_name="[options][products-variant-picker-background-hover-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options[
                      "products-variant-picker-background-hover-color"
                    ]
                      ? element.options[
                          "products-variant-picker-background-hover-color"
                        ]
                      : undefined
                  }
                  placeholder={
                    variant.options[
                      "products-variant-picker-background-hover-color"
                    ]
                      ? variant.options[
                          "products-variant-picker-background-hover-color"
                        ]
                      : element.options[
                          "products-variant-picker-background-color"
                        ] || "#000"
                  }
                  col="6"
                  gradient={true}
                  show_reset={true}
                  offset_left={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Border Style"
                  setting_name="[options][products-variant-picker-border-style]"
                  setting_type="border_style"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-border-style"]
                      ? element.options["products-variant-picker-border-style"]
                      : undefined
                  }
                  show_reset={true}
                />

                {element.options["products-variant-picker-border-style"] &&
                  element.options["products-variant-picker-border-style"] !==
                    "none" && (
                    <div
                      className="col-xs-12 some-vertical-space setting"
                      style={{ marginBottom: "5px" }}
                    >
                      <div className="row hard-center">
                        <div className="mb-[5px] text-xl text-black dark:text-white">
                          Picker Border Width
                        </div>

                        {["left", "top", "bottom", "right"].map((side) => {
                          const settingName = `[options][products-variant-picker-border-${side}]`;
                          return (
                            <VariantBuilderSetting
                              key={settingName}
                              object={element}
                              setting_name={settingName}
                              setting_type="number"
                              object_type="elements"
                              object_id={element.id}
                              value={
                                _.isNil(
                                  element.options[
                                    `products-variant-picker-border-${side}`
                                  ]
                                ) == false
                                  ? element.options[
                                      `products-variant-picker-border-${side}`
                                    ]
                                  : element.options[
                                      "products-variant-picker-border"
                                    ]
                                  ? element.options[
                                      "products-variant-picker-border"
                                    ].replace("px", "")
                                  : undefined
                              }
                              placeholder="1px"
                              col="3"
                              note={window.capitalizeFirstLetter(side)}
                              classNames="spacing-inputs"
                              min={0}
                              allow_empty={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}

                <VariantBuilderSetting
                  object={element}
                  label="Picker Border Color"
                  setting_name="[options][products-variant-picker-border-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  placeholder={
                    variant.options["products-variant-picker-border-color"]
                      ? variant.options["products-variant-picker-border-color"]
                      : "#000"
                  }
                  value={
                    element.options["products-variant-picker-border-color"]
                      ? element.options["products-variant-picker-border-color"]
                      : undefined
                  }
                  col="6"
                  show_reset={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Hover Color"
                  setting_name="[options][products-variant-picker-border-hover-color]"
                  setting_type="color_v2"
                  object_type="elements"
                  object_id={element.id}
                  placeholder={
                    variant.options[
                      "products-variant-picker-border-hover-color"
                    ]
                      ? variant.options[
                          "products-variant-picker-border-hover-color"
                        ]
                      : "#000"
                  }
                  value={
                    element.options[
                      "products-variant-picker-border-hover-color"
                    ]
                      ? element.options[
                          "products-variant-picker-border-hover-color"
                        ]
                      : undefined
                  }
                  col="6"
                  show_reset={true}
                  offset_left={true}
                />

                <VariantBuilderSetting
                  object={element}
                  label="Picker Corner Rounding"
                  setting_name="[options][products-variant-picker-corner-rounding]"
                  setting_type="border_radius"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    element.options["products-variant-picker-corner-rounding"]
                      ? element.options[
                          "products-variant-picker-corner-rounding"
                        ]
                      : undefined
                  }
                  condition={
                    element.options["products-variant-picker-border-style"] !==
                    "none"
                  }
                  valueMappings={{
                    none: "cf-not-rounded",
                    some: "cf-slightly-rounded",
                    full: "cf-fully-rounded",
                    custom: "custom",
                  }}
                  show_reset={true}
                />

                <div className="col-xs-12">
                  {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                    (side) => {
                      const settingName = `[options][products-variant-picker-corner-${side}]`;
                      return (
                        <VariantBuilderSetting
                          key={settingName}
                          object={element}
                          setting_name={settingName}
                          setting_type="number"
                          object_type="elements"
                          object_id={element.id}
                          value={
                            _.isNil(
                              element.options[
                                `products-variant-picker-corner-${side}`
                              ]
                            ) == false
                              ? element.options[
                                  `products-variant-picker-corner-${side}`
                                ]
                              : undefined
                          }
                          condition={
                            element.options[
                              "products-variant-picker-corner-rounding"
                            ] == "custom"
                          }
                          min={0}
                          note={capitalizeFirstLetter(side).replace("-", " ")}
                          col="3"
                          placeholder="0px"
                          classNames="spacing-inputs"
                        />
                      );
                    }
                  )}
                </div>
              </React.Fragment>
            </VariantBuilderPanelCollapse>
          ))}

      {element.options["quantity-picker-show"] !== "false" &&
        element.options["products-quantity"] !== "false" && (
          <VariantBuilderPanelCollapse name="Quantity" open={false}>
            <VariantBuilderSetting
              object={element}
              label="Quantity Minimum Width"
              setting_name="[options][products-quantity-picker-minimum-width]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-minimum-width"]
                  ? element.options["products-quantity-picker-minimum-width"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-minimum-width"]
                  ? variant.options["products-quantity-picker-minimum-width"]
                  : "100"
              }
              min={0}
              max={1000}
              allow_empty={true}
              range_step={1}
              device="desktop"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Quantity Minimum Width"
              setting_name="[options][products-quantity-picker-minimum-width-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-minimum-width-mobile"]
                  ? element.options[
                      "products-quantity-picker-minimum-width-mobile"
                    ]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-minimum-width-mobile"]
                  ? variant.options[
                      "products-quantity-picker-minimum-width-mobile"
                    ]
                  : "100"
              }
              min={0}
              max={1000}
              allow_empty={true}
              range_step={1}
              device="mobile"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Quantity Font family"
              setting_name="[options][products-quantity-picker-font-family]"
              setting_type="select"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-font-family"]
                  ? element.options["products-quantity-picker-font-family"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-font-family"]
                  ? variant.options["products-quantity-picker-font-family"]
                  : undefined
              }
              option_groups={["System fonts", "Google fonts"]}
              options={[
                {
                  value: "",
                  text: "Theme font",
                  group: "System fonts",
                },
                ...window.builder_fonts_dropdown,
              ]}
              callback={ctas.builder.fonts.load_font_family}
              col="8"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-quantity-picker-font-weight]"
              setting_type="font_weight"
              label="Weight"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-font-weight"]
                  ? element.options["products-quantity-picker-font-weight"]
                  : undefined
              }
              col="4"
              classNames="no-padding-left"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Quantity Font Size"
              setting_name="[options][products-quantity-picker-font-size]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-font-size"]
                  ? element.options["products-quantity-picker-font-size"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-font-size"]
                  ? variant.options["products-quantity-picker-font-size"]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="desktop"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Quantity Font Size"
              setting_name="[options][products-quantity-picker-font-size-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-font-size-mobile"]
                  ? element.options["products-quantity-picker-font-size-mobile"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-font-size-mobile"]
                  ? variant.options["products-quantity-picker-font-size-mobile"]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="mobile"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Quantity Text"
              setting_name="[options][products-quantity-picker-text-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-text-color"]
                  ? element.options["products-quantity-picker-text-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-text-color"]
                  ? variant.options["products-quantity-picker-text-color"]
                  : "#000"
              }
              col="6"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Background"
              setting_name="[options][products-quantity-picker-background-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-background-color"]
                  ? element.options["products-quantity-picker-background-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-background-color"]
                  ? variant.options["products-quantity-picker-background-color"]
                  : "#FFFFFF"
              }
              col="6"
              show_reset={true}
              gradient={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Border Style"
              setting_name="[options][products-quantity-picker-border-style]"
              setting_type="border_style"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-border-style"]
                  ? element.options["products-quantity-picker-border-style"]
                  : "solid"
              }
              show_reset={true}
            />

            {element.options["products-quantity-picker-border-style"] !==
              "none" && (
              <div
                className="col-xs-12 some-vertical-space setting"
                style={{ marginBottom: "5px" }}
              >
                <div className="row hard-center">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Picker Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][products-quantity-picker-border-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[
                              `products-quantity-picker-border-${side}`
                            ]
                          ) == false
                            ? element.options[
                                `products-quantity-picker-border-${side}`
                              ]
                            : "1"
                        }
                        allow_empty={true}
                        placeholder="1px"
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <VariantBuilderSetting
              object={element}
              label="Border Color"
              setting_name="[options][products-quantity-picker-border-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-border-color"]
                  ? element.options["products-quantity-picker-border-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-quantity-picker-border-color"] ||
                "#000"
              }
              condition={
                element.options["products-quantity-picker-border-style"] !==
                "none"
              }
              col="6"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Hover"
              setting_name="[options][products-quantity-picker-border-hover-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              placeholder={
                variant.options[
                  "products-quantity-picker-border-hover-color"
                ] || "#000"
              }
              value={
                element.options["products-quantity-picker-border-hover-color"]
                  ? element.options[
                      "products-quantity-picker-border-hover-color"
                    ]
                  : undefined
              }
              condition={
                element.options["products-quantity-picker-border-style"] !==
                "none"
              }
              col="6"
              show_reset={true}
              offset_left={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Border Corner Rounding"
              setting_name="[options][products-quantity-picker-corner-rounding]"
              setting_type="border_radius"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-quantity-picker-corner-rounding"]
                  ? element.options["products-quantity-picker-corner-rounding"]
                  : undefined
              }
              valueMappings={{
                none: "cf-not-rounded",
                some: "cf-slightly-rounded",
                full: "cf-fully-rounded",
                custom: "custom",
              }}
              show_reset={true}
            />

            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][products-quantity-picker-corner-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[
                            `products-quantity-picker-corner-${side}`
                          ]
                        ) == false
                          ? element.options[
                              `products-quantity-picker-corner-${side}`
                            ]
                          : undefined
                      }
                      condition={
                        element.options[
                          "products-quantity-picker-corner-rounding"
                        ] == "custom"
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder="0px"
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>
          </VariantBuilderPanelCollapse>
        )}

      {element.options["subscription-show"] !== "false" &&
        element.options["products-source"] == "ShopifyGraphql" && (
          <VariantBuilderPanelCollapse name="Subscriptions" open={false}>
            <VariantBuilderSetting
              object={element}
              label="Plan Font Family"
              setting_name="[options][products-subscription-choices-font-family]"
              setting_type="select"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-choices-font-family"]
                  ? element.options["products-subscription-choices-font-family"]
                  : undefined
              }
              placeholder={
                variant.options["products-subscription-choices-font-family"]
                  ? variant.options["products-subscription-choices-font-family"]
                  : undefined
              }
              option_groups={["System fonts", "Google fonts"]}
              options={[
                {
                  value: "",
                  text: "Theme font",
                  group: "System fonts",
                },
                ...window.builder_fonts_dropdown,
              ]}
              callback={ctas.builder.fonts.load_font_family}
              col="8"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-subscription-choices-font-weight]"
              setting_type="font_weight"
              label="Weight"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-choices-font-weight"]
                  ? element.options["products-subscription-choices-font-weight"]
                  : undefined
              }
              col="4"
              classNames="no-padding-left"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Plan Font Size"
              setting_name="[options][products-subscription-choices-font-size]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-choices-font-size"]
                  ? element.options["products-subscription-choices-font-size"]
                  : undefined
              }
              placeholder={
                variant.options["products-subscription-choices-font-size"]
                  ? variant.options["products-subscription-choices-font-size"]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="desktop"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Choices Font Size"
              setting_name="[options][products-subscription-choices-font-size-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options[
                  "products-subscription-choices-font-size-mobile"
                ]
                  ? element.options[
                      "products-subscription-choices-font-size-mobile"
                    ]
                  : undefined
              }
              placeholder={
                variant.options[
                  "products-subscription-choices-font-size-mobile"
                ]
                  ? variant.options[
                      "products-subscription-choices-font-size-mobile"
                    ]
                  : "14"
              }
              allow_empty={true}
              min={0}
              max={100}
              range_step={1}
              device="mobile"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Text Color"
              setting_name="[options][products-subscription-choices-text-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-choices-text-color"]
                  ? element.options["products-subscription-choices-text-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-subscription-choices-text-color"]
                  ? variant.options["products-subscription-choices-text-color"]
                  : "#000"
              }
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Hover"
              setting_name="[options][products-subscription-choices-text-hover-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options[
                  "products-subscription-choices-text-hover-color"
                ]
                  ? element.options[
                      "products-subscription-choices-text-hover-color"
                    ]
                  : undefined
              }
              condition={
                element.options["products-subscription-choices-type"] ==
                "radios"
              }
              placeholder={
                variant.options[
                  "products-subscription-choices-text-hover-color"
                ]
                  ? variant.options[
                      "products-subscription-choices-text-hover-color"
                    ]
                  : "#000"
              }
              show_reset={true}
              offset_left={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Choices Background Color"
              setting_name="[options][products-subscription-choices-background-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options[
                  "products-subscription-choices-background-color"
                ]
                  ? element.options[
                      "products-subscription-choices-background-color"
                    ]
                  : undefined
              }
              condition={
                element.options["products-subscription-choices-type"] !==
                "radios"
              }
              placeholder={
                variant.options[
                  "products-subscription-choices-background-color"
                ]
                  ? variant.options[
                      "products-subscription-choices-background-color"
                    ]
                  : "#fff"
              }
              gradient={true}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Border Style"
              setting_name="[options][products-subscription-border-style]"
              setting_type="border_style"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-border-style"]
                  ? element.options["products-subscription-border-style"]
                  : "solid"
              }
              condition={
                element.options["products-subscription-choices-type"] !==
                "radios"
              }
              show_reset={true}
            />

            {element.options["products-subscription-choices-type"] !==
              "radios" && (
              <div
                className="col-xs-12 some-vertical-space setting"
                style={{ marginBottom: "5px" }}
              >
                <div className="row hard-center">
                  <div className="mb-[5px] text-xl text-black dark:text-white">
                    Border Width
                  </div>

                  {["left", "top", "bottom", "right"].map((side) => {
                    const settingName = `[options][products-subscription-border-${side}]`;
                    return (
                      <VariantBuilderSetting
                        key={settingName}
                        object={element}
                        setting_name={settingName}
                        setting_type="number"
                        object_type="elements"
                        object_id={element.id}
                        value={
                          _.isNil(
                            element.options[
                              `products-subscription-border-${side}`
                            ]
                          ) == false
                            ? element.options[
                                `products-subscription-border-${side}`
                              ]
                            : undefined
                        }
                        allow_empty={true}
                        placeholder={
                          variant.options[
                            `products-subscription-border-${side}`
                          ] || "1px"
                        }
                        col="3"
                        note={window.capitalizeFirstLetter(side)}
                        classNames="spacing-inputs"
                        min={0}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <VariantBuilderSetting
              object={element}
              label="Border Color"
              setting_name="[options][products-subscription-border-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-border-color"]
                  ? element.options["products-subscription-border-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-subscription-border-color"]
              }
              condition={
                element.options["products-subscription-border-style"] !==
                  "none" &&
                element.options["products-subscription-choices-type"] !==
                  "radios"
              }
              col="6"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Hover Color"
              setting_name="[options][products-subscription-border-hover-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              placeholder={
                variant.options["products-subscription-border-hover-color"] ||
                "#000"
              }
              value={
                element.options["products-subscription-border-hover-color"]
                  ? element.options["products-subscription-border-hover-color"]
                  : undefined
              }
              condition={
                element.options["products-subscription-border-style"] !==
                  "none" &&
                element.options["products-subscription-choices-type"] !==
                  "radios"
              }
              col="6"
              show_reset={true}
              offset_left={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Plan Radio"
              setting_name="[options][products-subscription-radio-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-radio-color"]
                  ? element.options["products-subscription-radio-color"]
                  : undefined
              }
              placeholder={
                variant.options["products-subscription-radio-color"] ||
                "#D4DDEA"
              }
              condition={
                element.options["products-subscription-choices-type"] ==
                "radios"
              }
              show_reset={true}
              col="12"
            />

            <VariantBuilderSetting
              object={element}
              label="Selected Color"
              setting_name="[options][products-subscription-radio-selected-color]"
              setting_type="color_v2"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-radio-selected-color"]
                  ? element.options[
                      "products-subscription-radio-selected-color"
                    ]
                  : undefined
              }
              placeholder={
                variant.options["products-subscription-radio-selected-color"] ||
                "#003FFF"
              }
              condition={
                element.options["products-subscription-choices-type"] ==
                "radios"
              }
              show_reset={true}
              col="12"
            />

            <VariantBuilderSetting
              object={element}
              label="Border Corner Rounding"
              setting_name="[options][products-subscription-corner-rounding]"
              setting_type="border_radius"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-subscription-corner-rounding"]
                  ? element.options["products-subscription-corner-rounding"]
                  : undefined
              }
              condition={
                element.options["products-subscription-choices-type"] !==
                "radios"
              }
              valueMappings={{
                none: "cf-not-rounded",
                some: "cf-slightly-rounded",
                full: "cf-fully-rounded",
                custom: "custom",
              }}
              show_reset={true}
            />

            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][products-subscription-corner-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[
                            `products-subscription-corner-${side}`
                          ]
                        ) == false
                          ? element.options[
                              `products-subscription-corner-${side}`
                            ]
                          : undefined
                      }
                      condition={
                        element.options[
                          "products-subscription-corner-rounding"
                        ] == "custom" &&
                        element.options[
                          "products-subscription-choices-type"
                        ] !== "radios"
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder={
                        variant.options[`products-subscription-corner-${side}`]
                      }
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>
          </VariantBuilderPanelCollapse>
        )}

      <VariantBuilderPanelCollapse name="Buttons" open={false}>
        <VariantBuilderSetting
          object={element}
          label={"Button Padding"}
          setting_name="[options][button-size]"
          setting_type="box_height"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-size"]
              ? element.options["button-size"]
              : "cf-button-regular"
          }
          valueMappings={{
            small: "cf-button-small",
            regular: "cf-button-regular",
            large: "cf-button-large",
            custom: "custom",
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-size-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Button Padding"}
          value={
            element.options["button-size-value"]
              ? element.options["button-size-value"]
              : "0"
          }
          condition={element.options["button-size"] == "custom"}
          min={0}
          max={200}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Width"
          setting_name="[options][button-width]"
          setting_type="box_width"
          valueMappings={{
            auto: "cf-button-slim",
            regular: "cf-button-mid",
            full: "cf-full-width",
            custom: "custom",
          }}
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-width"]
              ? element.options["button-width"]
              : "cf-full-width"
          }
          condition={
            element.element_type !== "products" ||
            element.options["products-layout"] == "list"
          }
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-width-value]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Button Width"}
          value={
            element.options["button-width-value"]
              ? element.options["button-width-value"]
              : undefined
          }
          condition={element.options["button-width"] == "custom"}
          min={0}
          max={2000}
          range_step={"1"}
          device="desktop"
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-width-value-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Custom Button Width"}
          value={
            element.options["button-width-value-mobile"]
              ? element.options["button-width-value-mobile"]
              : undefined
          }
          condition={element.options["button-width"] == "custom"}
          min={0}
          max={2000}
          range_step={"1"}
          device="mobile"
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font Family"
          setting_name="[options][button-font]"
          setting_type="select"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font"]
              ? element.options["button-font"]
              : undefined
          }
          placeholder={
            variant.options["button-font"]
              ? variant.options["button-font"]
              : undefined
          }
          option_groups={["System fonts", "Google fonts"]}
          options={[
            {
              value: "",
              text: "Theme font",
              group: "System fonts",
            },
            ...window.builder_fonts_dropdown,
          ]}
          skip_history={true}
          callback={function (new_value, old_value) {
            builder.update([
              {
                object_type: "elements",
                object_id: element.id,
                setting_name: "[options][button-font]",
                value: new_value,
                old_value: old_value,
              },
            ]);
            ctas.builder.fonts.load_font_family(element.options["button-font"]);
          }}
          col="8"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-font-weight]"
          setting_type="font_weight"
          label="Weight"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font-weight"]
              ? element.options["button-font-weight"]
              : undefined
          }
          placeholder={variant.options["button-font-weight"]}
          col="4"
          classNames="no-padding-left"
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font Size"
          setting_name="[options][button-font-size]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font-size"]
              ? element.options["button-font-size"]
              : undefined
          }
          placeholder={
            variant.options["button-font-size"]
              ? variant.options["button-font-size"]
              : 16
          }
          min={0}
          max={100}
          range_step={1}
          device="desktop"
          allow_empty={true}
          callback={(new_value, old_value) => {
            // remove top level inline style so changes take effect
            if (new_value) {
              let elementHtml = $(element.html)[0];
              let tagsWithStyle = $(elementHtml).find('[style*="font-size"]');

              if (tagsWithStyle.length == 1) {
                tagsWithStyle[0].style.removeProperty("font-size");
                const new_value = elementHtml.outerHTML;

                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[html]",
                    value: new_value,
                    old_value: new_value,
                  },
                ]);
              }
            }
          }}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          label="Button Font Size"
          setting_name="[options][button-font-size-mobile]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-font-size-mobile"]
              ? element.options["button-font-size-mobile"]
              : undefined
          }
          placeholder={
            element.options["button-font-size"]
              ? element.options["button-font-size"]
              : (variant.options["button-font-size-mobile"]
                  ? variant.options["button-font-size-mobile"]
                  : variant.options["button-font-size"]) || 16
          }
          min={0}
          max={100}
          range_step={1}
          device="mobile"
          allow_empty={true}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label={"Text Color"}
            setting_name="[options][button-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-text-color"]
                ? element.options["button-text-color"]
                : undefined
            }
            placeholder={
              variant.options["button-text-color"]
                ? variant.options["button-text-color"]
                : "#fff"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button–hover-text-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button–hover-text-color"]
                ? element.options["button–hover-text-color"]
                : undefined
            }
            placeholder={
              element.options["button-text-color"]
                ? element.options["button-text-color"]
                : variant.options["button–hover-text-color"]
                ? variant.options["button–hover-text-color"]
                : variant.options["button-text-color"]
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label={"Button Color"}
            setting_name="[options][button-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-color"]
                ? element.options["button-color"]
                : undefined
            }
            placeholder={
              variant.options["button-color"]
                ? variant.options["button-color"]
                : "#0072f7"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
            gradient={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button–hover-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button–hover-color"]
                ? element.options["button–hover-color"]
                : undefined
            }
            placeholder={
              element.options["button-color"]
                ? element.options["button-color"]
                : variant.options["button–hover-color"]
                ? variant.options["button–hover-color"]
                : variant.options["button-color"]
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
            gradient={true}
          />
        </div>

        <VariantBuilderSetting
          object={element}
          label={"Border Style"}
          setting_name="[options][button-border-style]"
          setting_type="border_style"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-border-style"]
              ? element.options["button-border-style"]
              : undefined
          }
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Border Color"
            setting_name="[options][button-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-border-color"]
                ? element.options["button-border-color"]
                : undefined
            }
            placeholder={
              element.options["button-color"]
                ? element.options["button-color"]
                : variant.options["button-border-color"]
            }
            condition={
              element.options["button-border-style"] &&
              element.options["button-border-style"] !== "" &&
              element.options["button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button-hover-border-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-hover-border-color"]
                ? element.options["button-hover-border-color"]
                : undefined
            }
            placeholder={
              element.options["button-border-color"]
                ? element.options["button-border-color"]
                : variant.options["button-hover-border-color"]
            }
            condition={
              element.options["button-border-style"] &&
              element.options["button-border-style"] !== "" &&
              element.options["button-border-style"] !== "none"
            }
            col="6"
            classNames="some-padding-left"
            show_reset={true}
            offset_left={true}
          />
        </div>

        {element.options["button-border-style"] &&
          element.options["button-border-style"] !== "none" && (
            <div
              className="col-xs-12 some-vertical-space setting"
              style={{ marginBottom: "5px" }}
            >
              <div className="row">
                <div className="mb-[5px] text-xl text-black dark:text-white">
                  Button Border Width
                </div>

                {["left", "top", "bottom", "right"].map((side) => {
                  const settingName = `[options][button-border-width-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[`button-border-width-${side}`]
                        ) == false
                          ? element.options[`button-border-width-${side}`]
                          : element.options["button-border-width"]
                          ? element.options["button-border-width"].replace(
                              "px",
                              ""
                            )
                          : undefined
                      }
                      placeholder="0px"
                      col="3"
                      note={window.capitalizeFirstLetter(side)}
                      classNames="spacing-inputs"
                      min={0}
                      allow_empty={true}
                    />
                  );
                })}
              </div>
            </div>
          )}

        <VariantBuilderSetting
          object={element}
          label={"Button Corner Rounding"}
          setting_name="[options][button-radius]"
          setting_type="border_radius"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-radius"]
              ? element.options["button-radius"]
              : undefined
          }
          valueMappings={{
            none: "cf-not-rounded",
            some: "cf-slightly-rounded",
            full: "cf-fully-rounded",
            custom: "custom",
          }}
          show_reset={true}
        />

        <div className="col-xs-12">
          {["top-left", "top-right", "bottom-right", "bottom-left"].map(
            (side) => {
              const settingName = `[options][button-radius-${side}]`;
              return (
                <VariantBuilderSetting
                  key={settingName}
                  object={element}
                  setting_name={settingName}
                  setting_type="number"
                  object_type="elements"
                  object_id={element.id}
                  value={
                    _.isNil(element.options[`button-radius-${side}`]) == false
                      ? element.options[`button-radius-${side}`]
                      : undefined
                  }
                  condition={element.options["button-radius"] == "custom"}
                  min={0}
                  note={capitalizeFirstLetter(side).replace("-", " ")}
                  col="3"
                  placeholder="0px"
                  classNames="spacing-inputs"
                />
              );
            }
          )}
        </div>

        <VariantBuilderSetting
          object={element}
          label={"Button Shadow"}
          setting_name="[options][button-shadow]"
          setting_type="shadow"
          object_type="elements"
          object_id={element.id}
          value={
            element.options["button-shadow"]
              ? element.options["button-shadow"]
              : undefined
          }
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-horizontal-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Horizontal Distance"}
          value={
            element.options["button-shadow-horizontal-distance"]
              ? element.options["button-shadow-horizontal-distance"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-vertical-distance]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Vertical Distance"}
          value={
            element.options["button-shadow-vertical-distance"]
              ? element.options["button-shadow-vertical-distance"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={-100}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-blur-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["button-shadow-blur-radius"]
              ? element.options["button-shadow-blur-radius"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <VariantBuilderSetting
          object={element}
          setting_name="[options][button-shadow-spread-radius]"
          setting_type="range"
          object_type="elements"
          object_id={element.id}
          label={"Shadow Blur Radius"}
          value={
            element.options["button-shadow-spread-radius"]
              ? element.options["button-shadow-spread-radius"]
              : undefined
          }
          condition={element.options["button-shadow"] == "custom"}
          min={0}
          max={100}
          range_step={"1"}
          show_reset={true}
        />

        <div className="row hard-center">
          <VariantBuilderSetting
            object={element}
            label="Shadow Color"
            setting_name="[options][button-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-shadow-color"]
                ? element.options["button-shadow-color"]
                : undefined
            }
            condition={element.options["button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-right"
            show_reset={true}
          />

          <VariantBuilderSetting
            object={element}
            label="Hover"
            setting_name="[options][button-hover-shadow-color]"
            setting_type="color_v2"
            object_type="elements"
            object_id={element.id}
            value={
              element.options["button-hover-shadow-color"]
                ? element.options["button-hover-shadow-color"]
                : undefined
            }
            placeholder={
              element.options["button-shadow-color"]
                ? element.options["button-shadow-color"]
                : variant.options["button-hover-shadow-color"]
            }
            condition={element.options["button-shadow"] == "custom"}
            col="6"
            classNames="some-padding-left"
            offset_left={true}
          />
        </div>
      </VariantBuilderPanelCollapse>

      {element.options["products-button-cart-all"] == "true" && (
        <React.Fragment>
          <VariantBuilderPanelCollapse
            name="Add All To Cart Button"
            open={false}
          >
            <VariantBuilderSetting
              object={element}
              label={"Button Padding"}
              setting_name="[options][products-add-all-btn-padding]"
              setting_type="box_height"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-add-all-btn-padding"]
                  ? element.options["products-add-all-btn-padding"]
                  : undefined
              }
              valueMappings={{
                small: "cf-button-small",
                regular: "cf-button-regular",
                large: "cf-button-large",
                custom: "custom",
              }}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-add-all-btn-padding-value]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Button Padding"}
              value={
                element.options["products-add-all-btn-padding-value"]
                  ? element.options["products-add-all-btn-padding-value"]
                  : undefined
              }
              placeholder={element.options["button-size-value"]}
              condition={
                element.options["products-add-all-btn-padding"] == "custom"
              }
              min={0}
              max={200}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Button Font"
              setting_name="[options][products-add-all-btn-font-family]"
              setting_type="select"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-add-all-btn-font-family"]
                  ? element.options["products-add-all-btn-font-family"]
                  : undefined
              }
              placeholder={
                element.options["button-font"] || variant.options["button-font"]
              }
              option_groups={["System fonts", "Google fonts"]}
              options={[
                {
                  value: "",
                  text: "Theme font",
                  group: "System fonts",
                },
                ...window.builder_fonts_dropdown,
              ]}
              callback={function (new_value, old_value) {
                builder.update([
                  {
                    object_type: "elements",
                    object_id: element.id,
                    setting_name: "[options][products-add-all-btn-font-family]",
                    value: new_value,
                    old_value: old_value,
                  },
                ]);
                ctas.builder.fonts.load_font_family(
                  element.options["products-add-all-btn-font-family"]
                );
              }}
              skip_history={true}
              col="8"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-add-all-btn-font-weight]"
              setting_type="font_weight"
              label="Weight"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-add-all-btn-font-weight"]
                  ? element.options["products-add-all-btn-font-weight"]
                  : undefined
              }
              placeholder={
                element.options["button-font-weight"] ||
                variant.options["button-font-weight"]
              }
              col="4"
              classNames="no-padding-left"
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Button Font Size"
              setting_name="[options][products-add-all-btn-font-size]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-add-all-btn-font-size"]
                  ? element.options["products-add-all-btn-font-size"]
                  : undefined
              }
              placeholder={
                element.options["button-font-size"] ||
                variant.options["button-font-size"] ||
                16
              }
              min={0}
              max={100}
              range_step={1}
              device="desktop"
              allow_empty={true}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              label="Button Font Size"
              setting_name="[options][products-add-all-btn-font-size-mobile]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              value={element.options["products-add-all-btn-font-size-mobile"]}
              placeholder={
                element.options["button-font-size-mobile"] ||
                variant.options["button-font-size-mobile"] ||
                variant.options["button-font-size"] ||
                16
              }
              min={0}
              max={100}
              range_step={1}
              device="mobile"
              allow_empty={true}
              show_reset={true}
            />

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label={"Button Text Color"}
                setting_name="[options][products-add-all-btn-text-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["products-add-all-btn-text-color"]
                    ? element.options["products-add-all-btn-text-color"]
                    : undefined
                }
                placeholder={
                  element.options["button-text-color"] ||
                  variant.options["button-text-color"] ||
                  "#fff"
                }
                col="6"
                classNames="some-padding-right"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover Color"
                setting_name="[options][products-add-all-btn-hover-text-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={element.options["products-add-all-btn-hover-text-color"]}
                placeholder={
                  element.options["button–hover-text-color"] ||
                  variant.options["button–hover-text-color"] ||
                  variant.options["button-text-color"]
                }
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                offset_left={true}
              />
            </div>

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label={"Button Color"}
                setting_name="[options][products-add-all-btn-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["products-add-all-btn-color"]
                    ? element.options["products-add-all-btn-color"]
                    : undefined
                }
                placeholder={
                  element.options["button-color"] ||
                  variant.options["button-color"] ||
                  "#0072f7"
                }
                col="6"
                classNames="some-padding-right"
                gradient={true}
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][products-add-all-btn-hover-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={element.options["products-add-all-btn-hover-color"]}
                placeholder={
                  element.options["button–hover-color"] ||
                  variant.options["button–hover-color"] ||
                  variant.options["button-color"]
                }
                col="6"
                classNames="some-padding-left"
                gradient={true}
                show_reset={true}
                offset_left={true}
              />
            </div>

            <VariantBuilderSetting
              object={element}
              label={"Button Border Style"}
              setting_name="[options][products-add-all-btn-border-style]"
              setting_type="border_style"
              object_type="elements"
              object_id={element.id}
              value={
                element.options["products-add-all-btn-border-style"]
                  ? element.options["products-add-all-btn-border-style"]
                  : undefined
              }
              show_reset={true}
            />

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label="Button Border"
                setting_name="[options][products-add-all-btn-border-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={element.options["products-add-all-btn-border-color"]}
                placeholder={
                  element.options["button-border-color"] ||
                  variant.options["button-border-color"]
                }
                condition={
                  element.options["products-add-all-btn-border-style"] &&
                  element.options["products-add-all-btn-border-style"] !== "" &&
                  element.options["products-add-all-btn-border-style"] !==
                    "none"
                }
                col="6"
                classNames="some-padding-right"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][products-add-all-btn-hover-border-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["products-add-all-btn-hover-border-color"]
                }
                placeholder={
                  element.options["button-hover-border-color"] ||
                  variant.options["button-hover-border-color"]
                }
                condition={
                  element.options["products-add-all-btn-border-style"] &&
                  element.options["products-add-all-btn-border-style"] !== "" &&
                  element.options["products-add-all-btn-border-style"] !==
                    "none"
                }
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                offset_left={true}
              />
            </div>

            {element.options["products-add-all-btn-border-style"] &&
              element.options["products-add-all-btn-border-style"] !==
                "none" && (
                <div
                  className="col-xs-12 some-vertical-space setting"
                  style={{ marginBottom: "5px" }}
                >
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="mb-[5px] text-xl text-black dark:text-white">
                        Button Border Width
                      </div>

                      {["left", "top", "bottom", "right"].map((side) => {
                        const settingName = `[options][products-add-all-btn-border-width-${side}]`;
                        return (
                          <VariantBuilderSetting
                            key={settingName}
                            object={element}
                            setting_name={settingName}
                            setting_type="number"
                            object_type="elements"
                            object_id={element.id}
                            value={
                              _.isNil(
                                element.options[
                                  `products-add-all-btn-border-width-${side}`
                                ]
                              ) == false
                                ? element.options[
                                    `products-add-all-btn-border-width-${side}`
                                  ]
                                : element.options[
                                    "products-add-all-btn-border-width"
                                  ]
                                ? element.options[
                                    "products-add-all-btn-border-width"
                                  ].replace("px", "")
                                : undefined
                            }
                            placeholder={
                              element.options[`button-border-width-${side}`] ||
                              element.options["button-border-width"]
                            }
                            col="3"
                            note={window.capitalizeFirstLetter(side)}
                            classNames="spacing-inputs"
                            min={0}
                            allow_empty={true}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

            <VariantBuilderSetting
              object={element}
              label={"Button Corner Rounding"}
              setting_name="[options][products-add-all-btn-radius]"
              setting_type="border_radius"
              object_type="elements"
              object_id={element.id}
              value={element.options["products-add-all-btn-radius"]}
              show_reset={true}
            />

            <div className="col-xs-12">
              {["top-left", "top-right", "bottom-right", "bottom-left"].map(
                (side) => {
                  const settingName = `[options][products-add-all-btn-radius-${side}]`;
                  return (
                    <VariantBuilderSetting
                      key={settingName}
                      object={element}
                      setting_name={settingName}
                      setting_type="number"
                      object_type="elements"
                      object_id={element.id}
                      value={
                        _.isNil(
                          element.options[`products-add-all-btn-radius-${side}`]
                        ) == false
                          ? element.options[
                              `products-add-all-btn-radius-${side}`
                            ]
                          : undefined
                      }
                      condition={
                        element.options["products-add-all-btn-radius"] ==
                        "custom"
                      }
                      min={0}
                      note={capitalizeFirstLetter(side).replace("-", " ")}
                      col="3"
                      placeholder="0px"
                      classNames="spacing-inputs"
                    />
                  );
                }
              )}
            </div>

            <VariantBuilderSetting
              object={element}
              label={"Button Shadow"}
              setting_name="[options][products-add-all-btn-shadow]"
              setting_type="shadow"
              object_type="elements"
              object_id={element.id}
              value={element.options["products-add-all-btn-shadow"]}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-add-all-btn-shadow-horizontal-distance]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Shadow Horizontal Distance"}
              value={
                element.options[
                  "products-add-all-btn-shadow-horizontal-distance"
                ]
              }
              condition={
                element.options["products-add-all-btn-shadow"] == "custom"
              }
              min={-100}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-add-all-btn-shadow-vertical-distance]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Shadow Vertical Distance"}
              value={
                element.options["products-add-all-btn-shadow-vertical-distance"]
              }
              condition={
                element.options["products-add-all-btn-shadow"] == "custom"
              }
              min={-100}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-add-all-btn-shadow-blur-radius]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Shadow Blur Radius"}
              value={element.options["products-add-all-btn-shadow-blur-radius"]}
              condition={
                element.options["products-add-all-btn-shadow"] == "custom"
              }
              min={0}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <VariantBuilderSetting
              object={element}
              setting_name="[options][products-add-all-btn-shadow-spread-radius]"
              setting_type="range"
              object_type="elements"
              object_id={element.id}
              label={"Shadow Blur Radius"}
              value={
                element.options["products-add-all-btn-shadow-spread-radius"]
              }
              condition={
                element.options["products-add-all-btn-shadow"] == "custom"
              }
              min={0}
              max={100}
              range_step={"1"}
              show_reset={true}
            />

            <div className="row hard-center">
              <VariantBuilderSetting
                object={element}
                label="Shadow Color"
                setting_name="[options][products-add-all-btn-shadow-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={element.options["products-add-all-btn-shadow-color"]}
                condition={
                  element.options["products-add-all-btn-shadow"] == "custom"
                }
                col="6"
                classNames="some-padding-right"
                show_reset={true}
              />

              <VariantBuilderSetting
                object={element}
                label="Hover"
                setting_name="[options][products-add-all-btn-hover-shadow-color]"
                setting_type="color_v2"
                object_type="elements"
                object_id={element.id}
                value={
                  element.options["products-add-all-btn-hover-shadow-color"]
                }
                condition={
                  element.options["products-add-all-btn-shadow"] == "custom"
                }
                col="6"
                classNames="some-padding-left"
                show_reset={true}
                offset_left={true}
              />
            </div>
          </VariantBuilderPanelCollapse>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ElementSettingsProductsDesign;
