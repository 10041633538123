import React from "react";
import useConditions from "../conditions/useConditions";
import ConditionForm from "./ConditionForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(fab, fas, far);

const ConditionListItem = (props) => {
  const { currentConditionsHook } = useConditions(props);
  const Conditions = currentConditionsHook;

  const object = props.object;
  const website = props.website;

  const itemLabel = () => {
    var label = object.condition_type;
    let object_data = object.data || {};

    if (
      [
        "If page is",
        "If page is NOT",
        "If person has visited",
        "If person has NOT visited",
      ].includes(object.condition_type)
    ) {
      let url = object.url.split('?')[0].replace('https://', '').replace('http://', '').replace(/\/+$/, '');
      var label = label + " - " + url;

      if (object.count) {
        label += " " + object.count + " times";
      }
    }

    if (
      [
        "If person has visited URL in session with keyword",
        "If person has NOT visited URL in session with keyword",
      ].includes(object.condition_type)
    ) {
      var label = label + ' "' + object.keyword + '"';

      if (object.count) {
        label += " " + object.count + " times";
      }
    }

    if (
      [
        "If URL contains",
        "If URL does NOT contain",
        "If visiting from city",
        "If NOT visiting from city",
        "If visiting from region",
        "If NOT visiting from region",
        "If referral source contains",
        "If referral source does NOT contain",
        "If current score is greater than",
        "If current score is less than",
        "If quiz answers are mostly",
        "If quiz answers are NOT mostly",
      ].includes(object.condition_type)
    ) {
      var label = label + " – " + object.keyword;
    }

    if (
      ["If visiting from country", "If NOT visiting from country"].includes(
        object.condition_type
      )
    ) {
      var label = label + " – " + object.country;
    }

    if (
      [
        "If person has visited with URL parameter",
        "If person has NOT visited with URL parameter",
        "If cookie value contains keyword",
        "If cookie value does NOT contain keyword",
        "If global variable value contains keyword",
        "If global variable value does NOT contain keyword",
      ].includes(object.condition_type)
    ) {
      var label = label + " – " + '"' + object.url + '" ? ' + object.keyword;
    }

    if (
      [
        "If person has more than X page views",
        "If person has less than X page views",
        "If person has more than X sessions",
        "If person has fewer than X sessions",
        "If person has more than X page views in current session",
        "If person has fewer than X page views in current session",
        "If current session is more than X minutes",
        "If current session is less than X minutes",
      ].includes(object.condition_type)
    ) {
      var label = label + " – " + object.count;
    }

    if (
      [
        "If contact has tag",
        "If person has tag",
        "If contact does NOT have tag",
        "If person does NOT have tag",
      ].includes(object.condition_type) &&
      object.tag_id !== undefined
    ) {
      var tag = Object.values(website.tags).find(
        (tag) => tag.id == object.tag_id
      );

      if (tag !== undefined) var label = label + " – " + tag.name;
    }

    if (
      [
        "If person has viewed CTA",
        "If person has viewed campaign",
        "If person has NOT viewed CTA",
        "If person has NOT viewed campaign",
        "If person has converted with CTA",
        "If person has converted with campaign",
        "If person has NOT converted with CTA",
        "If person has NOT converted with campaign",
        "If person has engaged with CTA",
        "If person has engaged with campaign",
        "If person has NOT engaged with CTA",
        "If person has NOT engaged with campaign",
        "If person has completed CTA",
        "If person has completed campaign",
        "If person has NOT completed CTA",
        "If person has NOT completed campaign",
        "If visiting ConvertFlow landing page",
      ].includes(object.condition_type) &&
      object.condition_cta !== undefined
    ) {
      var cta = Object.values(website.ctas).find(
        (cta) => cta.id == object.condition_cta
      );
      if (cta !== undefined) {
        var name = cta.name !== null ? cta.name : "Campaign " + cta.id;
      } else {
        var name = "Campaign " + object.condition_cta;
      }
      var label = label + ' "' + name + '"';

      if (object.count) {
        label += " " + object.count + " times";
      }
    }

    if (
      [
        "If custom field value contains",
        "If custom field value does NOT contain",
        "If CTA custom field contains",
        "If CTA custom field does NOT contain",
        "If CTA survey selection matches",
        "If CTA survey selection does NOT match",
        "If answer to question is",
        "If answer to question is NOT",
        "If custom field contains",
        "If custom field does NOT contain",
        "If survey selection matches",
        "If survey selection does NOT match",
        "If custom field is greater than",
        "If custom field is less than",
      ].includes(object.condition_type)
    ) {
      var label = `${label} - ${object_data["slug"]} ? "${object.keyword}"`;
    }

    if (object.condition_type == "If area is" && website.areas !== undefined) {
      var area = Object.values(website.areas).find(
        (area) => area.id == object.area_id
      );

      if (area !== undefined) {
        var name = area.name !== null ? area.name : "Area " + area.id;
        var label = label + " – " + name;
      }
    }

    if (object.condition_type == "If device type is") {
      var label = label + " – " + object_data["device"];
    }

    if (
      [
        "If contact field value contains",
        "If contact field value does NOT contain",
      ].includes(object.condition_type)
    ) {
      var label =
        label + " – " + object_data["attribute"] + " ? " + object.keyword;
    }

    if (
      [
        "If person is in visitor segment",
        "If person is NOT in visitor segment",
      ].includes(object.condition_type)
    ) {
      var conditions_group = Object.values(website.conditions_groups).find(
        (conditions_group) =>
          conditions_group.id == object_data["conditions_group_id"]
      );

      if (conditions_group !== undefined) {
        var name =
          conditions_group.name !== null
            ? conditions_group.name
            : "Segment " + conditions_group.id;
        var label = label + " – " + name;
      }
    }

    if (object.data_name) {
      var label = label + " – " + object.data_name;
    }

    Object.values(website.integrations).forEach((integration) => {
      Object.values(integration.condition_types).forEach(
        (integration_condition_type) => {
          if (
            object.condition_type ==
              integration_condition_type.condition_type &&
            object.data
          ) {
            integration_condition_type.settings.forEach((setting) => {
              let labelAddition = object.data[setting.attribute];

              // use integration config list item name instead of ID if available
              if (setting.dropdown && integration.api_data[setting.dropdown]) {
                const listItem = integration.api_data[setting.dropdown].filter(
                  (item) =>
                    item[setting.attribute_key || "id"] + "" ==
                    object.data[setting.attribute] + ""
                )[0];

                if (listItem && listItem["name"]) {
                  labelAddition = listItem["name"];
                }
              }
              label += ` - ${labelAddition}`;
            });
          }
        }
      );
    });

    return label;
  };

  return (
    <React.Fragment>
      <div
        className="col-xs-12 no-padding-sides flex mb-[15px]"
        data-object-type="conditions"
        data-object-id={object.id}
      >
        <div className="flex-grow">
          <ConditionForm
            condition={object}
            {...props}
          />
        </div>

        <div className="w-[40px] pl-3 flex gap-x-2 cf-item-controls no-padding-right">
          <button
            title="Duplicate"
            className={`h-[30px] p-0 px-2 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
            onClick={() => props.onDuplicate(object)}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 9.84375L14.25 4.49994C14.25 3.25729 13.2426 2.24992 12 2.24994L6.65625 2.25M9.75 15.75L4.6875 15.75C3.75552 15.75 3 14.9945 3 14.0625L3 6.75C3 5.81802 3.75552 5.0625 4.6875 5.0625L9.75 5.0625C10.682 5.0625 11.4375 5.81802 11.4375 6.75L11.4375 14.0625C11.4375 14.9945 10.682 15.75 9.75 15.75Z"
                stroke={props.dark_mode ? "white" : "black"}
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>

          <button
            title="Remove"
            className={`h-[30px] p-0 px-2 pt-1 bg-white hover:bg-slate-100 dark:bg-slate-950 dark:hover:bg-slate-800 cursor-pointer border-2 border-solid border-white hover:border-slate-100 dark:border-slate-950 dark:hover:border-slate-800 rounded-lg`}
            onClick={() =>
              Conditions.remove(object, true, () => {
                props.onRemove(object.id);
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 4.63235H15M6.75 2.25H11.25M7.5 12.5735V7.80882M10.5 12.5735V7.80882M11.625 15.75H6.375C5.54657 15.75 4.875 15.0389 4.875 14.1618L4.53255 5.45953C4.5148 5.00837 4.85544 4.63235 5.2819 4.63235H12.7181C13.1446 4.63235 13.4852 5.00837 13.4674 5.45953L13.125 14.1618C13.125 15.0389 12.4534 15.75 11.625 15.75Z"
                stroke={props.dark_mode ? "white" : "black"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConditionListItem;
